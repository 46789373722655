import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Dropdown, Menu } from 'antd'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

import actions from '../../../../redux/user/actions'

/**
 * @description UserAvatar component is used to display the user avatar and the dropdown menu with user actions
 * @param {Object} user - User object
 * @returns {JSX.Element}
 * @author Rafael Rapizo Nery
 * @example
 * <UserAvatar user={user} />
 * @version 1.0.0
 * @since 1.0.0
 * @see https://ant.design/components/dropdown/#components-dropdown-demo-dropdown-button
 * @see https://ant.design/components/menu/#components-menu-demo-horizontal
 */
export const UserAvatar = ({ user }) => {
  const [visible, setVisible] = useState(false)
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch({ type: actions.LOGOUT })
  }

  return (
    <>
      {user && user.user && user.user.id ? (
         // If user is logged in, display the dropdown menu with user actions
        <Dropdown
          onVisibleChange={() => setVisible(!visible)}
          placement="bottomCenter"
          trigger={'click'}
          overlay={
            <Menu className="mt-2 rounded">
              <Menu.Item key={'1'} className="py-2 text-left">
                <a
                  className="mr-3"
                  href={`https://app.tradeto.world/#/?adminAccessToken=${localStorage.getItem(
                    'apiToken',
                  )}`}
                  target="_blank"
                  rel="noreferrer noloffow noopener"
                >
                  Dashboard
                </a>
              </Menu.Item>
              <Menu.Item key={'2'} className="py-2 text-left">
                <a
                  className="mr-3"
                  href={`https://app.tradeto.world/#/?adminAccessToken=${localStorage.getItem(
                    'apiToken',
                  )}`}
                  target="_blank"
                  rel="noreferrer noloffow noopener"
                >
                  My Profile
                </a>
              </Menu.Item>
              <Menu.Item key={'3'} className="py-2 text-left">
                <a
                  className="mr-3"
                  href={`https://app.tradeto.world/#/?adminAccessToken=${localStorage.getItem(
                    'apiToken',
                  )}`}
                  target="_blank"
                  rel="noreferrer noloffow noopener"
                >
                  My Products
                </a>
              </Menu.Item>
              <Menu.Item key={'4'} className="py-2 text-left" onClick={handleLogout}>
                Logout
              </Menu.Item>
            </Menu>
          }
        >
          <div className="user-avatar">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 7C16 9.206 14.206 11 12 11C9.794 11 8 9.206 8 7C8 4.794 9.794 3 12 3C14.206 3 16 4.794 16 7ZM14 7C14 5.897 13.103 5 12 5C10.897 5 10 5.897 10 7C10 8.103 10.897 9 12 9C13.103 9 14 8.103 14 7Z"
                fill="white"
              />
              <path
                d="M3 20.875C3 16.5325 6.99998 13 12 13C17 13 21 16.5325 21 20.875C21 21.496 20.4891 22 19.8571 22C19.2251 22 18.7143 21.496 18.7143 20.875C18.7143 17.7734 16 15.25 12 15.25C7.99998 15.25 5.28571 17.7734 5.28571 20.875C5.28571 21.496 4.77486 22 4.14286 22C3.51086 22 3 21.496 3 20.875Z"
                fill="white"
              />
            </svg>
            <span>{user.user.first_name && user.user.first_name}</span>
            {visible ? <CaretUpOutlined />  : <CaretDownOutlined />}
          </div>
        </Dropdown>
      ) : (
        // </a>
        <Link to="/secured/login">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16 7C16 9.206 14.206 11 12 11C9.794 11 8 9.206 8 7C8 4.794 9.794 3 12 3C14.206 3 16 4.794 16 7ZM14 7C14 5.897 13.103 5 12 5C10.897 5 10 5.897 10 7C10 8.103 10.897 9 12 9C13.103 9 14 8.103 14 7Z"
              fill="white"
            />
            <path
              d="M3 20.875C3 16.5325 6.99998 13 12 13C17 13 21 16.5325 21 20.875C21 21.496 20.4891 22 19.8571 22C19.2251 22 18.7143 21.496 18.7143 20.875C18.7143 17.7734 16 15.25 12 15.25C7.99998 15.25 5.28571 17.7734 5.28571 20.875C5.28571 21.496 4.77486 22 4.14286 22C3.51086 22 3 21.496 3 20.875Z"
              fill="white"
            />
          </svg>{' '}
          <span>Login</span>
        </Link>
      )}
    </>
  )
}
