import { Button } from 'antd'
import emptyCartImg from '../../../../assets/cart/empty cart.svg'
import { Link } from 'react-router-dom/cjs/react-router-dom'

export const CartEmpty = () => {
  return (
    <>
      <div className="text-center mt-5 ">
        <img className="mt-5" src={emptyCartImg} alt="Empty Cart Icon" />
        <h4 className="mt-5 mb-3">
          <strong>Your shopping cart is empty...</strong>
        </h4>
        <p>
          Looks like you have no items in your Shopping Cart. <br />
          Click out of here to continue shopping
        </p>
        <Link to="/products">
          <Button type="primary" className="ant-btn-ttu w-25 mt-4 ">
            view the catalog
          </Button>
        </Link>
      </div>
    </>
  )
}
