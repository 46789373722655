import { SearchBar } from '../../../../components/ui/SearchBar'
import { HeroContent } from './HeroContent'

export const Hero = () => {
  return (
    <div className="hero">
      <div className="container">
        <div className="hero__wrapper">
          <div className="row">
            <div className="col-12 col-lg-7">
              <HeroContent
                title={
                  <>
                    <span>One stop</span> B2B Marketplace
                  </>
                }
                description={
                  'Supporting Buyers and Sellers while promoting a fair and sustainable trade'
                }
              />
              <SearchBar />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
