import { useState, useEffect } from 'react'

export const useDescriptionScaffold = (productList) => {
  const [expanded, setExpanded] = useState(null)
  const [isShowAttributes, setisShowAttributes] = useState(false)
  const [isShowDescription, setisShowDescription] = useState(false)

  useEffect(() => {
    if (productList.length === 1) setExpanded(productList[0].id)

    if (productList.length === 2) {
      setisShowAttributes(true)
      setisShowDescription(true)
    }

    if (productList.length >= 3 && productList.length <= 4) setisShowAttributes(true)
  }, [productList])

  return { expanded, setExpanded, isShowAttributes, isShowDescription }
}
