import { useState } from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"


export function MainImage({
  shopOptions,
  disableProductLinks,
  expanded,
  setExpanded,
  product
}) {
  const [mainImage, setMainImage] = useState(
    product.get_main_image ? product.get_main_image.medium : '',
  )

  return (
    <div className="item-image">
      <Link
        to={`/product/${encodeURIComponent(product.slug)}`}
        onClick={(e) => {
          if (shopOptions || disableProductLinks) {
            e.preventDefault()
          }

          if (disableProductLinks) {
            if (product.id === expanded) {
              setExpanded(null)
            } else {
              setExpanded(product.id)
            }
          }
        }}
      >
        <img src={mainImage} alt={product.name} />
      </Link>
    </div>
  )
}
