import { Button } from 'antd'

import { CountryHelper, IterableHelper } from '../../../../../models'

import { redirectCompanyFacts } from '../../../../../util/functions'

import { IconList } from '.'
import Truncate from 'react-truncate'
import { useEffect, useRef, useState } from 'react'

/**
 * @description CompanyCard's Body component
 * @param {Object} props - Component props
 * @param {String} props.logo - Company's logo
 * @param {String} props.name - Company's name
 * @param {String} props.slug - Company's slug
 * @param {String} props.country - Company's country
 * @param {Array} props.industries - Company's industries
 * @param {Array} props.brands - Company's brands
 * @param {Function} props.onViewProducts - Function to view products
 * @returns {JSX.Element}
 * @author Rafael Rapizo Nery
 * @created 2024-02-06
 */
export const Body = ({
  logo,
  name,
  slug,
  country,
  industries,
  brands = [],
  onViewProducts = () => null,
}) => {
  const [isTruncated, setIsTruncated] = useState(false)
  const COUNTRY_CODE = CountryHelper.getCountryCode(country)

  const nameWrapperRef = useRef(null)
  const nameRef = useRef(null)

  useEffect(() => {
    if (name.length >= 19) {
      setIsTruncated(true)
    }
  }, [name])

  return (
    <div ref={nameWrapperRef} className="company-card__info__body">
      <div className="company-card__info__body__logo">
        <img
          src={
            logo ??
            `${process.env.REACT_APP_CDN_URL}/web/common/company-logo-placeholder.svg`
          }
          alt={`${name}'s logo`}
        />
      </div>
      <div className="company-card__info__body__name">
        <span onClick={() => redirectCompanyFacts(slug)}>
          <strong>
            {/* {window.innerWidth < 720
              ? name.length >= 8
                ? `${IterableHelper.limitIterable(name, 8).join('')}...`
                : name
              : name} */}
            {isTruncated ? (
              <Truncate lines={1} ellipsis={<span>...</span>}>
                {name}
              </Truncate>
            ) : (
              <span ref={nameRef}>{name}</span>
            )}
          </strong>
        </span>
        <span className="company-card__info__body__name__country">
          <img
            src={`https://flagsapi.com/${COUNTRY_CODE}/shiny/16.png`}
            alt={`${country}'s flag`}
          />
          <p>{country}</p>
        </span>
      </div>
      <IconList
        data={industries}
        title="Industries"
        className="company-card__info__body__industries"
      />
      <IconList
        data={brands}
        title="Brands"
        className="company-card__info__body__brands"
        isLogo
      />
      <Button
        onClick={onViewProducts}
        type="ghost"
        className="company-card__info__body__btn ant-btn-primary ant-btn-primary__outlined ant-btn-ttu"
      >
        view products
      </Button>
    </div>
  )
}
