import { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'

import { fetchSuppliers, fetchProducts } from '../../util/functions/fetch/'
import { removeKeys, removeMatchingKeys } from '../../util/functions/fetch/removeKey'

import { useFilters } from './useFilters'

import { Query } from '../../models/Query'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const PRODUCT_SORT_OPTIONS = [
  {
    title: 'Name (A-Z)',
    param: 'sortAlphabetically',
  },
  {
    title: 'Price (Lowest)',
    param: 'sortLowestPrice',
  },
  {
    title: 'Price (Highest)',
    param: 'sortHighestPrice',
  },
  {
    title: 'Newest',
    param: 'sortNewest',
  },
  {
    title: 'Oldest',
    param: 'sortOldest',
  },
]

const COMPANIES_SORT_OPTIONS = [
  {
    title: 'Name (A-Z)',
    param: 'sortAlphabetically',
  },
  {
    title: 'Newest',
    param: 'sortNewest',
  },
  {
    title: 'Oldest',
    param: 'sortOldest',
  },
]

/**
 * @description Custom hook for Catalogue component to handle data fetching and state management.
 * @customHook
 * @namespace Hooks - UI - Catalogue
 *
 * @returns {Object} - Object containing the following properties and methods:
 * @property {String} title - Title of the current tab
 * @property {String} view - Current view of the catalogue
 * @property {Function} setView - Function to set the view of the catalogue
 * @property {String} currentTab - Current tab
 * @property {Function} setCurrentTab - Function to set the current tab
 * @property {Number} currentPage - Current page
 * @property {Function} setCurrentPage - Function to set the current page
 * @property {Object} pagination - Pagination object
 * @property {Boolean} loading - Loading state
 * @property {Array} data - Data array
 * @property {Function} handleTabChange - Function to handle tab change
 * @property {Function} handlePaginationChange - Function to handle pagination change
 * @property {Function} handleSortChange - Function to handle sort change
 * @property {Function} handleCategoryChange - Function to handle category change
 * @property {Function} handlePriceRangeChange - Function to handle price range change
 * @property {Function} handleSelectChange - Function to handle select change
 * @property {Object} filters - Filters object
 * @property {Boolean} isFilterLoading - Loading state for filters
 * @property {Function} handleSupplierChange - Function to handle supplier change
 * @property {Array} sortOptions - Sort options array
 *
 * @method getData - Function to fetch data
 * @method handleTabChange - Function to handle tab change
 * @method handlePaginationChange - Function to handle pagination change
 * @method handleSortChange - Function to handle sort change
 * @method handleCategoryChange - Function to handle category change
 * @method handlePriceRangeChange - Function to handle price range change
 * @method handleSelectChange - Function to handle select change
 * @method handleSupplierChange - Function to handle supplier change
 * @method sortOptions - Sort options array
 *
 * @todo Implement search system
 * @todo Create storybook
 * @todo Create tests
 *
 * @requires react
 * @requires antd
 * @requires fetchSuppliers
 * @requires fetchProducts
 * @requires removeKeys
 * @requires removeMatchingKeys
 * @requires useFilters
 * @requires Query
 * @requires useLocation
 *
 * @version 1.0.0
 * @since 1.0.0
 * @created at 02-28-2024
 * @author Rafael Rapizo Nery
 */
export const useCatalogue = () => {
  const { filters, loading: isFilterLoading } = useFilters()
  const location = useLocation()

  const [title, setTitle] = useState('Products')
  const [view, setView] = useState('grid')
  const [currentTab, setCurrentTab] = useState('products')

  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({})
  const [paginationSize, setPaginationSize] = useState(12)

  const [loading, setLoading] = useState(false)

  const [data, setData] = useState([])
  const [params, setParams] = useState({})

  /**
   * @description Function to fetch data
   * @function
   * @name getData
   * @param {Object} params - Params object
   * @returns {Promise} - Promise object
   * @async
   * @inner
   * @memberof Hooks - UI - Catalogue
   * @inner
   * @see {@link fetchProducts}
   * @see {@link fetchSuppliers}
   * @example
   * getData({ ...params, page: currentPage, perPage: paginationSize })
   *
   * @author Rafael Rapizo Nery
   */
  const getData = useCallback(
    async (params) => {
      setLoading(true)
      try {
        let { data, pagination } =
          currentTab === 'products'
            ? await fetchProducts(params)
            : await fetchSuppliers(params)
        setData(data)
        setLoading(false)
        setPagination(pagination)
      } catch (error) {
        if (!TypeError === error) {
          message.error('Something went wrong, please try again later.')
          console.error(error)
        }
      }
    },
    [currentTab],
  )

  /**
   * @description Function to handle select change
   * @function
   * @name handleSelectChange
   * @param {Object} query - Query object
   * @param {String} value - Value string
   * @returns {Void}
   * @inner
   * @memberof Hooks - UI - Catalogue
   * @inner
   * @example
   * handleSelectChange({ query, value })
   *
   * @todo Create storybook
   * @author Rafael Rapizo Nery
   */
  const handleSelectChange = useCallback(
    ({ query, value }) => {
      let parsedParams = { ...params }
      const regex = new RegExp(`^${query}.*?(?=&|$)`)
      const queryBuilder = new Query(null, null, ',')
      const currentValues = parsedParams[query]?.split(',')
      let values = currentValues ? [...currentValues] : []

      // remove empty values
      values = values.filter((val) => val !== '')
      if (values.includes(value)) {
        values = values.filter((val) => val !== value)
      } else {
        values.push(value)
      }

      parsedParams = removeMatchingKeys(parsedParams, regex)

      values.forEach((value) => {
        queryBuilder.append(value)
      })

      setCurrentPage(1)
      setParams({ ...parsedParams, [query]: queryBuilder.buildParams() })
    },
    [params],
  )

  /**
   * @description Function to handle price range change
   * @function
   * @name handlePriceRangeChange
   * @param {Object} range - Range object
   * @returns {Void}
   * @inner
   * @memberof Hooks - UI - Catalogue
   * @inner
   * @example
   * handlePriceRangeChange({ min, max })
   *
   * @todo Create storybook
   * @todo Create tests
   *
   * @requires removeMatchingKeys
   * @requires removeKeys
   *
   *
   *
   * @version 1.0.0
   * @since 1.0.0
   * @created at 02-28-2024
   * @author Rafael Rapizo Nery
   *
   */
  const handlePriceRangeChange = useCallback(
    ({ min, max }) => {
      // { min, max }
      let parsedParams = { ...params }
      parsedParams = removeMatchingKeys(parsedParams, /^price.*?(?=&|$)/)
      setParams({ ...parsedParams, priceRange: `${min},${max}` })
    },
    [params],
  )

  /**
   * @description Function to handle category change
   * @function
   * @name handleCategoryChange
   * @param {Array} categories - Categories array
   * @param {String} name - Name string
   * @returns {Void}
   * @inner
   * @memberof Hooks - UI - Catalogue
   * @inner
   * @example
   * handleCategoryChange(categories, name)
   * @todo Create storybook
   * @todo Create tests
   * @requires removeMatchingKeys
   * @requires removeKeys
   * @requires Query
   *
   * @version 1.0.0
   * @since 1.0.0
   * @created at 02-28-2024
   *
   * @todo Create storybook
   * @todo Create tests
   *
   * @author Rafael Rapizo Nery
   */
  const handleCategoryChange = useCallback(
    (categories = [], name) => {
      let parsedParams = { ...params }
      parsedParams = removeMatchingKeys(parsedParams, /^category.*?(?=&|$)/)
      parsedParams = removeKeys(parsedParams, ['brand', 'title', 'supplier'])

      const query = new Query(null, null, ',')
      categories.forEach((category) => {
        query.append(category)
      })

      setLoading(true)
      setTitle(name)
      setCurrentPage(1)
      setParams({ ...parsedParams, category: query.buildParams() })
    },
    [params],
  )

  /**
   * @description Function to handle sort change
   * @function
   * @name handleSortChange
   * @param {Object} sort - Sort object
   * @returns {Void}
   * @inner
   * @memberof Hooks - UI - Catalogue
   * @inner
   * @example
   * handleSortChange(sort)
   * @todo Create storybook
   * @todo Create tests
   * @requires removeMatchingKeys
   *
   * @version 1.0.0
   * @since 1.0.0
   * @created at 02-28-2024
   *
   * @author Rafael Rapizo Nery
   */
  const handleSortChange = useCallback(
    (sort) => {
      let parsedParams = { ...params }
      parsedParams = removeMatchingKeys(parsedParams, /^sort.*?(?=&|$)/)
      setParams({ ...parsedParams, ...sort })
    },
    [params],
  )

  // handle pagination change
  const handlePaginationChange = useCallback((page) => {
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }, [])

  // handle tab change
  const handleTabChange = useCallback(
    (key) => {
      if (params['supplier']) {
        let parsedParams = { ...params }
        parsedParams = removeMatchingKeys(parsedParams, /^supplier.*?(?=&|$)/)
        parsedParams = removeKeys(parsedParams, ['brand', 'title', 'category'])
        setParams(parsedParams)
      }

      setCurrentTab(key)
      setCurrentPage(1)
      setView('grid')
      setData([])
    },
    [params],
  )

  const handleSupplierChange = useCallback(
    (suppliers = []) => {
      handleTabChange('products')
      let parsedParams = { ...params }
      parsedParams = removeMatchingKeys(parsedParams, /^supplier.*?(?=&|$)/)
      parsedParams = removeKeys(parsedParams, ['brand', 'title', 'category'])

      const query = new Query(null, null, ',')
      suppliers.forEach((supplier) => {
        query.append(supplier.id)
      })

      setLoading(true)
      setParams({
        ...parsedParams,
        supplier: query.buildParams(),
        title: `${suppliers[0].name}'s Products`,
      })
    },
    [params, handleTabChange],
  )

  /**
   * @name handleClearFilters
   * @description Function to clear filters
   * @method
   */
  const handleClearFilters = () => {
    setParams({})
    setCurrentPage(1)
  }

  useEffect(() => {
    if (!params.category && !params.brand) {
      if (currentTab === 'products') {
        setTitle('Products')
      } else {
        setTitle('Sellers')
      }
    }
    if (params.title) {
      setTitle(params.title)
    }
  }, [currentTab, params])

  // Set pagination size based on screen size useEffect
  useEffect(() => {
    if (window.innerWidth < 576) {
      setPaginationSize(8)
    } else if (window.innerWidth >= 576 && window.innerWidth < 992) {
      setPaginationSize(8)
    } else if (window.innerWidth >= 992 && window.innerWidth < 1900) {
      setPaginationSize(12)
    } else {
      setPaginationSize(15)
    }
  }, [])

  useEffect(() => {
    getData({ ...params, page: currentPage, perPage: paginationSize })
  }, [params, paginationSize, currentPage, getData])

  // config useEffect
  useEffect(() => {
    let search = location
    const params = new URLSearchParams(search.search)
    // parse through the possible params each by one and set the state respectively, if it exists
    let currentParams = { ...params }
    console.log(search.search, params)

    if (params.has('category')) {
      const category = params.get('category')
      currentParams = { ...currentParams, category }
    }

    if (params.has('brand')) {
      const brand = params.get('brand')
      currentParams = { ...currentParams, brand }
    }

    if (params.has('country')) {
      const country = params.get('country')
      currentParams = { ...currentParams, country }
    }

    if (params.has('supplier')) {
      const supplier = params.get('supplier')
      currentParams = { ...currentParams, supplier }
    }

    if (params.has('title')) {
      const title = params.get('title')
      currentParams = { ...currentParams, title }
    }

    setParams(currentParams)
  }, [])

  return {
    title,
    view,
    setView,
    currentTab,
    currentPage,
    setCurrentPage,
    pagination,
    loading,
    data,
    handleTabChange,
    handlePaginationChange,
    handleSortChange,
    handleCategoryChange,
    handlePriceRangeChange,
    handleSelectChange,
    filters,
    isFilterLoading,
    handleSupplierChange,
    handleClearFilters,
    sortOptions:
      currentTab === 'products' ? PRODUCT_SORT_OPTIONS : COMPANIES_SORT_OPTIONS,
  }
}
