import MarketplaceCard from '../../../ui/cards/marketplace/MarketplaceCard'
import { Carousel } from 'antd'

import DocumentCard from './DocumentCard/DocumentCard'

import styles from './carousel.module.scss'

const Docs = ({ data }) => {
  return (
    <MarketplaceCard title="Certificates & Patents">
      <div className={styles.carousel}>
        <Carousel
          dots={false}
          arrows
          draggable
          touchMove
          infinite={false}
          slidesToScroll={1}
          slidesToShow={5}
          nextArrow={
            <div>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  opacity="0.6"
                  cx="15"
                  cy="15"
                  r="14.5"
                  fill="white"
                  stroke="#EDEDED"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.3334 10L12.1584 11.175L15.975 15L12.1584 18.825L13.3334 20L18.3334 15L13.3334 10Z"
                  fill="#808080"
                />
              </svg>
            </div>
          }
          prevArrow={
            <div>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  opacity="0.6"
                  cx="15"
                  cy="15"
                  r="14.5"
                  transform="rotate(-180 15 15)"
                  fill="white"
                  stroke="#EDEDED"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.6666 20L17.8416 18.825L14.025 15L17.8416 11.175L16.6666 10L11.6666 15L16.6666 20Z"
                  fill="#808080"
                />
              </svg>
            </div>
          }
        >
          {data.map((doc) => (
            <DocumentCard
              key={doc.id}
              href={doc.path}
              title={doc.name}
              wrapperClassName={styles.item}
              innerClassName={`${styles.itemWrapper} ${styles.certificateWrapper}`}
            />
          ))}
        </Carousel>
      </div>
    </MarketplaceCard>
  )
}

export default Docs
