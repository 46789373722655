import { useSelector } from 'react-redux'
import { Badge, Dropdown } from 'antd'

import { PopUp } from './Popup/index'

export const CartIcon = () => {
  const { orders } = useSelector((state) => state.cart)

  return (
    <>
      {/* <PopUp /> */}
      <Dropdown overlay={<PopUp type="menu" />}>
        <Badge count={orders.length}>
          <svg
            className="w-100"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_6163_24887)">
              <path
                d="M15.55 13C16.3 13 16.96 12.59 17.3 11.97L20.88 5.48C21.25 4.82 20.77 4 20.01 4H5.21L4.27 2H2C1.44772 2 1 2.44772 1 3C1 3.55228 1.44772 4 2 4H3L6.6 11.59L5.25 14.03C4.52 15.37 5.48 17 7 17H18C18.5523 17 19 16.5523 19 16C19 15.4477 18.5523 15 18 15H7L8.1 13H15.55ZM6.16 6H18.31L15.55 11H8.53L6.16 6ZM7 18C5.9 18 5.01 18.9 5.01 20C5.01 21.1 5.9 22 7 22C8.1 22 9 21.1 9 20C9 18.9 8.1 18 7 18ZM17 18C15.9 18 15.01 18.9 15.01 20C15.01 21.1 15.9 22 17 22C18.1 22 19 21.1 19 20C19 18.9 18.1 18 17 18Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_6163_24887">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Badge>
      </Dropdown>
    </>
  )
}
