import { Link } from "react-router-dom/cjs/react-router-dom.min"

import { Icons } from "./Icons"

export const SupplierInfo = ({product, layout = 'list'}) => {
  return (
    <div className="item-supplier">
      <div className="d-flex justify-content-start align-items-center">
        <div className={`supplier ${layout === 'list' && 'fw-bold'}`}>
          <Link to={`/showroom/${product.get_supplier.slug}`}>
            {product.get_supplier && product.get_supplier.name}
          </Link>
        </div>
        <Icons />
      </div>
    </div>
  )
}
