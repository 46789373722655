import { Drawer } from 'antd'
import { useState } from 'react'
import { FaExchangeAlt } from 'react-icons/fa'

import './SortControllerMobile.scss'

export const SortControllerMobile = ({
  value,
  options = { title: '', callback: () => null, param: '' },
  onChange,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Drawer
        visible={open}
        placement="bottom"
        className="sort-controller-mobile__drawer"
        onClose={() => setOpen(!open)}
      >
        <div className="sort-controller-mobile__drawer__title">
          <h3>Sort by:</h3>
        </div>
        <ul className="sort-controller-mobile__drawer__options">
          {options.map((option, index) => (
            <li
              key={index}
              className={`sort-controller-mobile__drawer__options__option ${
                value === option.title
                  ? 'sort-controller-mobile__drawer__options__option--active'
                  : ''
              }`}
              onClick={() => {
                onChange(option.title, option.param)
                setOpen(!open)
              }}
            >
              <p>{option.title}</p>
            </li>
          ))}
        </ul>
      </Drawer>
      <div className="sort-controller-mobile">
        <div className="sort-controller-mobile__button" onClick={() => setOpen(!open)}>
          <FaExchangeAlt color="#004d40" transform="rotate(90)" />
        </div>
      </div>
    </>
  )
}
