import { notification } from 'antd'

import { ErrorMessage } from '../../../../../models/Messages/ErrorMessage'

import { ProductsWrapperHeader, ProductItem, ProductsWrapperFooter } from './'

export const ProductsWrapper = ({
  companies,
  orders,
  isSelected,
  isSelectedAllCompany,
  onSelectAllCompany,
  onRemoveAllCompany,
  onSelect,
  parseOrderPerCompanies,
  showLogisticQuote,
  selectedOrders,
}) => {
  const getTotalPrice = (orders) => {
    let totalValue = 0.0

    for (const o of orders) {
      totalValue += o.totalPrice
    }

    return totalValue.toFixed(2)
  }

  const handleShowLogistiQuote = (companySlug) => {
    let isMissingPckgData = false

    for (const o of selectedOrders) {
      if (!!!o.product.get_packaging.length) {
        isMissingPckgData = true
        break
      }
    }

    if (isMissingPckgData)
      return notification.error({
        message: 'Error!',
        description: ErrorMessage.logisticQuote.missingPckgData,
      })
    else {
      showLogisticQuote(companySlug)
    }
  }

  const getTotalItems = (orders) => {
    let totalItems = 0

    for (const o of orders) {
      totalItems += o.quantity
    }

    return totalItems
  }

  return (
    <>
      {companies.map((c) => (
        <div className="cart__products__wrapper">
          <ProductsWrapperHeader
            company={c}
            isSelectedAllCompany={isSelectedAllCompany}
            onSelectAllCompany={onSelectAllCompany}
            onRemoveAllCompany={onRemoveAllCompany}
          />
          <div className="cart__products__wrapper__list">
            {parseOrderPerCompanies(c, orders).map((o) => (
              <ProductItem
                product={o.product}
                totalPrice={o.totalPrice}
                selectedPrice={o.selectedPrice}
                quantity={o.quantity}
                data={o.data}
                isSelected={isSelected}
                onSelect={onSelect}
              />
            ))}
          </div>
          <ProductsWrapperFooter
            selectedOrders={selectedOrders}
            showLogisticQuote={handleShowLogistiQuote}
            totalItens={getTotalItems(parseOrderPerCompanies(c, orders))}
            totalPrice={getTotalPrice(parseOrderPerCompanies(c, orders))}
            companySlug={c.slug}
          />
        </div>
      ))}
    </>
  )
}
