import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export function Title({
  disableProductLinks,
  shopOptions,
  expanded,
  setExpanded,
  product,
}) {
  return (
    <h2 className="product-name">
      {disableProductLinks ? (
        <Link
          to={`/product/${encodeURIComponent(product.slug)}`}
          onClick={(e) => {
            if (shopOptions || disableProductLinks) {
              e.preventDefault()
            }

            if (disableProductLinks) {
              if (product.id === expanded) {
                setExpanded(null)
              } else {
                setExpanded(product.id)
              }
            }
          }}
        >
          {product.name}
        </Link>
      ) : (
        <Link to={`/product/${encodeURIComponent(product.slug)}`}>{product.name}</Link>
      )}
    </h2>
  )
}
