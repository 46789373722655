import { useSelector } from 'react-redux'

import { OrderCard } from '../OrderCard'

export const BodyMenu = () => {
  const { orders } = useSelector((state) => state.cart)

  return (
    <div className="cart-popup__body">
      {orders.map((o) => (
        <OrderCard
          key={o.product.id}
          className={'cart-popup__body__product'}
          order={o}
          deletable={true}
          divider={true}
        />
      ))}
    </div>
  )
}
