import { Carousel, Image } from 'antd'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import { Title } from './Title'
import { SupplierInfo } from './SupplierInfo'
import { DiscountBadge } from './DiscountBadge'
import { NewArrivalBadge } from './NewArrivalBadge'
import { RateWrapper } from './RateWrapper'

export function MainImage({
  shopOptions,
  disableProductLinks,
  expanded,
  setExpanded,
  isSellerShowroom = false,
  product,
  view = 'grid',
}) {
  return (
    <div className="product-card__image">
      <div className="product-card__image__badges">
        <DiscountBadge product={product} />
        <NewArrivalBadge
          isShow={
            new Date().valueOf() - new Date(product.created_at).valueOf() <= 1209600033
          }
        />
      </div>
      {isSellerShowroom && view === 'list' ? (
        <div className="product-gallery">
          <Carousel>
            {product.get_all_images &&
              product.get_all_images
                .slice(0, 4)
                .map((image, index) => (
                  <Image
                    fallback={`${process.env.REACT_APP_CDN_URL}/web/common/product-image-placeholder.svg`}
                    preview={false}
                    loading="lazy"
                    key={index}
                    src={image.medium}
                    alt={product.name}
                  />
                ))}
          </Carousel>
          <div className="product-card__footer d-none">
            <RateWrapper rating={0} reviewCount={0} />
            <div className="product-card__footer__ttw-code">
              <span>{product.product_data ? product.product_data.ttw_code : null}</span>
            </div>
          </div>
        </div>
      ) : (
        <Link
          to={`/product/${encodeURIComponent(product.slug)}`}
          onClick={(e) => {
            if (shopOptions || disableProductLinks) {
              e.preventDefault()
            }
            if (disableProductLinks) {
              if (product.id === expanded) {
                setExpanded(null)
              } else {
                setExpanded(product.id)
              }
            }
          }}
        >
          <img
            src={
              product.get_all_images
                ? product.get_all_images[0].medium
                : product.get_main_image
                ? product.get_main_image.medium
                : ''
            }
            alt={product.name}
          />
        </Link>
      )}
      <div className="product-card__image__title">
        <SupplierInfo product={product} />
        <Title
          product={product}
          disableProductLinks={disableProductLinks}
          expanded={expanded}
          setExpanded={setExpanded}
          shopOptions={shopOptions}
        />
        {/* <div className="product-card__footer d-none" >
          <RateWrapper rating={0} reviewCount={0} />
          <div className="product-card__footer__ttw-code">
            <span>{product.product_data ? product.product_data.ttw_code : null}</span>
          </div>
        </div> */}
      </div>
    </div>
  )
}
