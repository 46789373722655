const DEPRECATED_CDN_REGEX = /https:\/\/cdn.gruppobpc.com/

/**
 * Check if the url is a deprecated CDN
 * @param {string} url
 * @returns {boolean}
 * @example
 * isInvalidCDN('https://cdn.gruppobpc.com') // true
 * isInvalidCDN('https://cdn.example.com') // false
 * @author Rafael Rapizo Nery
 * @created 2024-02-06
 */
export let isInvalidCDN = (url) => {
  return url.match(DEPRECATED_CDN_REGEX)
}
