import { useEffect, useState } from 'react'
import { Skeleton } from 'antd'

import { LogisticQuote } from '../../../../../models/LogisticQuote'

import { ReturnStageController, AddressForm, ShipmentForm, QuoteReview } from './'

export const LogisticQuoteForm = ({
  showLogisticQuote,
  companySlug,
  quote = new LogisticQuote(),
}) => {
  const stages = [
    {
      step: 0,
      title: 'Addressess Information',
    },
    {
      step: 1,
      title: 'Shipment data',
    },
    {
      step: 2,
      title: 'Logistic Quote Review',
    },
  ]
  const [sellerInfo, setSellerInfo] = useState()
  const [buyerInfo, setBuyerInfo] = useState()
  const [quoteData, setQuoteData] = useState(quote)
  const [stage, setStage] = useState(stages[0])
  const [isLoading, setIsLoading] = useState(false)

  const hanldeSubmitAddressData = (sellerData, buyerData) => {
    quote.setSellerAddress(sellerData.address)
    quote.setSellerInfo({
      first_name: sellerData.first_name,
      last_name: sellerData.last_name,
      company: sellerData.company,
      email: sellerData.email,
      phone_code: sellerData.phone_code,
      phone: sellerData.phone,
    })
    quote.setBuyerAddress(
      buyerData.address.shippingAddress,
      buyerData.address.billingAddress,
    )
    quote.setBuyerInfo({
      first_name: buyerData.first_name,
      last_name: buyerData.last_name,
      company: buyerData.company,
      email: buyerData.email,
      phone_code: buyerData.phone_code,
      phone: buyerData.phone,
    })
    console.log(quote)
    setQuoteData(quote)
    setStage(stages[1])
  }

  const handleSubmitShipmentData = (values) => {
    console.log(values)
    if (!values.isUsePreviousData) {
      quote.setShipmentData({
        usePreviousData: values.usePreviousData,
        dangerous_goods: values.dangerous_goods,
        delivery_date: values.delivery_date,
        extra_needs: values.extra_needs,
        incoterm: values.incoterm,
        pickup_date: values.pickup_date,
        require_insurance: values.require_insurance,
        transport: {
          type: values.transport.type,
          condition: values.transport.condition,
          temperature: {
            degree: values.transport.temperature.degree,
            type: values.transport.temperature.type,
          },
        },
        wrapper: values.wrapper,
        is_palletized: values.is_palletized,
        pallet_category: values.pallet_category,
        container_distribution: values.container_distribution,
      })
      // console.log(quote)
      setQuoteData(quote)

      setStage(stages[2])
    }

    // decide how to fetch previous data
    /* quote.setShipment() */
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [stage.step])

  useEffect(() => {
    const fetchQuoteData = async () => {
      setIsLoading(true)
      let sellerData = null
      let buyerData = null
      try {
        sellerData = await quoteData.getSellerData()
        buyerData = await quoteData.getBuyerData()
      } catch (error) {
        console.log('ERROR FETCH', error)
      } finally {
        setSellerInfo(sellerData)
        setBuyerInfo(buyerData)
        setIsLoading(false)
      }
    }
    fetchQuoteData()
  }, [quoteData])

  if (isLoading) {
    return <Skeleton className="col-8" />
  }

  return (
    <>
      <div className="col-lg-8 logistic_quote">
        <ReturnStageController
          callback={showLogisticQuote}
          crrStage={stage.step}
          stageController={setStage}
        />
        <h4 className="logistic_quote__title">{stage.title}</h4>
        {stage.step === 0 && (
          <AddressForm
            seller={sellerInfo}
            buyer={buyerInfo}
            onAddressDataSubmit={hanldeSubmitAddressData}
          />
        )}
        {stage.step === 1 && (
          <ShipmentForm
            onSubmit={handleSubmitShipmentData}
            onReturn={() => setStage(stages[0])}
          />
        )}
        {stage.step === 2 && (
          <QuoteReview
            quote={quote}
            redirect={{ setStage, stages }}
            quoteHandler={setQuoteData}
          />
        )}
      </div>
    </>
  )
}
