import ProductAttributes from '../../../../pages/marketplace/product-details/ProductAttributes'
import { DescriptionCard } from './ListDetails'

export const PreviewData = ({
  attClassName = '',
  descClassName = '',
  isShowAttributes,
  isShowDescription,
  product,
  expanded,
}) => {
  return (
    <>
      {isShowAttributes && !(expanded === product.id) && (
        <ProductAttributes
          options={true}
          className={attClassName}
          product={product}
          handleChangeProductVariant={() => null}
        />
      )}
      {isShowDescription && !(expanded === product.id) && (
        <DescriptionCard className={descClassName} product={product} type={'desc'} />
      )}
    </>
  )
}
