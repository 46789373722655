import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { DeleteOutlined } from '@ant-design/icons'

import { actions } from '../../../../redux/cart/actions'

import placeholder from '../../../../assets/placeholder/TTW NO Product image.png'

export const OrderCard = ({
  order,
  deletable = false,
  divider = false,
  className = '',
}) => {
  const dispatch = useDispatch()

  const handleDelete = () => {
    dispatch({
      type: actions.REMOVE,
      payload: { productId: order.product.id },
    })
  }

  return (
    <div className={`pb-2 ${className} ${divider && 'border-bottom border-light mt-2'}`}>
      {deletable && <DeleteOutlined className="delete-icon" onClick={handleDelete} />}
      <div className="row align-items-center ">
        <div className="col-2">
          <img
            className="w-100"
            src={order.product.get_all_images[0].original}
            alt={`${order.product.name}`}
          />
        </div>
        <div className="col-10">
          <div className="row">
            <div className="col-12 mb-2">
              <Link
                className="text-decoration-none"
                to={`/product/${encodeURIComponent(order.product.slug)}`}
              >
                {order.product.name}
              </Link>
            </div>
            <div className="col-3">{order.quantity} items</div>
            <div className="col-9 text-end">
              <strong>$ {order.totalPrice.toFixed(2)}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
