/**
 * @name IterableHelper
 * @description This class is a helper class for iterable objects and arrays
 * @class
 * @author Rafael Rapizo Nery
 * @created 2024-02-06
 */
export class IterableHelper {
  static isIterable = (obj) => {
    if (obj == null) {
      return false
    }
    return typeof obj[Symbol.iterator] === 'function'
  }

  static isIterableObject = (obj) => {
    if (obj == null) {
      return false
    }
    return typeof obj[Symbol.iterator] === 'function' && typeof obj === 'object'
  }

  static isIterableArray = (obj) => {
    if (obj == null) {
      return false
    }
    return typeof obj[Symbol.iterator] === 'function' && Array.isArray(obj)
  }

  static isIterableString = (obj) => {
    if (obj == null) {
      return false
    }
    return typeof obj[Symbol.iterator] === 'function' && typeof obj === 'string'
  }

  static isIterableMap = (obj) => {
    if (obj == null) {
      return false
    }
    return typeof obj[Symbol.iterator] === 'function' && obj instanceof Map
  }

  static isIterableSet = (obj) => {
    if (obj == null) {
      return false
    }
    return typeof obj[Symbol.iterator] === 'function' && obj instanceof Set
  }

  static isIterableWeakMap = (obj) => {
    if (obj == null) {
      return false
    }
    return typeof obj[Symbol.iterator] === 'function' && obj instanceof WeakMap
  }

  static isIterableWeakSet = (obj) => {
    if (obj == null) {
      return false
    }
    return typeof obj[Symbol.iterator] === 'function' && obj instanceof WeakSet
  }

  static isIterableTypedArray = (obj) => {
    if (obj == null) {
      return false
    }
    return typeof obj[Symbol.iterator] === 'function' && obj instanceof ArrayBuffer
  }

  static isIterableArrayBuffer = (obj) => {
    if (obj == null) {
      return false
    }
    return typeof obj[Symbol.iterator] === 'function' && obj instanceof ArrayBuffer
  }

  static isIterableDataView = (obj) => {
    if (obj == null) {
      return false
    }
    return typeof obj[Symbol.iterator] === 'function' && obj instanceof DataView
  }

  static isIterableInt8Array = (obj) => {
    if (obj == null) {
      return false
    }
    return typeof obj[Symbol.iterator] === 'function' && obj instanceof Int8Array
  }

  static limitIterable = (obj, limit) => {
    if (obj == null) {
      return []
    }
    if (typeof obj[Symbol.iterator] !== 'function') {
      return []
    }
    return Array.from(obj).slice(0, limit)
  }
}
