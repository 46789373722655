import {  useEffect } from 'react'

/**
 * @name useResponsive
 * @description A hook that allows you to execute different functions based on the screen size.
 * @param {Object} props
 * @param {Function} props.onMobile - Function to execute when the screen size is mobile.
 * @param {Function} props.onTablet - Function to execute when the screen size is tablet.
 * @param {Function} props.onDesktop - Function to execute when the screen size is desktop.
 * @returns {null}
 * @author Rafael Rapizo Nery
 */
const useResponsive = ({
  onMobile = () => null,
  onTablet = () => null,
  onDesktop = () => null,
}) => {
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        onMobile()
      } else if (window.innerWidth <= 1024) {
        onTablet()
      } else {
        onDesktop()
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return null
}

export default useResponsive
