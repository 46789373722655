import React from 'react'

import { history } from '../../../..'

import styles from './be-a-part-ttw.module.scss'

import { RoleSelectionCard } from '../../../../pages/auth/verification/RoleSelectionCard'
import { FaChevronRight } from 'react-icons/fa'

const BeAPartOfTTW = () => {
  return (
    <div className={styles.content}>
      <div className="homepage-container">
        <div className="row align-items-md-center">
          <div className="col-12 col-md-6 col-lg-3 py-5 py-lg-0">
            <h2>Be a Part of TradeToWorld</h2>
            <p>
              Access our exclusive community of leading companies willing to participate
              in our fantastic venture. Join us and get worldwide visibility and limitless
              trade opportunities!
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <RoleSelectionCard
              onChange={() => history.push('secured/register')}
              readOnly
              img={`${process.env.REACT_APP_CDN_URL}/web/marketplace/Buyer_signup_illustration.svg`}
              title={
                <>
                  Sign Up as a Buyer <FaChevronRight />
                </>
              }
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <RoleSelectionCard
              onChange={() => history.push('secured/register')}
              readOnly
              img={`${process.env.REACT_APP_CDN_URL}/web/marketplace/Seller_signup_illustration.svg`}
              title={
                <>
                  Sign Up as Seller <FaChevronRight />
                </>
              }
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <RoleSelectionCard
              onChange={() => history.push('secured/register')}
              readOnly
              img={`${process.env.REACT_APP_CDN_URL}/web/marketplace/Service_Provider_signup_illustration.svg`}
              title={
                <>
                  Sign Up as Service Provider <FaChevronRight />
                </>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BeAPartOfTTW
