export const ReturnStageController = ({
  crrStage,
  stageController,
  callback,
  stages = [
    {
      step: 0,
      title: 'Addressess Information',
    },
    {
      step: 1,
      title: 'Shipment data',
    },
    {
      step: 2,
      title: 'Logistic Quote Review',
    },
  ],
}) => {

  const redirect = () => {
    switch (crrStage) {
      /* case 0:
        return callback()
      case 1:
        return stageController(stages[0])
      case 2:
        return stageController(stages[1]) */
      default:
        return callback()
    }
  }

  const getTitle = () => {
    switch (crrStage) {
      /* case 0:
        return 'Go back to My Cart'
      case 1:
        return 'Go back to Address Information'
      case 2:
        return 'Go back to Shipment Data' */
      default:
        return 'Go back to My Cart'
    }
  }

  return (
    <div className="logistic_quote__return-cart" onClick={() => redirect()}>
      <i className="fe fe-chevron-left"></i>
      <p>{getTitle()}</p>
    </div>
  )
}
