import { CheckCircleOutlined } from "@ant-design/icons"

export const HeaderNotification = () => {
  return (
    <>
      <div>
        <CheckCircleOutlined />
        <p>Product Added to cart</p>
      </div>
      {/* <CloseOutlined /> */}
    </>
  )
}
