import { ProductCard } from './ProductCard'

/**
 * @description A read-only card component for the Seller Showroom modal
 * @namespace Card - ProductCard - ProductCardReadOnly
 * @param {Object} props
 * @param {Object} props.product - The product to display
 * @param {function} props.onVariantChange - The function to handle the variant change
 * @param {function} props.onClick - The function to handle the card click
 *
 * @example
 * <ProductCardReadOnly product={product} onVariantChange={() => null} onClick={() => null} />
 *
 * @version 1.0.0
 * @since 1.0.0
 * @author Rafael Rapizo Nery
 *
 * @todo Add the prop types
 * @todo Add the tests
 * @todo Add the styles
 * @todo Add the storybook
 * @todo Add the localization
 */
export const ProductCardReadOnly = ({ product, onVariantChange, onClick }) => {
  return (
    <ProductCard
      key={product.id}
      className="product-card--ss product-card--ss__modal__card"
      product={product}
      view={'list'}
      isSellerShowroom
      expanded={true}
      setExpanded={() => null}
      handleChangeVariant={onVariantChange}
      onClick={onClick}
      disableProductLinks
    />
  )
}
