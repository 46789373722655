import { Prices } from './Prices'
import { OptBtn } from './OptBtn'

export const PriceWrapper = ({
  isSellerShowroom,
  product,
  expanded,
  shopOptions,
  setExpanded,
  layout = 'list',
}) => {
  return (
    <div className="product-prices">
      {!isSellerShowroom && (
        <div
          className={layout === 'grid' && 'grid-prices'}
          style={{
            height: '44px',
          }}
        >
          {<Prices product={product} />}
        </div>
      )}
      {layout === 'list' && (
        <OptBtn
          expanded={expanded}
          isShopOption={shopOptions}
          product={product}
          setExpanded={setExpanded}
        />
      )}
    </div>
  )
}
