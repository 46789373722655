import axios from 'axios'
import { message } from 'antd'

import { Address } from './Address'
import { LogisticQuoteData } from './LogisticQuoteData'

/**
 * @author Rafael Rapizo Nery
 *
 * @description Represents local construct of LogisticQuote. It consists in a progressively built object container user information on buyer and seller, as well as required shipment and delivery data for the quotation.
 *
 * @property {Order[]} orders
 * @property {Seller} seller
 * @property {Buyer} buyer
 * @property {Address} address_seller
 * @property {Address} address_buyer
 * @property {ShipmentData} shipment_data
 * @property {String} additional_notes
 */
export class LogisticQuote {
  orders

  seller = {
    id: '',
    slug: '',
    first_name: '',
    last_name: '',
    company: '',
    phone_code: '',
    phone: '',
    email: '',
  }
  buyer = {
    id: '',
    token: '',
    first_name: '',
    last_name: '',
    company: '',
    phone_code: '',
    phone: '',
    email: '',
  }
  address_seller
  address_buyer

  shipment_data = {
    usePreviousData: false,
    transport: {
      type: '',
      condition: '',
      temperature: {
        type: '',
        degree: '',
      },
    },
    incoterm: '',
    dangerous_goods: false,
    require_insurance: false,
    pickup_date: '',
    delivery_date: '',
    extra_needs: [],
    wrapper: '',
    is_palletized: false,
    pallet_category: '',
    container_distribution: '',
  }

  additional_notes = ''
  /* ----------------------------------------- */

  /**
   * @author Rafael Rapizo Nery
   *
   * @constructor
   *
   * @description Instanciates a Logistic Quote with mapped orders, seller and buyer basic fetcher information and seller address, defaultly built with orders provided data.
   *
   * @param {String} buyerId
   * @param {Order[]} orders
   */
  constructor(buyerId = '', orders) {
    this.orders = [
      ...orders.map((o) => ({
        product_data: o.data,
        product: o.product,
        quantity: o.quantity,
        selectedPrice: o.selectedPrice,
        totalPrice: o.totalPrice,
      })),
    ]

    if (orders.length >= 1) {
      this.seller = {
        id: orders[0].company.id,
        slug: orders[0].company.slug,
      }

      this.buyer = {
        id: buyerId,
        token: localStorage.getItem('apiToken'),
      }

      this.address_seller = new Address(
        orders[0].company.country,
        orders[0].company.city,
        orders[0].company.address,
        orders[0].company.zip,
      )
    }
  }

  /* ----------------------------------------- */
  /**
   * @function setSellerInfo
   *
   * @description NEEDS REFACTOR
   *
   *
   */
  setSellerInfo(info) {
    this.seller = { ...this.seller, ...info }
  }
  /**
   * @function setSellerAddressField
   *
   * @description NEEDS REFACTOR
   *
   *
   */
  setSellerAddressField(field, value) {
    this.address_seller[`${field}`] = value
  }
  /**
   * @function setSellerAddress
   *
   * @description NEEDS REFACTOR
   *
   *
   */
  setSellerAddress(address = new Address()) {
    this.address_seller = address
  }
  /**
   * @function setBuyerInfo
   *
   * @description NEEDS REFACTOR
   *
   *
   */
  setBuyerInfo(info) {
    this.buyer = { ...this.buyer, ...info }
  }
  /**
   * @function setBuyerAddressField
   *
   * @description NEEDS REFACTOR
   *
   *
   */
  setBuyerAddressField(field, value) {
    this.address_buyer[`${field}`] = value
  }
  /**
   * @function setBuyerAddress
   *
   * @description NEEDS REFACTOR
   *
   *
   */
  setBuyerAddress(shippingAddress = new Address(), billingAddress = new Address()) {
    this.address_buyer = {
      billingAddress,
      shippingAddress,
    }
  }
  /**
   * @function setShipmentData
   *
   * @description NEEDS REFACTOR
   *
   *
   */
  setShipmentData(data = this.shipment_data) {
    this.shipment_data = data
  }
  /**
   * @function setAdditionalNotes
   *
   * @description NEEDS REFACTOR
   *
   *
   */
  setAdditionalNotes(value) {
    this.additional_notes = value
  }

  /* ----------------------------------------- */
  /**
   * @function getBuyerData
   *
   * @description NEEDS REFACTOR
   *
   *
   */
  async getBuyerData() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_MAIN_API_URL}/api/v1/user-details`, {
        headers: {
          Authorization: `Bearer ${this.buyer.token}`,
        },
      })
      if (res.status === 200) {
        return res.data.user
      }
    } catch (err) {
      return message.error({
        content:
          "Something went wrong when fetching buyer's data, please try again later.",
      })
    }
  }
  /**
   * @function getSellerData
   *
   * @description NEEDS REFACTOR
   *
   *
   */
  async getSellerData() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_MAIN_API_URL}/api/v1/marketplace/company/${this.seller.slug}`,
      )

      if (res.status === 200) {
        return res.data.company
      }
    } catch (err) {
      return message.error({
        content:
          "Something went wrong when fetching seller's data, please try again later.",
      })
    }
  }

  /**
   * @function toData
   * @param {LogisticQuote} logisticQuote
   * @description Returns the Logistic Quote data enclosed in the instance in a formatted way adequate to backend.
   * @return {LogisticQuoteData}
   *
   * @author Rafael Rapizo Nery
   */
  static toData(qt) {
    return new LogisticQuoteData(qt)
  }
  toData() {
    return new LogisticQuoteData(this)
  }
}
