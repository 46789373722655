import COUNTRIES_LIST from '../../data/countries'

export class CountryHelper {
  static COUNTRIES = COUNTRIES_LIST

  static getCountryName(countryCode) {
    return this.COUNTRIES.find((country) => country.code === countryCode).name
  }

  static getCountryCode(countryName) {
    return this.COUNTRIES.find((country) => country.name === countryName).code
  }
}
