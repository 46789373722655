import { round } from 'lodash'

export const Prices = ({ product }) => {
  // console.log(product.get_prices)
  // if (product.get_prices.length === 1) {
  // Check
  if (product.sales_terms.length > 0) {
    if (product.sales_terms && product.sales_terms[0]) {
      if (parseFloat(product.sales_terms[0].price_value) === 0.0) {
        return null
      }
    }
    let lowestIndex = product.sales_terms[0]
    const lowestPrice = 999999999999999999
    // Find the lowest price
    product.sales_terms[0].prices &&
      product.sales_terms[0].prices.forEach((price, index) => {
        if (price.type === 'by_qty' && parseFloat(price.price_value) < lowestPrice) {
          lowestIndex = product.sales_terms[0].prices[index]
        }
      })
    return (
      <>
        <div>
          <div
            className="product-card__prices__price__discount-value"
            style={{
              opacity: lowestIndex.discount_percentage === '0.00' ? 0 : 1,
            }}
          >
            <span>
              {product.currency} {parseFloat(lowestIndex.price_value)}
            </span>
            {lowestIndex.discount_percentage &&
            lowestIndex.discount_percentage !== 0 &&
            lowestIndex.discount_percentage !== 0.0 &&
            lowestIndex.discount_percentage !== '0.00' ? (
              <div className="product-card__prices__price__discount-value__discount">
                {product.currency} -
                {round(
                  parseFloat(lowestIndex.price_value) -
                    lowestIndex.price_value * ((100 - lowestIndex.discount_percentage) / 100),
                  2,
                )}
              </div>
            ) : null}
          </div>
          <div className="product-card__prices__price__value">
            <strong>
              <span>{`${product.currency || 'USD'} ${
                lowestIndex.discount_percentage &&
                lowestIndex.discount_percentage !== 0 &&
                lowestIndex.discount_percentage !== 0.0 &&
                lowestIndex.discount_percentage !== '0.00'
                  ? new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(
                      parseFloat(
                        lowestIndex.price_value * ((100 - lowestIndex.discount_percentage) / 100),
                      ),
                    )
                  : new Intl.NumberFormat('en-US').format(parseFloat(lowestIndex.price_value))
              }`}</span>
            </strong>
          </div>
        </div>
        {lowestIndex.type === 'by_qty' && lowestIndex.min_qty > 0 && (
          <p className="product-card__prices__price__min-order">
            Minimum order {lowestIndex.min_qty} {product.sales_terms[0].order_measure}s
          </p>
        )}
      </>
    )
  }
  let min = 99999999999999999999999999
  let max = 0
  product.sales_terms.forEach((price) => {
    if (parseFloat(price.price_value) < min) {
      min = parseFloat(price.price_value)
    }
  })
  max = min
  product.sales_terms.forEach((price) => {
    if (parseFloat(price.price_value) > max) {
      max = parseFloat(price.price_value)
    }
  })
  return (
    <div className="product-card__prices__price__value">
      <span>
        <strong>{`${product.currency} ${product.price} `}</strong>
      </span>
    </div>
  )
}
