/**
 * @description This hook is used to create a PDF from a string and download it.
 * @example
 * const { downloadPDF } = useCreatePDF()
 * downloadPDF('This is a PDF')
 * @returns {Object} The downloadPDF function
 * @see
 * src/components/pages/marketplace/cart/LogisticQuote/QuoteReview.jsx
 *
 * @namespace Hooks - useCreatePDF
 * @property {HTMLAnchorElement} linkElement - The link element to be used to download the PDF
 * @method {Function} createPDFBlob - Creates a Blob from a string (encoded PDF)
 * @method {Function} createPDFUrl - Creates a URL from a Blob
 * @method {Function} createFileName - Creates a file name from the current date
 * @method {Function} addHrefToLink - Adds a href to the link element
 * @method {Function} addDownloadToLink - Adds a download attribute to the link element
 * @method {Function} addLinkAttributes - Adds href and download attributes to the link element
 * @method {Function} addBlobToLink - Adds a Blob to the link element
 * @method {Function} triggerDownload - Triggers the download of the PDF
 * @method {Function} downloadPDF - Add a Blob to the link element and triggers the download
 *
 * @version 0.1.2
 * @since 0.1.0
 * @author Rafael Rapizo Nery
 */
export const useCreatePDF = () => {
  let linkElement = document.createElement('a')

  const createPDFBlob = (encodedPDF) =>
    new Blob([encodedPDF], { type: 'application/pdf' })

  const createPDFUrl = (encodedPDF) => URL.createObjectURL(createPDFBlob(encodedPDF))

  const createFileName = () => new Date().toLocaleDateString().replace(/\//g, '-')

  const addHrefToLink = (hrefURL) => (linkElement.href = hrefURL)

  const addDownloadToLink = () => (linkElement.download = `${createFileName()}.pdf`)

  const addLinkAttributes = (hrefURL) => {
    addHrefToLink(hrefURL)
    addDownloadToLink()
  }

  const addBlobToLink = (encodedPDF) => {
    const hrefURL = createPDFUrl(encodedPDF)
    addLinkAttributes(hrefURL)
  }

  const triggerDownload = () => linkElement.click()

  const downloadPDF = (encodedPDF) => {
    addBlobToLink(encodedPDF)
    triggerDownload()
  }

  return { downloadPDF }
}
