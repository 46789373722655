import { Link } from './Link'

export class LinkBuilder {
  #linkList = []

  createLocal(title = '', url = '') {
    let link = new Link().setTitle(title).setURL(url).setIsLocal()
    this.#linkList.push(link)
    return link
  }
  createExternal(title = '', url = '') {
    let link = new Link().setTitle(title).setURL(url).setIsLocal(false)
    this.#linkList.push(link)
    return link
  }

  getLinkList() {
    return this.#linkList
  }
}
