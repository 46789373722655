import { Button } from 'antd'

import { ProductsHeader, ProductsWrapper } from './'

export const Products = ({
  orders,
  selectedOrders,
  onSelectAll,
  onRemoveAll,
  isSelectedAll,
  isSelected,
  isSelectedAllCompany,
  onSelectAllCompany,
  onRemoveAllCompany,
  onSelect,
  companies,
  parseOrderPerCompanies,
  onDeleteSelected,
  showLogisticQuote,
}) => {
  if (orders && orders.length >= 1) {
    return (
      <div className="col-lg-8 cart__products">
        <h3>My Cart</h3>
        <ProductsHeader
          isSelectedAll={isSelectedAll}
          onDeleteSelected={onDeleteSelected}
          onRemoveAll={onRemoveAll}
          onSelectAll={onSelectAll}
          orders={orders}
          selectedOrders={selectedOrders}
        />
        <ProductsWrapper
          companies={companies}
          orders={orders}
          isSelected={isSelected}
          isSelectedAllCompany={isSelectedAllCompany}
          onSelectAllCompany={onSelectAllCompany}
          onRemoveAllCompany={onRemoveAllCompany}
          onSelect={onSelect}
          parseOrderPerCompanies={parseOrderPerCompanies}
          showLogisticQuote={showLogisticQuote}
          selectedOrders={selectedOrders}
        />
        <Button
          type={`${selectedOrders.length <= 0 ? 'dashed' : 'primary'}`}
          disabled={selectedOrders.length <= 0}
          className="ant-btn ant-btn-ttu w-25"
        >
          send request
        </Button>
      </div>
    )
  }

  return <h1>empty cart</h1>
}
