import stockIcon from '../../../../../../assets/badges/stock.png'
import checkIcon from '../../../../../../assets/badges/check.png'
import tagsIcon from '../../../../../../assets/badges/tags.png'
import { Dropdown } from 'antd'

const OVERLAY_STYLE = {
  backgroundColor: 'rgb(0,0,0,0.8)',
  padding: '2px 4px',
  borderRadius: '4px',
  color: 'white',
  cursor: 'pointer',
}

export const Icons = ({
  isInStock = false,
  isGuarantee = false,
  isHighPromotion = false,
}) => {
  return (
    <div className="product-card__data__supplier__icons">
      <ul className="product-card__data__supplier__icons__list">
        {isInStock && (
          <Dropdown
            overlay={<span>In Stock</span>}
            overlayStyle={OVERLAY_STYLE}
            placement="topCenter"
          >
            <li className="product-card__data__supplier__icons__list__item">
              <img src={stockIcon} alt="" />
            </li>
          </Dropdown>
        )}
        {isGuarantee && (
          <Dropdown
            overlay={<span>Has Warranty</span>}
            overlayStyle={OVERLAY_STYLE}
            placement="topCenter"
          >
            <li className="product-card__data__supplier__icons__list__item">
              <img src={checkIcon} alt="" />
            </li>
          </Dropdown>
        )}
        {isHighPromotion && (
          <Dropdown
            overlay={<span>Has Discount</span>}
            overlayStyle={OVERLAY_STYLE}
            placement="topCenter"
          >
            <li className="product-card__data__supplier__icons__list__item">
              <img src={tagsIcon} alt="" />
            </li>
          </Dropdown>
        )}
      </ul>
    </div>
  )
}
