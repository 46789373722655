import { useState } from 'react'
import { Button, Checkbox, DatePicker, Form, Input, Radio, Select, message } from 'antd'
import _ from 'lodash'

import { INCOTERMS } from '../../../../../data/incoterms'

import { Pallet } from '../../../../../models/Pallet'

import { PalletCard } from './'

export const ShipmentForm = ({ onSubmit, onReturn }) => {
  const [pallet, setPallet] = useState(Pallet.getCodes()[0])
  const [pickUpDate, setPickUpDate] = useState('asap')
  const [extraNeeds, setExtraNeeds] = useState(['none'])

  const handleSubmit = (values) => {
    if (!pallet) return message.error({ content: 'Please, provide a pallet category.' })
    if (!pickUpDate) return message.error({ content: 'Plasen provide a pick up date.' })

    const shipmentData = {
      /* usePreviousData: values.usePreviousData, */
      transport: {
        type: values.transport_type,
        condition: values.transport_condition,
        temperature: {
          type: values.transport_temperature_type || 'celsius',
          degree: values.transport_temperature_degrees || null,
        },
      },
      incoterm: values.incoterm,
      dangerous_goods: values.dangerous_goods,
      require_insurance: values.require_insurance,
      pickup_date: pickUpDate,
      delivery_date: values.delivery_date
        ? values.delivery_date.format('MM-DD-YYYY')
        : 'Unspecified',
      extra_needs: extraNeeds,
      wrapper: values.wrapper,
      is_palletized: values.is_palletized,
      pallet_category: pallet,
      container_distribution: values.container_distribution,
    }
    onSubmit(shipmentData)
  }

  const handleSubmitFailed = (err) => {
    window.scrollTo(0, 0)
    message.error({ content: 'Please, fill out all required fields.' })
  }

  const handleExtraNeeds = (value, crrExtraNeeds) => {
    let updatedNeeds = [...crrExtraNeeds]
    if (value === 'none' && crrExtraNeeds.length > 0) {
      updatedNeeds = []
    }
    if (value !== 'none' && crrExtraNeeds.find((v) => v === 'none')) {
      updatedNeeds = _.remove(updatedNeeds, 'none')
    }

    if (value !== 'none' && crrExtraNeeds.find((v) => v === value)) {
      updatedNeeds = updatedNeeds.filter((v) => v !== value)
    } else {
      updatedNeeds.push(value)
    }

    if (value !== 'none' && updatedNeeds.length === 0) {
      updatedNeeds.push('none')
    }

    setExtraNeeds(updatedNeeds)
  }

  const isNeedSelected = (value, crrExtraNeeds) => {
    if (crrExtraNeeds.find((v) => v === value)) return true
    else return false
  }

  return (
    <>
      <Form
        colon={false}
        initialValues={{
          /* usePreviousData: false, */
          transport_type: 'air',
          incoterm: null,
          transport_condition: 'dry',
          wrapper: 'pallets',
          is_palletized: true,
          container_distribution: 'groupage',
          dangerous_goods: false,
          require_insurance: true,
          delivery_date: null,
          transport_temperature_type: 'celsius',
          transport_temperature_degrees: null,
        }}
        onFinish={handleSubmit}
        onFinishFailed={handleSubmitFailed}
        className="logistic_quote__shipment-data"
      >
        {/*
        /////// on wait until further development of cart feature
        <div className="col-lg-6">
          <Form.Item
            name="usePreviousData"
            label="Retrieving data from a previous order?"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div> */}
        <span>Please provide the following information</span>
        <div className="col-lg-6">
          <Form.Item
            name="transport_type"
            label="Transport Type"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Radio.Group>
              <Radio value={'air'}>Air</Radio>
              <Radio value={'boat'}>Boat</Radio>
              <Radio value={'rail'}>Rail</Radio>
              <Radio value={'truck'}>Truck</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="incoterm"
            label="Incoterms (delivery terms)"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Select placeholder="Select Incoterm">
              {INCOTERMS.map((i) => (
                <Select.Option
                  value={i.code}
                  key={i.code}
                >{`${i.code} - ${i.name}`}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="wrapper"
            label="Cases or Pallets?"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Radio.Group>
              <Radio value={'cases'}>Cases</Radio>
              <Radio value={'pallets'}>Pallets</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="is_palletized"
            label="Items already palletized?"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="col-lg-12">
          <Form.Item
            label="Pallets"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <div className="row">
              <Radio.Group className="logistic_quote__shipment-data__pallets">
                {Pallet.getCodes().map((c) => (
                  <div className="col-3">
                    <PalletCard
                      code={c}
                      key={c}
                      selected={c === pallet}
                      onClick={setPallet}
                    />
                  </div>
                ))}
              </Radio.Group>
            </div>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="container_distribution"
            label="Container"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Radio.Group>
              <Radio value={'groupage'}>Groupage</Radio>
              <Radio value={'full_load'}>Full Load</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="transport_condition"
            label="Transport Conditions"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Radio.Group>
              <Radio value={'dry'}>Dry</Radio>
              <Radio value={'insulated'}>Insulated</Radio>
              <Radio value={'reefer'}>Reefer</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="transport_temperature_type"
            label="Temperature Requirements"
            rules={[{ message: 'This field is required.' }]}
          >
            <Radio.Group>
              <Radio value={'celsius'}>Celsius</Radio>
              <Radio value={'farehenheit'}>Farhenheit</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name={'transport_temperature_degrees'}
            rules={[{ message: 'This field is required.' }]}
          >
            <Input placeholder="Degrees" type="number" />
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="dangerous_goods"
            label="Dangerous Goods?"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="require_insurance"
            label="Insurance Needed?"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          {/* constrolled in state, not form //////////////////////////
           */}
          <Form.Item label="Pickup Date">
            <Radio.Group defaultValue={'asap'}>
              <Radio
                checked={pickUpDate === 'asap'}
                onClick={() => setPickUpDate('asap')}
                value={'asap'}
              >
                ASAP
              </Radio>
              <Radio
                checked={pickUpDate !== 'asap'}
                onClick={() => setPickUpDate('mm/dd/yyyy')}
                value={'set_date'}
              >
                mm/dd/yy
              </Radio>
            </Radio.Group>
          </Form.Item>
          {pickUpDate !== 'asap' && (
            <Form.Item>
              <DatePicker
                className="w-75"
                disabledDate={(crr) => crr && crr.valueOf() < Date.now()}
                onChange={(e) => setPickUpDate(e.toISOString())}
              />
            </Form.Item>
          )}
        </div>
        <div className="col-lg-6">
          <Form.Item name="delivery_date" label="Delivery Mandatory Date (if requested)">
            <DatePicker
              className="w-75"
              disabledDate={(crr) => crr && crr.valueOf() < Date.now()}
            />
          </Form.Item>
        </div>
        <div className="col-12">
          <Form.Item label="Do You Have Additional Logistic Needs?">
            <Checkbox
              value={'none'}
              checked={isNeedSelected('none', extraNeeds)}
              onClick={(e) => handleExtraNeeds(e.target.value, extraNeeds)}
            >
              No
            </Checkbox>
            <Checkbox
              value={'warehousing'}
              checked={isNeedSelected('warehousing', extraNeeds)}
              onClick={(e) => handleExtraNeeds(e.target.value, extraNeeds)}
            >
              Warehousing
            </Checkbox>
            <Checkbox
              value={'distribution'}
              checked={isNeedSelected('distribution', extraNeeds)}
              onClick={(e) => handleExtraNeeds(e.target.value, extraNeeds)}
            >
              Distribution
            </Checkbox>
            <Checkbox
              value={'local-invoice'}
              checked={isNeedSelected('local-invoice', extraNeeds)}
              onClick={(e) => handleExtraNeeds(e.target.value, extraNeeds)}
            >
              Local Invoicing
            </Checkbox>
          </Form.Item>
        </div>
        <div className="col-12">
          <Button
            onClick={onReturn}
            type="primary"
            className="ant-btn-primary__outlined ant-btn-ttu"
          >
            Back
          </Button>
          <Button type="primary" htmlType="submit" className="ant-btn-ttu ms-4">
            Continue
          </Button>
        </div>
      </Form>
    </>
  )
}
