import { useState } from 'react'
import { Button, Form, Input, Modal, message } from 'antd'

import axiosRequest from '../../../../hooks/useAxiosRequest'

export const CompanyBanner = ({ company, bannerSrc = null, isEditable = false }) => {
  const [bannerUploadModal, setBannerUploadModal] = useState({ visible: false, data: {} })
  const [pageBannerPath, setBannerPath] = useState(bannerSrc)

  const handleBannerUpload = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      message.loading({ content: 'Please, wait...', key: 'save' })
      const formData = new FormData()
      formData.append('image', file)
      axiosRequest('/api/v1/images/upload?noImageProcess=1', formData, 'post', true).then(
        (res) => {
          if (res.data.success && res.data.image) {
            const { original } = JSON.parse(res.data.image)

            setBannerPath(original)
            // window.location.reload()
          }
        },
      )
      e.target.value = ''
    } else {
      message.error('Incorrect image')
    }
  }

  const onBannerDataUpdate = (values) => {
    const formData = values
    formData.path = null
    message.loading({ content: 'Please, wait...', key: 'save' })
    if (pageBannerPath && pageBannerPath !== null) {
      formData.path = pageBannerPath
    }
    if (!company.id) {
      message.error({ content: 'Company ID not found', key: 'save' })
      return
    }
    formData.supplierID = company.id
    axiosRequest('/api/v1/supplier/banner-upload', formData, 'post', true)
      .then((res2) => {
        message.success('Done')
        window.location.reload()
      })
      .catch(() => {
        message.error('Something goes wrong, try again later...')
      })
      .finally(() => {
        message.success({ content: 'Done', key: 'save' })
      })
  }

  return (
    <>
      <Modal
        visible={bannerUploadModal.visible}
        title="Seller Showroom Banner"
        onCancel={() => setBannerUploadModal({ visible: false, data: {} })}
        destroyOnClose
        footer={false}
      >
        <Form
          layout="vertical"
          initialValues={bannerUploadModal.data}
          requiredMark={false}
          onFinish={onBannerDataUpdate}
        >
          <Form.Item name="bannerTitle" label="Banner Title">
            <Input placeholder="Input banner title" />
          </Form.Item>
          <Form.Item name="bannerSubTitle" label="Banner Sub-Title">
            <Input placeholder="Input banner sub-title" />
          </Form.Item>
          {pageBannerPath && (
            <Form.Item label="Page Banner">
              <img
                src={pageBannerPath}
                style={{ maxWidth: '100%', height: 'auto' }}
                alt=""
              />
            </Form.Item>
          )}
          <Form.Item label="Upload new page banner">
            <input
              type="file"
              accept="image/png,image/jpeg,image/jpg"
              onChange={(e) => handleBannerUpload(e)}
            />
          </Form.Item>
          <Form.Item className="mb-0">
            <Button
              className="ant-btn ant-btn-link ant-btn-sm ant-btn-primary px-4"
              htmlType="submit"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <div
        className="sellerShowroom__head sellerShowroom__head-full"
        style={
          company.bannerImage
            ? {
                backgroundImage: `url(${company.bannerImage})`,
              }
            : null
        }
      >
        {isEditable ? (
          <div
            className="sellerShowroom__head-uploadWrapper"
            onClick={() =>
              setBannerUploadModal({
                visible: true,
                data: company,
              })
            }
          >
            <div className="upload">
              <div className="uploadIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  />
                </svg>
              </div>
            </div>
          </div>
        ) : null}
        <div className="container">
          <h1>{company.bannerTitle ? company.bannerTitle : company.name}</h1>
          <h4>{company.bannerSubTitle ? company.bannerSubTitle : 'Seller Showroom'}</h4>
        </div>
      </div>
    </>
  )
}
