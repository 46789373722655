import { useCallback, useEffect, useMemo, useState } from 'react'
import { limit } from '../../../../util/functions/limit'
import { FaChevronDown, FaChevronLeft, FaChevronUp } from 'react-icons/fa'

import { history } from '../../../..'
import { useParams, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Radio, Select } from 'antd'

export const CategoriesTree = ({ categoriesData = [], onChange, isMenu = false }) => {
  const [returnObject, setReturnObject] = useState({
    title: 'Home',
    action: () => history.push('/'),
  })
  const [treeTitle, setTreeTitle] = useState('Shop by category')
  const [isSubCategories, setIsSubCategories] = useState(false)
  const [activeSubCategory, setActiveSubCategory] = useState(null)
  const [isShowMore, setIsShowMore] = useState(false)
  const [currentData, setCurrentData] = useState(categoriesData ?? [])
  const location = useLocation()

  const MAIN_CATEGORIES = useMemo(() => categoriesData, [categoriesData])

  const handleSelectCategoryMenu = useCallback(
    (category) => {
      let { subIndustries, name } = category

      const subIndustriesId = subIndustries.map((sub) => sub.id)

      onChange(subIndustriesId, name)
    },
    [onChange],
  )

  const handleSelectCategory = useCallback(
    (category) => {
      let { subIndustries, name } = category

      const subIndustriesId = subIndustries.map((sub) => sub.id)

      setTreeTitle(name)
      setCurrentData([...subIndustries])

      setIsSubCategories(true)
      setActiveSubCategory(null)

      onChange(subIndustriesId, category.name)
    },
    [onChange],
  )

  const handleSelectSubCategory = (category) => {
    setActiveSubCategory(category)
    onChange([category.id], category.name)
  }

  useEffect(() => {
    if (!isSubCategories) {
      setReturnObject({
        title: 'Home',
        action: () => history.push('/'),
      })
      setTreeTitle('Shop by category')
      setCurrentData(MAIN_CATEGORIES)
    } else {
      setReturnObject({
        title: 'Shop by category',
        action: () => {
          setIsSubCategories(false)
          onChange([])
        },
      })
    }
  }, [isSubCategories, MAIN_CATEGORIES, onChange])

  useEffect(() => {
    let params = new URLSearchParams(location.search)
    if (params.get('page') && params.get('page') === '1') {
      let subCategoriesId = params.get('category')
        ? params
            .get('category')
            .split(',')
            .map((id) => parseInt(id))
        : null

      if (subCategoriesId) {
        let category = MAIN_CATEGORIES.find((c) =>
          c.subIndustries.every((sI) => subCategoriesId.includes(sI.id)),
        )

        if (category) {
          console.log(params)
          console.log(subCategoriesId)
          console.log(category)
          handleSelectCategory(category)
        }
      }
    }
  }, [location, MAIN_CATEGORIES])

  return (
    <>
      <div className="navigation-tree">
        {!isMenu && (
          <>
            <div className="navigation-tree__return" onClick={returnObject.action}>
              <FaChevronLeft className="navigation-tree__return__arrow" />
              <span className="navigation-tree__return__text">{returnObject.title}</span>
            </div>
            <div className="navigation-tree__title">{treeTitle}</div>
          </>
        )}
        <div className="navigation-tree__list">
          {/* <ul className="navigation-tree__list__items">
            {limit(currentData, isShowMore ? currentData.length : 8).map((category) => {
              return (
                <li
                  className={`navigation-tree__list__items__item ${
                    activeSubCategory && activeSubCategory.id === category.id
                      ? 'navigation-tree__list__items__item--active'
                      : ''
                  }`}
                  key={category.id}
                  onClick={() =>
                    isSubCategories
                      ? handleSelectSubCategory(category)
                      : handleSelectCategory(category)
                  }
                >
                  {category.name}
                </li>
              )
            })}
          </ul> */}
          {isMenu ? (
            <>
              <Radio.Group
                defaultValue={'all'}
                className="navigation-tree__list__radio-group"
              >
                {!isSubCategories && <Radio value="all">All</Radio>}
                {limit(currentData, isShowMore ? currentData.length : 8).map(
                  (category) => {
                    return (
                      <Radio
                        value={category.id}
                        className={`navigation-tree__list__items__item ${
                          activeSubCategory && activeSubCategory.id === category.id
                            ? 'navigation-tree__list__items__item--active'
                            : ''
                        }`}
                        key={category.id}
                        onClick={() =>
                          isSubCategories
                            ? handleSelectSubCategory(category)
                            : handleSelectCategoryMenu(category)
                        }
                      >
                        {category.name}
                      </Radio>
                    )
                  },
                )}
              </Radio.Group>
            </>
          ) : (
            <ul className="navigation-tree__list__items">
              {limit(currentData, isShowMore ? currentData.length : 8).map((category) => {
                return (
                  <li
                    className={`navigation-tree__list__items__item ${
                      activeSubCategory && activeSubCategory.id === category.id
                        ? 'navigation-tree__list__items__item--active'
                        : ''
                    }`}
                    key={category.id}
                    onClick={() =>
                      isSubCategories
                        ? handleSelectSubCategory(category)
                        : handleSelectCategory(category)
                    }
                  >
                    {category.name}
                  </li>
                )
              })}
            </ul>
          )}
          {currentData.length > 8 && (
            <div
              className="navigation-tree__show-more"
              onClick={() => setIsShowMore(!isShowMore)}
            >
              <span>{isShowMore ? 'Show less' : 'Show more'}</span>
              {isShowMore ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
