/**
 * @author Rafael Rapizo Nery
 * @class Class representing an address of a company or a user, containing: country, city, street, zip code and assertion of address funcionality in case of being a company's address.
 *
 * @property {String} country Address's country
 * @property {String} city Address's city
 * @property {String} street Address's street
 * @property {String} zip Address's zip
 * @property {Boolean} isStock Asserts if the given address is a common or HQ address, or if it's a stock / warehouse. Default: false.
 */
export class Address {
  country
  city
  street
  zip

  /**
   * @author Rafael Rapizo Nery
   * @constructor
   *
   * @description By default, all arguments are provided as falsy values, but not blank one. So what we have after instancianting the class without any specific content is a blank address.
   *
   * @param {String} country
   * @param {String} city
   * @param {String} street
   * @param {String} zip
   * @param {Boolean} isStock
   *
   * @returns {Address}
   */
  constructor(country = '', city = '', street = '', zip = '', isStock = false) {
    this.country = country
    this.city = city
    this.street = street
    this.zip = zip
    this.isStock = isStock
  }

  /**
   * @author Rafael Rapizo Nery
   * @function getAddressMessage
   * @returns {String} Formatted full address information, with functional prefix, street, city and country.
   */
  getAddressMessage() {
    const prefix = this.isStock ? 'Stock' : 'HQ Address'
    return `${prefix} - ${this.street} - ${this.city}, ${this.country}`
  }
}
