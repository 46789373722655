import { query } from "./query"

/**
 * @description Fetches the search results
 * @param {Object} params The query parameters object
 * @param {Function} callback The callback function
 * @returns {Promise} A promise that resolves to the search results array
 * @async
 * @function fetchSearch
 * @inner
 * @memberof fetch
 * @example <caption>Usage</caption>
 * fetchSearch({ search: 'test', target: 'all' })
 * .then((data) => console.log(data))
 * .catch((err) => console.error(err))
 * @see query
 * @requires query
 * @version 1.0.0
 * @author Rafael Rapizo Nery
 */
export const fetchSearch = async (params, callback) => {
  return await query('api/v2/search', params, callback)
}
