import Checkbox from 'antd/lib/checkbox/Checkbox'

export const ProductsWrapperHeader = ({
  company,
  isSelectedAllCompany,
  onSelectAllCompany,
  onRemoveAllCompany,
}) => {
  return (
    <div className="row cart__products__wrapper__header">
      <div className="col-lg-9">
        <Checkbox
          checked={isSelectedAllCompany(company.id)}
          onClick={
            isSelectedAllCompany(company.id)
              ? () => onRemoveAllCompany(company.id)
              : () => onSelectAllCompany(company.id)
          }
        ></Checkbox>
        <div className="brand-logo">
          <a
            href={`/#/company/${company.slug}`}
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            <img src={company.logo} alt={company.name} />
          </a>
          <p>{company.name}</p>
        </div>
      </div>
      <div className="col-lg-3 cart__products__wrapper__header__seller">
        <i className="fe fe-message-square"></i>
        Message with Seller
      </div>
    </div>
  )
}
