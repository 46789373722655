import { CompanyHelper } from '../../../../models'

import { isInvalidCDN } from '../../../../util/functions'
import Card from '../Card'

import { Image, Info, Footer } from './components'

/**
 * @description Company card component for the catalogue page (marketplace)
 * @typedef {Object} CompanyCardProps
 * @property {Object} company - Company object
 * @author Rafael Rapizo Nery
 * @created 2024-01-11
 * @updated 2024-02-06
 */
export const CompanyCard = ({
  classNamePrfx = '',
  company,
  view,
  onViewProducts = () => null,
  onCardClick = () => null,
  onImageClick = () => null,
}) => {
  if (company)
    return (
      <Card
        className={`company-card${view === 'list' ? '--horizontal' : ''} ${
          !!classNamePrfx && `company-card--${classNamePrfx}`
        }`}
        onClick={() => onCardClick([company])}
      >
        <Image
          image={
            CompanyHelper.hasImageGallery(company) &&
            !isInvalidCDN(company.get_images[0].image)
              ? company.get_images[0].image
              : null
          }
          onClick={() => onImageClick(company.slug)}
        />
        <Info
          company={company}
          view={view}
          brands={[...(company.get_brands ?? [])]}
          onViewProducts={() => onViewProducts([company])}
        />
        <Footer
          products={company.featuredProducts ?? []}
          slug={company.slug ?? ''}
          view={view}
          onViewProducts={() => onViewProducts([company])}
        />
      </Card>
    )
  else return null
}
