export const Tags = ({ product, layout = 'list' }) => {
  if (product.get_tags && product.get_tags.length > 0) {
    return (
      <div className="tags">
        {layout === 'list' ? (
          <ul className="flex-wrap">
            <li>Tags:</li>
            {product.get_tags.map((tag) => (
              <li className="mb-2" key={tag.id}>#{tag.get_tag.name}</li>
            ))}
          </ul>
        ) : (
          <>
            {product.get_tags.map((tag, index) => {
              if (index < 2) {
                return (
                  <div className="tag" key={tag.id}>
                    #{tag.get_tag.name}
                  </div>
                )
              }
              return null
            })}
            {product.get_tags.length > 2 ? (
              <div className="tag">+{product.get_tags.length - 2}</div>
            ) : null}
          </>
        )}
      </div>
    )
  } else return (<div className="tags"></div>)
}
