import { Rate } from 'antd'

/**
 * @description RateWrapper component is a sub-component of ProductCard component. It is used to display the rating and review count of the product.
 * @param {Number} rating - The rating of the product.
 * @param {Number} reviewCount - The review count of the product.
 *
 * @example
 * return (
 *  <RateWrapper rating={4.5} reviewCount={100} />
 * )
 *
 * @see ProductCard
 *
 * @category Components - Frontend - UI - Cards - Product Card Components
 *
 * @version 1.0.0
 * @since 1.0.0
 * @created 02/27/2024
 * @author Rafael Rapizo Nery
 */
export const RateWrapper = ({
  rating = 0,
  reviewCount = 0,
  isSellerShowroom = false,
  className = ''
}) => {
  if (isSellerShowroom) return null
  return (
    <>
      <div className={`product-card__rate-wrapper ${className}`}>
        <Rate
          value={{ rating }}
          disabled
          className="product-card__rate-wrapper__rating"
        />
        <span className="product-card__rate-wrapper__review-count">({reviewCount})</span>
      </div>
    </>
  )
}
