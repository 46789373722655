import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import { FOOTER_LINKS } from '../../../../data/ui/FooterLinks'

export const Links = () => {
  return (
    <div className="row col-12 col-md-5 pl-2">
      <div className="col-6">
        {FOOTER_LINKS.filter((link) => link.getIsLocal()).map((link, index) => (
          <div key={index} className="col-12 pb-4">
            <Link to={link.getURL()}>{link.getTitle()}</Link>
          </div>
        ))}
      </div>
      <div className="col-6">
        {FOOTER_LINKS.filter((link) => !link.getIsLocal()).map((link, index) => (
          <div key={index} className="col-12 pb-4">
            <a href={link.getURL()} target="_blank" rel="noreferrer">
              {link.getTitle()}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
