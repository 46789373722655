import eur1Img from '../assets/cart/EUR-1.svg'
import eur3Img from '../assets/cart/EUR-3.svg'
import eur6Img from '../assets/cart/EUR-6.svg'

/**
 * @author Rafael Rapizo Nery
 *
 * @class Represents pallet category available for shipping.
 *
 * @property {'EUR-1' | 'EUR-3' | 'EUR-6'} code Pallet code (currently) according to Euro/EPAL-pallet types.
 * @property {String} img Local path to icon of pallet.
 * @property {Number} width Width of pallet.
 * @property {Number} height Height of pallet.
 */
export class Pallet {
  code
  img
  width
  height

  /**
   * @author Rafael Rapizo Nery

   * @constructor
   * @description Instanciates a pallet according to the specified code.
   *
   * @param {'EUR-1' | 'EUR-3' | 'EUR-6'} code For instanciating a Pallet class we required only its EUR/EPAL code, since all its other properties are fixed and default values.
   * @returns {Pallet} Pallet class instance.
   */
  constructor(code) {
    this.code = code
    switch (code) {
      case 'EUR-1':
        this.img = eur1Img
        this.width = 120
        this.height = 80
        return
      case 'EUR-3':
        this.img = eur3Img
        this.width = 120
        this.height = 100
        return
      case 'EUR-6':
        this.img = eur6Img
        this.width = 80
        this.height = 60
        return
      default:
        this.img = eur1Img
        this.width = 120
        this.height = 80
        return
    }
  }

  /**
   * @author Rafael Rapizo Nery
   *
   * @description Retrivies support EUR/EPAL codes.
   *
   * @static
   * @function getCodes
   * @returns {Array<String>} Array containing EUR/EPAL-pallet codes supported by the platform.
   */
  static getCodes() {
    return ['EUR-1', 'EUR-3', 'EUR-6']
  }

  /**
   * @author Rafael Rapizo Nery
   *
   * @description Returns formatted identifier of pallet.
   *
   * @function getTitle
   * @returns {String} Formatted string for better data display of code.
   */
  getTitle() {
    return `${this.code} Pallet`
  }

  /**
   * @author Rafael Rapizo Nery
   *
   * @function getFormattedDimensions
   * @returns {String} Formatted information of width and height of pallet.
   */
  getFormattedDimensions() {
    return `${this.width} x ${this.height} cm`
  }
}
