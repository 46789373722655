import { useCallback, useEffect, useRef, useState } from 'react'

export const Tags = ({ product, layout = 'list' }) => {
  const [isTruncated, setIsTruncated] = useState(false)
  const wrapperRef = useRef(null)

  const mountList = useCallback(
    (
      list,
      container,
      style = {
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '5px 24px',
        marginRight: '5px',
      },
    ) => {
      if (container === null) return { newList: list, counter: 0 }

      let counter = 0
      let newList = list
      let isGreater = true
      while (isGreater) {
        const listWidth = newList.reduce((acc, item) => {
          const span = document.createElement('span')
          span.textContent = item
          span.style.display = 'inline-block'
          span.style.whiteSpace = 'nowrap'
          span.style.visibility = 'hidden'
          span.style.fontSize = style.fontSize
          span.style.fontWeight = style.fontWeight
          span.style.padding = style.padding
          span.style.marginRight = style.marginRight
          document.body.appendChild(span)
          const width = span.offsetWidth
          span.remove()
          return acc + width
        }, 0)
        isGreater = listWidth > container.offsetWidth
        if (isGreater) {
          newList = newList.slice(0, -1)
          counter++
        }
      }
      return { newList, counter }
    },
    [],
  )

  useEffect(() => {
    if (wrapperRef.current) {
      setIsTruncated(wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth)
    }
  }, [product.get_tags, wrapperRef, mountList])

  if (product.get_tags && product.get_tags.length > 0) {
    return (
      <div ref={wrapperRef} className="tags">
        {layout === 'list' ? (
          <ul>
            <li>Tags:</li>
            {isTruncated ? (
              <>
                {(() => {
                  const { newList, counter } = mountList(
                    product.get_tags.map((tag) => `#${tag.get_tag.name}`),
                    wrapperRef.current,
                  )

                  return (
                    <>
                      {newList.map((tag) => (
                        <li key={tag}>{tag}</li>
                      ))}
                      {counter > 0 ? <li>+{counter}</li> : null}
                    </>
                  )
                })()}
              </>
            ) : (
              product.get_tags.map((tag) => (
                <li className="mb-2" key={tag.id}>
                  #{tag.get_tag.name}
                </li>
              ))
            )}
          </ul>
        ) : (
          <>
            {(() => {
              const { newList, counter } = mountList(
                product.get_tags.map((tag) => `#${tag.get_tag.name}`),
                wrapperRef.current,
                {
                  fontSize: '10px',
                  fontWeight: 'normal',
                  padding: '0px 12px',
                  marginRight: '4px',
                },
              )

              return (
                <>
                  {newList.map((tag) => (
                    <div className="tags__tag" key={tag}>
                      {tag}
                    </div>
                  ))}
                  {counter > 0 ? <div>+{counter}</div> : null}
                </>
              )
            })()}
          </>
        )}
      </div>
    )
  } else
    return (
      <div className="tags" style={{ opacity: 0 }}>
        #notag
      </div>
    )
}
