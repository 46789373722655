import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, withRouter } from 'react-router-dom'
import ReactGA from 'react-ga'

import { Footer, Header } from './marketplace'

const mapStateToProps = ({ user }) => ({ user })

const MarketplaceLayout = ({ children, user }) => {
  const { pathname, search } = useLocation()
  const [disableMenu, setDisableMenu] = useState(false)

  useEffect(() => {
    ReactGA.pageview(pathname)
  }, [pathname, search])

  useEffect(() => {
    if (
      pathname.indexOf('/showroom/') !== -1 ||
      pathname.indexOf('/company/') !== -1 ||
      pathname.indexOf('/shop/') !== -1
    ) {
      setDisableMenu(true)
    } else {
      setDisableMenu(false)
    }
  }, [pathname])

  return (
    <>
      <Header disableMenu={disableMenu} user={user} />
      <div
        className={`marketplace-main ${
          pathname.match(/cart/gi) && 'marketplace-main--cart'
        }`}
      >
        {children}
      </div>
      <Footer />
    </>
  )
}

export default withRouter(connect(mapStateToProps)(MarketplaceLayout))
