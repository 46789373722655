import { ProductsPreview } from '.'

/**
 * @description CompanyCard's Footer component
 * @param {Array} products - Array of products
 * @param {Function} onViewProducts - Function to view products
 * @returns {JSX.Element}
 * @example
 * <Footer products={products} onViewProducts={onViewProducts} />
 * @see https://ant.design/components/dropdown/#components-dropdown-demo-hover
 * @see https://ant.design/components/dropdown/#API
 * @author Rafael Rapizo Nery
 * @created 2024-02-06
 */
export const Footer = ({ products, onViewProducts = () => null }) => {
  return (
    <div className="company-card__footer">
      <ProductsPreview data={products} onViewProducts={onViewProducts} />
    </div>
  )
}
