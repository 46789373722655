import { LinkBuilder } from '../../models/Link/LinkBuilder'

const BUILDER = new LinkBuilder()

BUILDER.createLocal('Products', '/products')
BUILDER.createLocal('Categories', '/explore/categories')
BUILDER.createLocal('Markets', 'explore/countries')
BUILDER.createLocal('Brands', 'explore/brands')

BUILDER.createExternal('About Us', 'https://about.tradeto.world/')
BUILDER.createExternal('How It Works', 'https://about.tradeto.world/how-it-works')
BUILDER.createExternal('Players', 'https://about.tradeto.world/players')
BUILDER.createExternal('Features', 'https://about.tradeto.world/features')
BUILDER.createExternal('Contacts', 'https://about.tradeto.world/contacts')

export const FOOTER_LINKS = BUILDER.getLinkList()
