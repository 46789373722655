import { Skeleton, Pagination } from 'antd'

import { limit } from '../../util/functions/limit'

import { useSearch } from '../../hooks/ui/useSearch'

import Header from '../../components/pages/marketplace/explore/Header'
import { Card } from './explore/components/Card'

const SearchPage = () => {
  const { data, pagination, title, isLoading, handlePageChange, handleRedirect } = useSearch()



  return (
    <div className="container mb-5">
      <div className="products-list products-list__no-filters">
        <div className="products">
          <Header title={title} hideViewToggle hideSort />
          {isLoading || !data ? (
            <Skeleton active />
          ) : (
            <>
              <div className="explore__list search__list">
                {data.map((item) => (
                  <Card
                    onClick={() => handleRedirect(item)}
                    className={'search__list__card'}
                    title={
                      item.name.length > 37 ? `${limit(item.name, 30)}...` : item.name
                    }
                    icon={
                      item.logo
                        ? item.logo
                        : item.get_main_image &&
                          !item.get_main_image.preview.match(/gruppobpc/gi)
                        ? item.get_main_image.preview
                        : 'https://d17jozkkv5mv8m.cloudfront.net/uploads/2024/01/20/65ac013073d7b65ac012ea1e66-1705771310.jpg'
                    }
                  />
                ))}
              </div>
              <div className="d-flex justify-content-center mt-4">
                {pagination && pagination.per_page < pagination.total ? (
                  <Pagination
                    size="small"
                    current={pagination.current_page}
                    pageSize={pagination.per_page}
                    total={pagination.total}
                    showSizeChanger={false}
                    onChange={(page) => handlePageChange(page)}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default SearchPage
