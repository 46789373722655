import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import { Icons } from '../../../../pages/marketplace/explore/ProductsList/ProductItem/Icons'

export const SupplierInfo = ({ product, layout = 'list', isSellerShowroom }) => {
  return (
    <div className="product-card__data__supplier">
      <div className={`${layout === 'list' && 'fw-bold'}`}>
        <Link to={`/showroom/${product.get_supplier.slug}`}>
          {product.get_supplier && product.get_supplier.name}
        </Link>
      </div>
      {!isSellerShowroom && (
        <Icons
          isInStock={
            product.sales_terms.length > 0 &&
            product.sales_terms[0].is_available &&
            product.sales_terms[0].available_qty > 0
          }
          isGuarantee={
            product.warranty_from_purchase ||
            product.warranty_from_shipping ||
            product.warranty_from_delivery
          }
          isHighPromotion={
            product.sales_terms.length > 0 && product.sales_terms[0].discount > 0
          }
        />
      )}
    </div>
  )
}
