import React from 'react'
import { Link } from 'react-router-dom'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

import styles from './products-wrapper.module.scss'

const ProductsWrapper = ({
  children,
  title,
  viewAllLink = null,
  viewAllMobileText = 'View All',
  isGrayBg = false,
  stylesObj = {},
  handleCarouselChange = () => null,
  hideViewAll = false,
  hideCarouselControl = false,
}) => {
  return (
    <div
      className={styles.productsWrapper}
      style={{ ...stylesObj, background: isGrayBg ? '#F7F7F7' : '#FFF' }}
    >
      <div className="homepage-container">
        <div className={styles.wrapper}>
          <div>
            <h2>{title}</h2>
          </div>
          {viewAllLink && !hideViewAll && (
            <div className={styles.link}>
              <Link to={viewAllLink}>View All</Link>
            </div>
          )}
          {!hideCarouselControl && (
            <div className="carousel__control ms-md-auto">
              <button
                className="carousel__control__button"
                onClick={() => handleCarouselChange('prev')}
              >
                <FaChevronLeft />
              </button>
              <button
                className="carousel__control__button"
                onClick={() => handleCarouselChange('next')}
              >
                <FaChevronRight />
              </button>
            </div>
          )}
        </div>
        <div>{children}</div>
        {!hideViewAll && (
          <div className={styles.viewAllMobile}>
            <Link to={viewAllLink}>{viewAllMobileText}</Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductsWrapper
