import React from 'react'

import { TabSystem } from '../../../ui/TabSystem'
import FiltersMobile from './FiltersMobile/FiltersMobile'
import { SortController } from './ProductsList/SortController/SortController'
import { ViewController } from '../../../../pages/marketplace/Catalogue/components/ViewController/ViewController'
import { Select } from 'antd'
import TagList from '../../../ui/TagList/TagList'
import RemovableTag from '../../../ui/Tag/RemovableTag/RemovableTag'

const Header = ({
  title,
  view,
  setView,
  hideViewToggle = false,
  hideNavToggle = false,
  hideTitle = false,
  tabs = [],
  sortOptions = [],
  onTabChange = () => null,
  onViewChange = () => null,
  onSortChange = () => null,
  hideSort = false,
  currentTab,
  filters = [],
  isLoadingFilters = false,
  onCategoryChange,
  onPriceRangeChange,
  onSelectchange,
  onClearFilters,
  onApplyFilters = () => null,
  isSellerShowroom = false,
  onRemoveFilter = () => null,
  tags,
}) => {
  const handleSelectChange = ({ query, value }) => {
    let params = onSelectchange({ query, value })
    onApplyFilters(params)
  }

  return (
    <div className={`explore-header`}>
      {!hideTitle && <h1>{title}</h1>}
      {!hideNavToggle && (
        <TabSystem
          currentTab={currentTab ?? 'products'}
          tabs={tabs}
          onChange={onTabChange}
        />
      )}
      <div className={`grid-list mb-4 ${hideTitle && hideNavToggle ? 'mt-0' : ''}`}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            {isSellerShowroom && (
              <div className="explore-header__filters-select align-items-center me-2 d-none d-lg-flex">
                <span className="sort-controller-desktop__caption me-2">Filter by:</span>
                {filters &&
                  filters.select.map((filter) => (
                    <Select
                      value={null}
                      className="sort-controller-desktop__select me-2"
                      placeholder={filter.query}
                      onChange={(value) =>
                        handleSelectChange({ query: filter.query, value })
                      }
                    >
                      {filter.options.map((option) => (
                        <Select.Option value={option.value}>{option.key}</Select.Option>
                      ))}
                    </Select>
                  ))}
              </div>
            )}
            {!hideSort ? (
              <SortController
                options={sortOptions}
                onChange={onSortChange}
                className="mb-0"
              />
            ) : null}
            <FiltersMobile
              filters={filters}
              isLoading={isLoadingFilters}
              onCategoryChange={onCategoryChange}
              onPriceRangeChange={onPriceRangeChange}
              onSelectchange={onSelectchange}
              onClearFilters={onClearFilters}
              onApplyFilters={onApplyFilters}
              isSellerShowroom={isSellerShowroom}
            />
          </div>
          {!hideViewToggle ? <ViewController view={view} onChange={setView} /> : null}
        </div>
        {isSellerShowroom && (
          <TagList
            className="ss-filter-list mt-3 d-none d-lg-flex"
            tags={tags}
            renderTag={(tag) => (
              <RemovableTag
                tag={tag}
                onRemove={() => onRemoveFilter(tag.query, `${tag.value}`)}
              />
            )}
          />
        )}
      </div>
    </div>
  )
}

export default Header
