import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import MarketplaceCard from '../../../ui/cards/marketplace/MarketplaceCard'
import { VectorMap } from 'react-jvectormap'

import styles from './sales-presence.module.scss'

const SalesPresence = ({ data }) => {
  const [mapData, setMapData] = useState([])

  useEffect(() => {
    const { exportCountries } = JSON.parse(data.export_countries)
    const newMapData = {}
    _.forEach(exportCountries, (country) => {
      newMapData[country.shortCode] = 999
    })
    setMapData(newMapData)
  }, [data])

  return (
    <MarketplaceCard title="Sales & Presence">
      <div className={`row ${styles.salesPresenceData}`}>
        <div className={`col-12 col-lg-4 ${styles.item}`}>
          <div className="d-flex justify-content-start align-items-center">
            <div className={styles.icon}>
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.7852 17.7766C35.7852 23.7404 31.6762 32.1106 26.6541 32.1106C21.632 32.1106 17.523 23.7404 17.523 17.7766C17.523 11.8129 21.632 7 26.6541 7C31.6762 7 35.7852 11.8129 35.7852 17.7766Z"
                  fill="#00968A"
                />
                <path
                  d="M41.9613 37.219C38.3202 35.7557 33.9674 34.3934 33.9674 34.3934L26.6541 40.1162L19.3407 34.3934C19.3407 34.3934 8.55226 37.7699 6.73689 39.6012C4.81779 41.5469 4.09164 48.0138 3.83231 51.9053C3.72857 53.5649 4.97339 54.9384 6.58129 54.9384H36.5069L37.2239 51.703C37.3927 50.9412 37.1413 50.1472 36.5647 49.6214L32.236 45.6741C30.8347 44.3963 31.6215 42.0623 33.5104 41.8936L38.995 41.4038C39.8107 41.331 40.5166 40.807 40.8225 40.0473L41.9613 37.219Z"
                  fill="#00968A"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M45.9496 53.0875C45.9842 53.0666 46.0276 53.0666 46.0622 53.0875L52.518 56.984C52.6005 57.0338 52.7023 56.9598 52.6804 56.866L50.9672 49.5222C50.958 49.4828 50.9714 49.4416 51.002 49.4151L56.7058 44.4739C56.7787 44.4108 56.7397 44.2911 56.6437 44.283L49.1329 43.6457C49.0926 43.6423 49.0576 43.6169 49.0418 43.5797L46.1062 36.6534C46.0687 36.5648 45.9431 36.5648 45.9056 36.6534L42.97 43.5797C42.9543 43.6169 42.9192 43.6423 42.8789 43.6457L35.3681 44.283C35.2721 44.2911 35.2331 44.4108 35.306 44.4739L41.0098 49.4151C41.0404 49.4416 41.0538 49.4828 41.0446 49.5222L39.3314 56.866C39.3095 56.9598 39.4113 57.0338 39.4938 56.984L45.9496 53.0875Z"
                  fill="#00968A"
                />
              </svg>
            </div>
            <div>
              <p className={styles.subTitle}>Ownership:</p>
              <h4 className={styles.title} style={{ fontSize: '18px' }}>
                {data.ownership}
              </h4>
            </div>
          </div>
        </div>
        <div className={`col-12 col-lg-4 ${styles.item}`}>
          <div className="d-flex justify-content-start align-items-center">
            <div className={styles.icon}>
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M45.0063 45.8833C44.5132 45.8833 44.0201 46.0066 43.7118 46.3148C39.9516 49.4587 35.205 51.2463 30.2736 51.2463C18.8079 51.2463 9.4997 41.9381 9.4997 30.4725V28.5615H12.9517C13.5065 28.5615 13.938 28.2533 14.2462 27.8218C14.4928 27.3287 14.4928 26.7739 14.1846 26.3424L8.88327 18.0821C8.63669 17.7123 8.20519 17.5273 7.6504 17.5273C7.0956 17.5273 6.6641 17.7739 6.41753 18.0821L1.23947 26.2807C0.931256 26.7122 0.931256 27.267 1.17783 27.7602C1.4244 28.2533 1.91755 28.4999 2.47234 28.4999H5.92438V30.4108C5.92438 43.8491 16.8353 54.76 30.3352 54.76C36.068 54.76 41.6776 52.7257 46.0543 49.0271C46.794 48.4107 46.9173 47.2395 46.2392 46.4998C45.931 46.1299 45.4995 45.8833 45.0063 45.8833Z"
                  fill="#00968A"
                />
                <path
                  d="M59.3695 33.0615C59.1229 32.5683 58.6298 32.3218 58.075 32.3218H54.623V30.4108C54.623 16.9725 43.7121 6 30.2121 6C24.4793 6 18.8697 8.03423 14.4931 11.7328C13.7533 12.3493 13.6301 13.5205 14.3081 14.2602C14.9246 14.9999 16.0958 15.1232 16.8355 14.4452C20.5958 11.3013 25.3423 9.51368 30.2738 9.51368C41.7395 9.51368 51.0476 18.8218 51.0476 30.2875V32.1985H47.5956C47.0408 32.1985 46.6093 32.5067 46.3011 32.9382C46.0545 33.3697 46.0545 33.9861 46.3627 34.4176L51.6641 42.7395C51.9106 43.1094 52.4038 43.3559 52.8969 43.3559C53.3901 43.3559 53.8832 43.1094 54.1298 42.7395L59.3695 34.5409C59.6161 34.1094 59.6161 33.5546 59.3695 33.0615Z"
                  fill="#00968A"
                />
                <path
                  d="M43.8349 30.4092C43.8349 22.8887 37.7322 16.8477 30.2734 16.8477C22.7529 16.8477 16.7118 22.9504 16.7118 30.4092C16.7118 37.8681 22.8145 43.9708 30.2734 43.9708C37.7939 43.9708 43.8349 37.9297 43.8349 30.4092ZM24.9104 33.0599H23.4926C23.246 33.0599 23.1227 32.875 23.1227 32.69V31.4572C23.1227 31.2106 23.3077 31.0873 23.4926 31.0873H24.5405C24.5405 30.8407 24.5405 30.6558 24.5405 30.4709C24.5405 30.2859 24.5405 30.0394 24.5405 29.8544H23.4926C23.246 29.8544 23.1227 29.6695 23.1227 29.4846V28.2517C23.1227 28.0051 23.3077 27.8818 23.4926 27.8818H24.9104C25.8967 25.0462 28.3624 23.1969 31.0747 23.1969C32.7391 23.1969 34.2802 23.875 35.5131 25.1079C35.5747 25.1695 35.6364 25.2928 35.6364 25.4161C35.6364 25.5394 35.5747 25.601 35.5131 25.7243L34.1569 26.9572C33.972 27.0805 33.7871 27.0805 33.6021 26.9572C32.8624 26.2175 31.9994 25.8476 31.0747 25.8476C29.7802 25.8476 28.5474 26.649 27.8076 27.9435H31.198C31.4446 27.9435 31.5679 28.1284 31.5679 28.3133V29.5462C31.5679 29.7928 31.383 29.9161 31.198 29.9161H27.1296C27.1296 30.1626 27.0679 30.3476 27.0679 30.5325C27.0679 30.7174 27.0679 30.964 27.1296 31.1489H31.198C31.4446 31.1489 31.5679 31.3339 31.5679 31.5188V32.7517C31.5679 32.9982 31.383 33.1215 31.198 33.1215H27.746C28.4857 34.416 29.7186 35.2174 31.0131 35.2174C31.9378 35.2174 32.8624 34.8476 33.5405 34.1078C33.6638 33.9845 33.9104 33.9229 34.0953 34.1078L35.4514 35.3407C35.5131 35.4023 35.5747 35.5256 35.5747 35.6489C35.5747 35.7722 35.5131 35.8338 35.4514 35.9571C34.2186 37.19 32.6775 37.8681 31.0131 37.8681C28.3624 37.7448 25.8967 35.8955 24.9104 33.0599Z"
                  fill="#00968A"
                />
              </svg>
            </div>
            <div>
              <p className={styles.subTitle}>Turnover:</p>
              <h4 className={styles.title}>
                ${new Intl.NumberFormat().format(data.turnover)}
              </h4>
            </div>
          </div>
        </div>
        <div className={`col-12 col-lg-4 ${styles.item}`}>
          <div className="d-flex justify-content-start align-items-center">
            <div className={styles.icon}>
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M47.6842 29.8974C46.1052 29.1395 45.1578 27.6869 45.0315 25.9816C42.9473 26.1079 40.9263 26.6132 39.0315 27.5606L35.8736 29.0132C35.3052 29.2658 34.6736 29.4553 34.0421 29.4553C32.6526 29.4553 31.2631 28.7605 30.4421 27.6237C29.621 26.4869 29.3684 24.9711 29.8105 23.6448C30.9473 20.0448 33.221 17.0764 36.0631 14.9922H19.8947C18.6947 14.9922 17.6842 16.0027 17.6842 17.2027V20.6132H14.021C11.5579 20.6132 9.34736 22.1922 8.58946 24.529L6.37894 31.0974L1.76842 31.9816C0.757894 32.1711 0 33.0553 0 34.1289V41.0763C0 42.2763 1.01053 43.2868 2.21052 43.2868H4.61052C4.61052 46.6973 7.38947 49.5394 10.8631 49.5394C14.2737 49.5394 17.1158 46.6973 17.1158 43.2868H33.7894C33.7894 46.6973 36.6315 49.5394 40.0421 49.5394C43.4526 49.5394 46.2947 46.6973 46.2947 43.2868H48.6947C49.8947 43.2868 50.9052 42.2763 50.9052 41.0763V30.2132C50.5894 30.2763 50.2105 30.3395 49.8315 30.3395C49.0736 30.4026 48.3157 30.2132 47.6842 29.8974ZM10.8 46.192C9.15788 46.192 7.83157 44.8657 7.83157 43.2236C7.83157 41.5815 9.15788 40.2552 10.8 40.2552C12.4421 40.2552 13.7684 41.5815 13.7684 43.2236C13.7684 44.8657 12.4421 46.192 10.8 46.192ZM17.6842 30.4026H10.0421L11.6842 25.4132C12 24.4027 12.9474 23.7711 14.021 23.7711H17.6842V30.4026ZM40.0421 46.192C38.4 46.192 37.0736 44.8657 37.0736 43.2236C37.0736 41.5815 38.4 40.2552 40.0421 40.2552C41.6842 40.2552 43.0105 41.5815 43.0105 43.2236C42.9473 44.8657 41.621 46.192 40.0421 46.192Z"
                  fill="#00968A"
                />
                <path
                  d="M59.4316 17.708L50.5895 10.9502C50.1474 10.6344 49.579 10.5712 49.1369 10.8239C48.6948 11.0765 48.379 11.5186 48.379 12.087V15.1186H45.9158C39.979 15.1186 34.7369 18.9712 32.9685 24.5922C32.7158 25.4764 33.6001 26.2343 34.4211 25.8554L37.579 24.4027C40.2948 23.1396 43.2632 22.508 46.2316 22.508H48.379V25.5396C48.379 26.0448 48.6948 26.5501 49.1369 26.8027C49.3263 26.9291 49.5158 26.9291 49.7684 26.9291C50.0842 26.9291 50.3369 26.8027 50.5895 26.6133L59.4316 19.8554C59.7474 19.6028 60 19.1607 60 18.7817C60 18.4028 59.8105 17.9607 59.4316 17.708Z"
                  fill="#00968A"
                />
              </svg>
            </div>
            <div>
              <p className={styles.subTitle}>Export:</p>
              <h4 className={styles.title}>{data.export}%</h4>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mapData}>
        <h3>Countries of Export</h3>
        <VectorMap
          map={'world_mill'}
          backgroundColor="transparent"
          zoomOnScroll={false}
          regionsSelectable={false}
          containerStyle={{
            width: '100%',
            height: '400px',
          }}
          zoomButtons={false}
          regionStyle={{
            initial: {
              fill: '#B2DFDB',
              'fill-opacity': 1,
              stroke: 'none',
              'stroke-width': 0,
              'stroke-opacity': 0,
            },
            hover: {
              'fill-opacity': 1,
              cursor: 'pointer',
            },
            selected: {
              'fill-opacity': 1,
              fill: '#00968A', //color for the clicked country
            },
            selectedHover: {},
          }}
          series={{
            regions: [
              {
                values: mapData, //this is your data
                scale: ['#00468F', '#00968A'],
                normalizeFunction: 'polynomial',
              },
            ],
          }}
        />
      </div>
    </MarketplaceCard>
  )
}

export default SalesPresence
