import { Button } from 'antd'
import { Card } from './Card'
import { FaChevronDown } from 'react-icons/fa'

export const List = ({
  content,
  classname,
  onClickCard = () => null,
  iconRounded = false,
  withDescription = false,
  infinite = false,
  childrenNext = null,
  onNext = () => null,
  hasMore = false,
}) => {
  return (
    <>
      <div className={`explore__list ${classname}`}>
        {content.map((item) => (
          <Card
            title={item.name}
            description={withDescription ? item.description : null}
            icon={item.icon}
            onClick={() => onClickCard(item)}
            iconRounded={iconRounded}
            isAvailable={item.isAvailable}
          />
        ))}
      </div>
      {infinite && hasMore ? (
        childrenNext ? (
          childrenNext
        ) : (
          <Button
            onClick={onNext}
            type="primary"
            className="ant-btn-ttu ant-btn--spread ant-btn-primary__outlined d-block mt-3 mx-auto"
          >
            load more <FaChevronDown className="ms-3" />
          </Button>
        )
      ) : null}
    </>
  )
}
