import { useState, useEffect, useCallback } from 'react'

import { Query } from '../../../models/Query'

import { fetchProducts } from '../../../util/functions'
import { removeMatchingKeys } from '../../../util/functions/fetch/removeKey'

import axiosRequest from '../../useAxiosRequest'
import useResponsive from '../../Responsive/useResponsive'
import { useFiltersSelection } from '../FiltersSelection/useFiltersSelection'
import { FilterHelper } from '../../../models/helpers/Filter/FilterHelper.class'

const PRODUCT_SORT_OPTIONS = [
  {
    title: 'Name (A-Z)',
    param: 'sortAlphabetically',
  },
  {
    title: 'Price (Lowest)',
    param: 'sortLowestPrice',
  },
  {
    title: 'Price (Highest)',
    param: 'sortHighestPrice',
  },
  {
    title: 'Newest',
    param: 'sortNewest',
  },
  {
    title: 'Oldest',
    param: 'sortOldest',
  },
]

const useSellerShowroom = (companyId) => {
  const [filters, setFilters] = useState(null)
  const [products, setProducts] = useState([])
  const [params, setParams] = useState({
    supplier: companyId,
    page: 1,
    categoryStrict: true,
  })
  const [pagination, setPagination] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(false)
  const [filterHelper, setFilterHelper] = useState(null)
  const { activeFilters, activeKeys } = useFiltersSelection()

  useResponsive({
    onMobile: () => setPageSize(8),
    onTablet: () => setPageSize(12),
    onDesktop: () => setPageSize(20),
  })

  const formatCategoryFiltersToSelect = (categories) => {
    let formattedCategoryFilter = {
      options: [],
      query: 'category',
      title: 'Categories',
    }

    categories.forEach((category) => {
      formattedCategoryFilter.options.push({
        value: category.industry,
        key: category.industry_details.name,
      })
    })

    return formattedCategoryFilter
  }

  const formatBrandFiltersToSelect = (brands) => {
    let formattedBrandFilter = {
      options: [],
      query: 'brand',
      title: "Company's Brands",
    }

    brands.forEach((brand) => {
      formattedBrandFilter.options.push({ value: brand.id, key: brand.name })
    })

    return formattedBrandFilter
  }

  const fetchFilters = useCallback(async () => {
    try {
      const response = await axiosRequest(`/api/v1/filters/${companyId}`, null, 'get')
      return response.data
    } catch (error) {
      console.error(error)
    }
  }, [companyId])

  const handlePageChange = useCallback(
    async (page) => {
      setIsLoading(true)
      setCurrentPage(page)
      let res = await fetchProducts({
        ...params,
        supplier: companyId,
        page: page,
        perPage: pageSize,
        categoryStrict: true,
      })
      setProducts(res.data)
      setPagination(res.pagination)
      setIsLoading(false)
    },
    [companyId, pageSize, params],
  )

  const handleSelectChange = useCallback(
    ({ query, value }) => {
      let parsedParams = { ...params }
      const regex = new RegExp(`^${query}.*?(?=&|$)`)
      const queryBuilder = new Query(null, null, ',')
      const currentValues = parsedParams[query]?.split(',')
      let values = currentValues ? [...currentValues] : []

      values = values.filter((val) => val !== '')
      if (values.includes(value)) {
        values = values.filter((val) => val !== value)
      } else {
        values.push(value)
      }

      parsedParams = removeMatchingKeys(parsedParams, regex)

      values.forEach((value) => {
        queryBuilder.append(value)
      })

      let newParams = { ...parsedParams, [query]: queryBuilder.buildParams() }

      setCurrentPage(1)
      setParams(newParams)
      return newParams
    },
    [params],
  )

  const handleSortChange = useCallback(
    async (sort) => {
      setIsLoading(true)
      setParams((prevParams) => ({ ...prevParams, ...sort }))
      let res = await fetchProducts({
        supplier: companyId,
        page: currentPage,
        perPage: pageSize,
        categoryStrict: true,
        ...sort,
      })
      setProducts(res.data)
      setPagination(res.pagination)
      setIsLoading(false)
    },
    [companyId, currentPage, pageSize],
  )

  const handleClearFilters = useCallback(async () => {
    setIsLoading(true)
    let res = await fetchProducts({
      supplier: companyId,
      page: 1,
      perPage: pageSize,
      categoryStrict: true,
    })

    setProducts(res.data)
    setPagination(res.pagination)
    setParams({ supplier: companyId, page: 1, perPage: pageSize })
    setCurrentPage(1)
    setIsLoading(false)
  }, [companyId, pageSize])

  const handleRemoveFilter = useCallback(
    async (query) => {
      setIsLoading(true)
      let parsedParams = { ...params }
      const regex = new RegExp(`^${query}.*?(?=&|$)`)
      parsedParams = removeMatchingKeys(parsedParams, regex)

      let res = await fetchProducts({
        supplier: companyId,
        page: 1,
        perPage: pageSize,
        categoryStrict: true,
        ...parsedParams,
      })

      setProducts(res.data)
      setPagination(res.pagination)
      setParams(parsedParams)
      setCurrentPage(1)
      setIsLoading(false)
    },
    [companyId, pageSize, params],
  )

  const handleRemoveFilterValue = useCallback(
    async (query, value) => {
      setIsLoading(true)
      let parsedParams = { ...params }
      const regex = new RegExp(`^${query}.*?(?=&|$)`)
      const queryBuilder = new Query(null, null, ',')
      const currentValues = parsedParams[query]?.split(',')
      let values = currentValues ? [...currentValues] : []

      values = values.filter((val) => val !== '')
      values = values.filter((val) => val !== value)

      parsedParams = removeMatchingKeys(parsedParams, regex)

      values.forEach((value) => {
        queryBuilder.append(value)
      })

      let newParams = { ...parsedParams, [query]: queryBuilder.buildParams() }

      let res = await fetchProducts({
        supplier: companyId,
        page: 1,
        perPage: pageSize,
        categoryStrict: true,
        ...newParams,
      })

      setProducts(res.data)
      setPagination(res.pagination)

      setParams(newParams)
      setCurrentPage(1)
      setIsLoading(false)

      return newParams
    },
    [companyId, pageSize, params],
  )

  const handleApplyFilters = useCallback(
    async (paramArgs = null) => {
      try {
        setIsLoading(true)
        let queryParam = paramArgs ? paramArgs : params

        let res = await fetchProducts({
          supplier: companyId,
          page: currentPage,
          perPage: pageSize,
          categoryStrict: true,
          ...queryParam,
        })
        setProducts(res.data)
        setPagination(res.pagination)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    },
    [companyId, currentPage, pageSize, params],
  )

  const createTags = (filterHelper) => {
    return filterHelper
      ? filterHelper.getFiltersFromActiveKeys().map((tag) => ({
          key: tag.value,
          label: tag.key,
          value: tag.value,
          query: tag.query,
        }))
      : []
  }

  useEffect(() => {
    fetchFilters().then((data) => {
      setFilters({
        select: [
          formatBrandFiltersToSelect(data.brands),
          formatCategoryFiltersToSelect(data.categories),
        ],
      })
    })
  }, [fetchFilters])

  useEffect(() => {
    let params = {
      supplier: companyId,
      page: 1,
      perPage: window.innerWidth <= 768 ? 8 : window.innerWidth <= 1024 ? 12 : 20,
      categoryStrict: true,
    }

    setParams((prevParams) => ({ ...prevParams, ...params }))
    ;(async () => {
      let res = await fetchProducts(params)
      if (res) {

        if (res.data) {
          res.data = [...res.data].sort(
            (a, b) => {
              if (Number.parseInt(a.order) >= 0 && Number.parseInt(b.order) >= 0) {
                return Number.parseInt(a.order) - Number.parseInt(b.order)
              }
              return 0
            },
          )
        }

        setProducts(res.data)



        setPagination(res.pagination)
      }
    })()
  }, [companyId])

  useEffect(() => {
    if (filters) setFilterHelper(new FilterHelper({ activeKeys, activeFilters, filters }))
  }, [activeKeys, activeFilters, filters])

  return {
    filters,
    sortOptions: PRODUCT_SORT_OPTIONS,
    products,
    pagination,
    isLoading,
    handleSelectChange,
    handleSortChange,
    handleClearFilters,
    handleApplyFilters,
    handleRemoveFilter,
    handleRemoveFilterValue,
    handlePageChange,
    tags: createTags(filterHelper),
  }
}

export default useSellerShowroom
