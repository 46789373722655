import { SummaryBody, SummaryFooter } from './'

export const Summary = ({
  selectedOrders,
  companies,
  parseOrderPerCompanies,
  isLogisticQt,
  crrQt
}) => {
  return (
    <div className="col-lg-4 cart__summary">
      <div className="cart__summary__header">
        <p>{isLogisticQt ? 'Products' : 'Summary'}</p>
      </div>
      <SummaryBody
        isLogisticQt={isLogisticQt}
        selectedOrders={selectedOrders}
        companies={companies}
        parseOrderPerCompanies={parseOrderPerCompanies}
        crrQt={crrQt}
      />
      <SummaryFooter selectedOrders={selectedOrders} />
    </div>
  )
}
