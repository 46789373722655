import { useSelector } from 'react-redux'

import { usePrices } from '../../../../../hooks/usePrices'

export const HeaderMenu = () => {
  const { orders } = useSelector((state) => state.cart)
  const { total } = usePrices(orders)

  return (
    <>
      <p>
        Products in cart: <span>{orders.length}</span>
      </p>
      <span>${total.toFixed(2)}</span>
    </>
  )
}
