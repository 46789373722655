import { Divider } from 'antd'
import { FaLinkedin } from 'react-icons/fa'

import { Links, NewsletterForm, PolicyAndTerm, Registry, Logo } from './components'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useMemo } from 'react'

export const Footer = () => {
  const { pathname } = useLocation()

  const isHideContent = useMemo(() => {
    return pathname.includes('/showroom/') || pathname.includes('/company/')
  }, [pathname])

  return (
    <div className="marketplace-footer">
      <div className="container">
        {!isHideContent && (
          <div className="row">
            <div className="row col-md-3">
              <Logo />
            </div>
            <Links />
            <div className="row col-12 col-md-4 mt-2">
              <div className="col-12">
                <NewsletterForm />
              </div>
            </div>
          </div>
        )}
        <Divider style={{ background: '#fff' }} />
        <div className="row text-center ">
          {!isHideContent && (
            <>
              <div className="col-12 d-flex d-md-none align-items-center justify-content-center gap-2">
                <a
                  href="https://www.linkedin.com/company/tradetoworld/"
                  target="_blank"
                  rel="noreferrer"
                  className="m-0 d-flex gap-2"
                >
                  <FaLinkedin />
                  Follow us on Linkedin
                </a>
              </div>
              <PolicyAndTerm
                policyURL="https://about.tradeto.world/legal/privacy-policy"
                termURL="https://about.tradeto.world/legal/terms"
              />
            </>
          )}
          <Registry />
        </div>
      </div>
    </div>
  )
}
