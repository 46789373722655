import { history } from '../index'

const convertObjToUrlParams = (obj) => {
  // Convert the object entries to an array, filter out entries without a value,
  // map each key-value pair to a string in the format 'key=value', and join them with '&'
  const str = Object.entries(obj)
    .filter(([key, value]) => value)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  // If the string is not empty, prepend it with '?', otherwise leave it as an empty string
  const urlParams = str ? `?${str}` : '';

  // Push the new URL, which is the current pathname plus the URL parameters, to the history stack
  history.push(`${history.location.pathname}${urlParams}`);

  // Return the URL parameters
  return urlParams;
};

export default convertObjToUrlParams
