import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import './ArrowButton.scss'

const ArrowButton = ({
  type = 'prev',
  size = 'small',
  className = '',
  style = null,
  icon = null,
  disabled = false,
  onClick = () => null,
}) => {
  return (
    <button
      data-testid={`${type}-arrow`}
      className={`arrow-btn arrow-btn--${size} ${
        disabled ? 'arrow-btn--disabled' : ''
      } ${className}`}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="arrow-btn__icon">
        {icon ? (
          icon
        ) : type === 'prev' ? (
          <span>
            <ArrowLeftOutlined />
          </span>
        ) : (
          <span>
            <ArrowRightOutlined />
          </span>
        )}
      </div>
    </button>
  )
}

export default ArrowButton
