import { Modal, Carousel, Image } from 'antd'

import ArrowButton from '../../Buttons/ArrowButton/ArrowButton'

import './PicturePreview.scss'

/**
 * @description PicturePreview component is used to display the product images in a modal.
 * @category Component
 * @namespace UI
 * @component PicturePreview
 * @param {Object} props
 * @prop {Boolean} props.isVisible - The visibility of the modal.
 * @prop {Function} props.handleVisibility - The function to handle the visibility of the modal.
 * @prop {Function} props.afterChange - The function to be called after the change of the image.
 * @prop {Object} props.modalStyle - The style of the modal.
 * @prop {Number} props.modalInitialSlide - The initial slide of the modal.
 * @prop {Array} props.images - The images to be displayed.
 * @example
 * return (
 *  <PicturePreview
 *   isVisible={isVisible}
 *   handleVisibility={handleVisibility}
 *   afterChange={afterChange}
 *   modalStyle={modalStyle}
 *   modalInitialSlide={modalInitialSlide}
 *   images={images}
 * />
 * )
 * @version 0.1.2
 * @since 0.1.2
 * @author Rafael Rapizo Nery
 */
const PicturePreview = ({
  isVisible = false,
  handleVisibility = () => null,
  afterChange = () => null,
  modalStyle = {},
  modalInitialSlide = 0,
  images = [],
  adaptativeHeight = false
}) => {
  return (
    <>
      <Modal
        visible={isVisible}
        onCancel={() => handleVisibility(!isVisible)}
        footer={null}
        destroyOnClose
        style={modalStyle}
        className="product-gallery--modal__container"
      >
        <div className="product-details">
          <div className="product-gallery--modal">
            <Carousel
              arrows
              infinite
              draggable
              dots={false}
              lazyLoad="ondemand"
              adaptiveHeight={adaptativeHeight}
              afterChange={(current) => afterChange(current)}
              initialSlide={modalInitialSlide}
              prevArrow={<ArrowButton size="large" />}
              nextArrow={<ArrowButton type="next" size="large" />}
            >
              {images.length > 0 &&
                images.map((image) => (
                  <div key={image.id}>
                    <Image
                      loading="lazy"
                      preview={false}
                      fallback={`${process.env.REACT_APP_CDN_URL}/web/common/product-image-placeholder.svg`}
                      src={image.original}
                    />
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default PicturePreview
