import { isInvalidCDN } from '../../../../../util/functions/checks/strings'

import { Body } from '.'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'

/**
 * @description CompanyCard's Info component
 * @param {Object} company - Company object
 * @param {String} view - View type
 * @param {Function} onViewProducts - Function to view products
 * @returns {JSX.Element}
 * @example
 * <Info company={company} view={view} onViewProducts={onViewProducts} />
 * @author Rafael Rapizo Nery
 * @created 2024-02-06
 */
export const Info = ({ company, view = 'grid', onViewProducts = () => null }) => {
  let { name, logo, country, slug, get_industries: industries } = company

  let parseCategories = (categories) => {
    return categories.map(
      (category) =>
        ({
          name: category.industry.name,
          icon: category.industry.icon,
        } ?? []),
    )
  }

  return (
    <div className="company-card__info">
      <ErrorBoundary>
        <Body
          name={name}
          slug={slug}
          logo={!isInvalidCDN(logo) ? logo : null}
          country={country}
          view={view}
          industries={parseCategories(industries ?? [])}
          brands={company.get_brands ?? []}
          onViewProducts={onViewProducts}
        />
      </ErrorBoundary>
    </div>
  )
}
