import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Modal } from 'antd'

import { history } from '../../../../..'

import { usePrices } from '../../../../../hooks/usePrices'

import { actions } from '../../../../../redux/cart/actions'

export const SummaryFooter = ({ selectedOrders }) => {
  const dispatch = useDispatch()
  const { total, discount, original } = usePrices(selectedOrders)
  const [isShowConfirmation, setIsShowConfirmation] = useState(false)

  const handleSubmitRequest = () => {
    setIsShowConfirmation(true)
  }

  const handleConfirmRequest = () => {
    setIsShowConfirmation(false)
    dispatch({
      type: actions.CLEAR,
    })

    history.push('/')
  }

  return (
    <>
      <Modal
        closable={false}
        visible={isShowConfirmation}
        title={<strong>Your request was successfully sent!</strong>}
        style={{ textAlign: 'center' }}
        footer={
          <Button
            type="primary"
            className="ant-btn-ttu w-100"
            onClick={handleConfirmRequest}
          >
            RETURN TO MARKEPLACE
          </Button>
        }
      >
        <p>
          The request was successfully received by the seller's company and by our Sales
          Team.
        </p>
        <p className="mb-0">We'll contact you as soon as possible.</p>
      </Modal>
      <div className="cart__summary__footer">
        {selectedOrders.length >= 1 && (
          <>
            <div className="cart__summary__footer__t-price">
              <p>
                <strong>Total Price</strong>
              </p>
              <span>
                {selectedOrders[0].selectedPrice.currency}{' '}
                {total.toFixed(2)}
              </span>
            </div>
            <div className="cart__summary__footer__t-discount">
              <p>Original Price</p>
              <span>
                <s>
                  {selectedOrders[0].selectedPrice.currency}{' '}
                  {original.toFixed(2)}
                </s>
              </span>
            </div>
            <div className="cart__summary__footer__t-discount">
              <p>Total Discount</p>
              <span>
                {selectedOrders[0].selectedPrice.currency}{' '}
                {discount.toFixed(2)}
              </span>
            </div>
          </>
        )}
        <Button
          type={`${selectedOrders.length <= 0 ? 'dashed' : 'primary'}`}
          disabled={selectedOrders.length <= 0}
          className="ant-btn ant-btn-ttu"
          onClick={handleSubmitRequest}
        >
          send request
        </Button>
      </div>
    </>
  )
}
