import axios from "axios"


export const fetchFilters = async (target = '') => {
  try {
    let res = await axios.get(`${process.env.REACT_APP_MAIN_API_URL}/api/v1/filters${target ? `/?for=${target}` : ''}`)
    if (res.status === 200) {
      const data = res.data
      return data
    } else {
      throw new Error(`Error fetching filters`)
    }
  } catch (error) {
    console.error(error)
  }
}
