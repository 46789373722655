import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'

import axiosRequest from '../../../../../../hooks/useAxiosRequest'
import ProductAttributes from '../../../product-details/ProductAttributes'
import ProductPackaging from '../../../product-details/ProductPackaging'

import { DescriptionCard } from './index'

export const ListDetails = ({ product, expanded, setMainImage }) => {
  const [loading, setLoading] = useState(true)
  const [productDetails, setProductDetails] = useState(null)

  const getProductDetails = (slug) => {
    setLoading(true)
    axiosRequest(`/api/v1/marketplace/product/${slug}`, null, 'get', true)
      .then((res) => {
        if (res && res.data.success) {
          setProductDetails(res.data.data)
          if (
            res.data.data &&
            res.data.data.product &&
            res.data.data.product.get_all_images &&
            res.data.data.product.get_all_images[0]
          ) {
          }
          setLoading(false)
        }
      })
      .catch(() => {
        alert('Something goes wrong, try again later...')
      })
  }

  const handleChangeProductVariant = (slug) => {
    getProductDetails(slug)
  }

  useEffect(() => {
    getProductDetails(product.slug)
  }, [product])

  if (!product) {
    return null
  }

  return (
    <div
      className={`expanded-details ${
        expanded === product.id ? 'expanded-details__visible' : ''
      }`}
    >
      {loading || !productDetails ? (
        <Skeleton active />
      ) : (
        <>
          <ProductAttributes
            product={product}
            preventLinks
            handleChangeProductVariant={handleChangeProductVariant}
          />
          <DescriptionCard product={product} type={'desc'} />
          <DescriptionCard product={product} type={'detail'} />
          <div className="product-details">
            <ProductPackaging product={productDetails} />
          </div>
        </>
      )}
    </div>
  )
}
