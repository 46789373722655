import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

export function ExpandController({ product, expanded, setExpanded }) {
  return (
    <div className="expand-product">
      <a
        href="/"
        onClick={(e) => {
          e.preventDefault()

          if (expanded) {
            setExpanded(null)
          } else {
            setExpanded(product.id)
          }
        }}
      >
        {expanded ? 'Hide' : 'Show'} Product Info
        {expanded ? (
          <>
            <FaChevronUp />
          </>
        ) : (
          <>
            <FaChevronDown />
          </>
        )}
      </a>
    </div>
  )
}
