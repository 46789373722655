import { Address } from '../../models/Address'

/**
 * Gets addresses based on user information, including headquarters and branches.
 *
 * @param {object} user - User information object containing country, city, address, zip, and branches.
 * @returns {Array<Address>} - An array of Address objects representing the user's addresses.
 */
export const getAddresses = (user) => {
  const addressess = []

  // Create the headquarters address using user information
  const hq = new Address(user.country, user.city, user.address, user.zip)
  addressess.push(hq)

  // Check if the user has branches and add branch addresses to the array
  if (user.get_branches && user.get_branches.length >= 1) {
    for (const br of user.get_branches) {
      // Create a new Address object for each branch
      const address = new Address(br.country, br.code, br.address, br.zip)
      addressess.push(address)
    }
  }

  return addressess
}
