import ProductAttributes from '../../../product-details/ProductAttributes'
import { DescriptionCard } from '../ListDetails'

export const PreviewData = ({
  isShowAttributes,
  isShowDescription,
  product,
  expanded,
}) => {
  return (
    <>
      {isShowAttributes && !(expanded === product.id) && (
        <ProductAttributes
          product={product}
          handleChangeProductVariant={() => null}
          asBanner
        />
      )}
      {isShowDescription && !(expanded === product.id) && (
        <DescriptionCard product={product} type={'desc'} />
      )}
    </>
  )
}
