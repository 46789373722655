import React from 'react'
import { Rate } from 'antd'

import { SupplierInfo } from './SupplierInfo'
import { ExpandController } from './ExpandController'
import { Title } from './Title'

export const Data = ({
  shopOptions,
  disableProductLinks,
  expanded,
  setExpanded,
  product,
  layout = 'list',
}) => {
  /* const Wrapper =
    layout === 'list'
      ? React.createElement('div', { className: 'product-data' })
      : React.Fragment */

  return (
    <>
      <div className={layout === 'list' && 'product-data'}>
        <SupplierInfo product={product} />
        <Title
          disableProductLinks={disableProductLinks}
          shopOptions={shopOptions}
          expanded={expanded}
          setExpanded={setExpanded}
          product={product}
        />
        {layout === 'list' ? (
          <>
            <div className="description">
              <Rate value={5} disabled />
              <span className="rate-text">Quality of responses (48)</span>
              <div className="mt-3">
                <p className="mb-0 ttw-code">TTW code: {product && product.product_data ? product.product_data.ttw_code : null}</p>
              </div>
            </div>
            <ExpandController
              product={product}
              expanded={expanded}
              setExpanded={setExpanded}
            />
          </>
        ) : null}
      </div>
    </>
  )
}
