export class LogisticQuoteData {
  constructor(qt) {
    this.seller_data = {
      supplier_id: qt.seller.id,
      first_name: qt.seller.first_name,
      last_name: qt.seller.last_name,
      company: qt.seller.company,
      phone: `${qt.seller.phone_code} ${qt.seller.phone}`,
      email: qt.seller.email,
      loading_address: {
        country: qt.address_seller.country,
        city: qt.address_seller.city,
        street: qt.address_seller.street,
        zip: qt.address_seller.zip,
        isStock: qt.address_seller.isStock,
      },
    }
    this.buyer_data = {
      user_id: qt.buyer.id,
      first_name: qt.buyer.first_name,
      last_name: qt.buyer.last_name,
      company: qt.buyer.company,
      phone: `${qt.buyer.phone_code} ${qt.buyer.phone}`,
      email: qt.buyer.email,
      shipping_address: {
        country: qt.address_buyer.shippingAddress.country,
        city: qt.address_buyer.shippingAddress.city,
        street: qt.address_buyer.shippingAddress.street,
        zip: qt.address_buyer.shippingAddress.zip,
        is_stock: qt.address_buyer.shippingAddress.isStock,
      },
      billing_address: {
        // ---------------------------------------------------------------
        billing_company_name: qt.address_buyer.billingAddress.billing_company || null,
        billing_registration_number:
          qt.address_buyer.billingAddress.billing_registration_number || null,
        billing_tax_number: qt.address_buyer.billingAddress.billing_tax_number || null,
        // ---------------------------------------------------------------
        country: qt.address_buyer.billingAddress.country,
        city: qt.address_buyer.billingAddress.city,
        street: qt.address_buyer.billingAddress.street,
        zip: qt.address_buyer.billingAddress.zip,
        is_stock: qt.address_buyer.billingAddress.isStock,
      },
    }
    this.shipment_data = {
      is_dangerous_goods: qt.shipment_data.dangerous_goods,
      is_required_insurance: qt.shipment_data.require_insurance,
      is_already_palletized: qt.shipment_data.is_palletized,
      delivery_date: qt.shipment_data.delivery_date,
      pickup_date: qt.shipment_data.pickup_date,
      incoterm: qt.shipment_data.incoterm,
      wrapper: qt.shipment_data.wrapper,
      pallet_category: qt.shipment_data.pallet_category,
      container: qt.shipment_data.container_distribution,
      extra_needs: qt.shipment_data.extra_needs,
      transport: {
        type: qt.shipment_data.transport.type,
        condition: qt.shipment_data.transport.condition,
        temperature: {
          type: qt.shipment_data.transport.temperature.type,
          degree: qt.shipment_data.transport.temperature.degree,
        },
      },
    }
    this.additional_notes = qt.additional_notes
    this.order_data = {
      products: [],
    }
    for (const o of qt.orders) {
      this.order_data.products.push({
        product_id: o.product.id,
        quantity: o.quantity,
        selected_price_id: o.selectedPrice.id,
        total_price: o.totalPrice,
      })
    }

    console.log({ quoteData: this })
  }
}
