import { useState, useEffect } from 'react'
import { Select, Button, Modal, message, notification } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import axios from 'axios'

import { history } from '../../../../../index'

import { useCreatePDF } from '../../../../../hooks/useCreatePDF'

export const QuoteReview = ({ quote, quoteHandler, redirect }) => {
  const { setStage, stages } = redirect
  const [isShowConfirmation, setIsShowConfirmation] = useState(false)
  const { downloadPDF } = useCreatePDF()

  const handleNoteChange = (value, quote, callback) => {
    quote.setAdditionalNotes(value)
    callback(quote)
  }

  const handleSubmitLogisticQuote = async (quote) => {
    message.loading('Processing review...')
    try {
      const res = await axios.post(`${process.env.REACT_APP_MAIN_API_URL}/api/review-to-pdf`, quote.toData(), {
        responseType: 'blob',
      })
      if (res.status === 200) {
        let encodedPDF = res.data
        downloadPDF(encodedPDF)
      }
    } catch (error) {
      notification.error({
        message: 'Something went wrong!',
        description:
          'Something went wrong when generating you quotation. Check the provided data or contact support for more information.',
      })
    } finally {
      message.destroy()
    }

    setIsShowConfirmation(true)
  }

  const formatLogisticNeeds = (values = []) => {
    let formattedValues = ''

    for (const v of values) {
      if (!values[values.indexOf(v) + 1]) {
        formattedValues += `${v}`
      } else {
        formattedValues += `${v}, `
      }
    }

    return formattedValues
  }

  const confirmRequest = () => {
    setIsShowConfirmation(false)
    history.push('/')
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Modal
        closable={false}
        visible={isShowConfirmation}
        title={<strong>Your request was successfully sent!</strong>}
        style={{ textAlign: 'center' }}
        footer={
          <Button type="primary" className="ant-btn-ttu w-100" onClick={confirmRequest}>
            RETURN TO MARKEPLACE
          </Button>
        }
      >
        <p>
          The request was successfully received by the seller's company and by our Sales
          Team.
        </p>
        <p className="mb-0">We'll contact you as soon as possible.</p>
      </Modal>
      <div className="row logistic_quote__review">
        <div className="col-12 logistic_quote__review__addressess">
          <div className="edit-link" onClick={() => setStage(stages[0])}>
            <i className="fe fe-edit-2"></i>
          </div>
          <div className="logistic_quote__review__addressess__seller">
            <p className="title">
              <strong>Seller Information</strong>
            </p>
            <p>
              <strong>{quote.seller.company}</strong>
            </p>
            <p className="info">{`${quote.seller.first_name} ${quote.seller.last_name}     p: ${quote.seller.phone_code} ${quote.seller.phone}     e: ${quote.seller.email}`}</p>
            <p>
              <strong>Loading Address</strong>
            </p>
            <p className="info">{`${
              quote.address_seller.isStock ? 'Stock' : 'HQ Address'
            } - ${quote.address_seller.street}, ${quote.address_seller.city}, ${
              quote.address_seller.country
            }  ${quote.address_seller.zip}`}</p>
          </div>
          <div className="logistic_quote__review__addressess__buyer">
            <p className="title">
              <strong>Buyer Information</strong>
            </p>
            <p>
              <strong>{quote.buyer.company}</strong>
            </p>
            <p className="info">{`${quote.buyer.first_name} ${quote.buyer.last_name}  p: ${quote.buyer.phone_code} ${quote.seller.phone}  e: ${quote.seller.email}`}</p>
            <div className="row">
              <div className="col-6">
                <p>
                  <strong>Shipping Address</strong>
                </p>
                <p className="info">{quote.buyer.company}</p>
                <p className="info">{`${quote.address_buyer.shippingAddress.street}, ${quote.address_buyer.shippingAddress.city}, ${quote.address_buyer.shippingAddress.country}  ${quote.address_seller.zip}`}</p>
              </div>
              <div className="col-6">
                <p>
                  <strong>Billing Address</strong>
                </p>
                <p className="info">{quote.buyer.company}</p>
                <p className="info">{`${quote.address_buyer.billingAddress.street}, ${quote.address_buyer.billingAddress.city}, ${quote.address_buyer.billingAddress.country}  ${quote.address_seller.zip}`}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 logistic_quote__review__shipment">
          <div className="edit-link" onClick={() => setStage(stages[1])}>
            <i className="fe fe-edit-2"></i>
          </div>
          <p className="title">
            <strong>Shipment data</strong>
          </p>
          <div className="row">
            <div className="col-10">
              <p>Transport Type</p>
            </div>
            <div className="col-2">{quote.shipment_data.transport.type}</div>
          </div>
          <div className="row">
            <div className="col-10">
              <p>Incoterm</p>
            </div>
            <div className="col-2">{quote.shipment_data.incoterm}</div>
          </div>
          <div className="row">
            <div className="col-10">
              <p>Pallet</p>
            </div>
            <div className="col-2">{quote.shipment_data.pallet_category}</div>{' '}
            {/* // fix */}
          </div>
          <div className="row">
            <div className="col-10">
              <p>Container</p>
            </div>
            <div className="col-2">{quote.shipment_data.container_distribution}</div>
          </div>
          <div className="row">
            <div className="col-10">
              <p>Stackable</p>
            </div>
            <div className="col-2">No</div>
          </div>
          <div className="row">
            <div className="col-10">
              <p>Temperature Requirements</p>
            </div>
            <div className="col-2">
              {quote.shipment_data.transport.temperature.degree}{' '}
              {quote.shipment_data.transport.temperature.type === 'celsius' ? 'C°' : 'F°'}
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <p>Transport Condition</p>
            </div>
            <div className="col-2">{quote.shipment_data.transport.condition}</div>
          </div>
          <div className="row">
            <div className="col-10">
              <p>Insurance Needed?</p>
            </div>
            <div className="col-2">
              {quote.shipment_data.require_insurance ? 'Yes' : 'No'}
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <p>Delivery Mandatory Date</p>
            </div>
            <div className="col-2">
              {quote.shipment_data.delivery_date
                ? quote.shipment_data.delivery_date
                : 'Optional'}
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <p>Additional Logistic Needs</p>
            </div>
            <div className="col-2">
              {formatLogisticNeeds(quote.shipment_data.extra_needs)}
            </div>
          </div>
        </div>
        <div className="col-12 mt-2 mb-4 logistic_quote__review__notes">
          <p className="title">
            <strong>Special Notes</strong>
          </p>
          <TextArea
            className="w-100"
            placeholder="Enter your note..."
            rows={6}
            onChange={(e) => handleNoteChange(e.target.value, quote, quoteHandler)}
          />
        </div>
        <div className="col-12 logistic_quote__review__submit">
          <p className="title">
            <strong>Submit to:</strong>
          </p>
          <Select defaultValue={'service-provider'}>
            <Select.Option value={'service-provider'}>Service Provider</Select.Option>
          </Select>
        </div>
        <div className="col-12">
          <Button
            onClick={() => setStage(stages[1])}
            type="primary"
            className="ant-btn-primary__outlined ant-btn-ttu"
          >
            Back
          </Button>
          <Button
            type="primary"
            onClick={() => handleSubmitLogisticQuote(quote)}
            className="ant-btn-ttu ms-4"
          >
            Send Request
          </Button>
        </div>
      </div>
    </>
  )
}
