export const INCOTERMS = [
  {
    code: 'EXW',
    name: 'Ex Works'
  },
  {
    code: 'FCA',
    name: 'Free Carrier'
  },
  {
    code: 'CPT',
    name: 'Carriage Paid To'
  },
  {
    code: 'CIP',
    name: 'Carriage & Insurance Paid To'
  },
  {
    code: 'DPU',
    name: 'Delivery At Place Unloaded'
  },
  {
    code: 'DAP',
    name: 'Delivered At Place'
  },
  {
    code: 'DDP',
    name: 'Delivered Duty Paid'
  },
  {
    code: 'FAS',
    name: 'Free Alongside Ship'
  },
  {
    code: 'FOB',
    name: 'Free On Board'
  },
  {
    code: 'CFR',
    name: 'Cost & Freight'
  },
  {
    code: 'CIF',
    name: 'Cost, Insurance & Freight'
  }
]
