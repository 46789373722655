import { Body, Footer, Header } from './index'

export const PopUp = ({ type }) => {
  return (
    <>
      <div className="cart-popup">
        <Header type={type} />
        <Body type={type} />
        <Footer type={type} />
      </div>
    </>
  )
}
