import { HistoryHelper } from '../../../models/helpers/HistoryHelper'

/**
 * Redirect to home
 * @returns {void}
 * @example
 * redirectHome()
 * @author Rafael Rapizo Nery
 * @created 2024-02-06
 */
export const redirectHome = () => HistoryHelper.redirect('/')

/**
 * Redirect to product details
 * @param {string} slug
 * @returns {void}
 * @example
 * redirectProductDetails('my-product')
 * @author Rafael Rapizo Nery
 * @created 2024-02-06
 */
export const redirectProductDetails = (slug) => HistoryHelper.redirect(`/product/${encodeURIComponent(slug)}`)

/**
 * Redirect to showroom
 * @param {string} slug
 * @returns {void}
 * @example
 * redirectShowroom('my-showroom')
 * @author Rafael Rapizo Nery
 * @created 2024-02-06
 */
export const redirectShowroom = (slug) => HistoryHelper.redirect(`/showroom/${encodeURIComponent(slug)}`)

/**
 * Redirect to company facts
 * @param {string} slug
 * @returns {void}
 * @example
 * redirectCompanyFacts('my-company')
 * @author Rafael Rapizo Nery
 * @created 2024-02-06
 */
export const redirectCompanyFacts = (slug) => HistoryHelper.redirect(`/company/${encodeURIComponent(slug)}`)

/**
 *
 */
export const redirectSearch = ({ target, search }) =>
  HistoryHelper.redirect(`search?target=${target}&search=${search}`)

/**
 * Redirects to Products page
 * @param {string} params
 * @returns void
 * @example
 * redirectExploreProducts('supplier=47&title=Supplier\'s Products')
 * @author Rafael Rapizo Nery
 * @created 02-19-2024
 */
export const redirectExploreProducts = (params) =>
  HistoryHelper.redirect(`products?${params}`)
