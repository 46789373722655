import axios from 'axios'


/**
 * Fetches available countries from the marketplace API.
 *
 * @returns {Promise<Array>} - A promise that resolves to an array of available countries.
 */
export const fetchAvailableCountries = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_MAIN_API_URL}/api/v1/marketplace/countries`)

    if (res.status === 200) {
      return res.data.countries
    }
  } catch (error) {
    console.error(error)
  }
}
