import { useCallback, useEffect } from 'react'
import { Skeleton } from 'antd'

import { useExplore } from '../../../hooks/ui/useExplore'

import { Industry } from '../../../models/Industry'

import { Explore } from './Explore'
import { List } from './components/List'

/**
 * React component representing a list of industry categories in the marketplace.
 * Fetches and displays industry categories with icons, providing an exploration experience.
 */
export const Categories = () => {
  const { filtered, fetch, isLoading, setAll, search, redirect, all } = useExplore()

  /**
   * Callback function to handle fetching industry categories and updating state.
   * @param {object} res - Response object from the industry categories fetch request.
   */
  const handleFetchCategories = useCallback(
    (res) => {
      let industryCategories = []

      for (const industry of res.data.industries) {
        industryCategories = [
          ...industryCategories,
          new Industry(industry.name, industry.icon, [...industry.get_sub_industries]),
        ]
      }
      setAll(industryCategories)
    },
    [setAll],
  )

  /**
   * Function to get the IDs of sub-industries based on the selected industry category.
   * @param {string} id - ID of the selected industry category.
   * @param {Industry[]} all - Array of all industry categories.
   * @returns {string[]} - Array of sub-industry IDs.
   */
  const getSubIndustriesId = (id, all) => {
    let targetCategory = all.find((c) => c.id === id)
    return [...targetCategory.subIndustries.map((sI) => sI.id)]
  }

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_MAIN_API_URL}/api/v1/marketplace/industry-categories?available=true`,
      handleFetchCategories,
    )
  }, [fetch, handleFetchCategories])

  return (
    <Explore title="Categories" onSearch={(value) => search(value)}>
      {isLoading ? (
        <>
          <Skeleton />
        </>
      ) : (
        <List
          content={filtered}
          onClickCard={(item) =>
            redirect(
              {
                baseURL: '/products',
                target: 'category',
                divider: ',',
                posfix: `title=${item.name}`,
              },
              getSubIndustriesId(item.id, all),
              item
            )
          }
          iconRounded={true}
        />
      )}
    </Explore>
  )
}
