import React from 'react'
import { Link } from 'react-router-dom'
import { Marker, StaticGoogleMap } from 'react-static-google-map'
import sellerShop from '../../../../pages/marketplace/SellerShop'

const CompanyInfo = ({ company, isSellerShop = false }) => {
  return (
    <div className="sellerShowroom__left">
      <div className="sellerShowroom__card">
        <h3 className="sellerShowroom__title">Categories</h3>
        <div className="sellerShowroom__categories">
          {company.get_industries &&
            company.get_industries.map((industry) => (
              <div key={industry.id} className="sellerShowroom__category">
                <div className="sellerShowroom__categoryIcon">
                  <img src={industry.industry.icon} alt={industry.industry.name} />
                </div>
                <div className="sellerShowroom__categoryName">
                  {industry.industry.name}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="sellerShowroom__card">
        <h3 className="sellerShowroom__title">Company Informations</h3>
        <div className="sellerShowroom__info">
          <div className="sellerShowroom__infoWrapper">
            <div>
              <p className="mb-2">Established in:</p>
              <p className="mb-0">
                <strong>{company.establishment_date}</strong>
              </p>
            </div>
          </div>
          <div className="sellerShowroom__infoWrapper">
            <div>
              <p className="mb-2">Employees:</p>
              <p className="mb-0">
                <strong>{company.employees}</strong>
              </p>
            </div>
          </div>
          <div className="sellerShowroom__infoWrapper">
            <div>
              <p className="mb-2">Ownership:</p>
              <p className="mb-0 text-end">
                <strong>{company.ownership}</strong>
              </p>
            </div>
          </div>
          <div className="sellerShowroom__infoWrapper">
            <div>
              <p className="mb-2">Turnover:</p>
              <p className="mb-0">
                <strong>
                  ${new Intl.NumberFormat('en-US').format(parseFloat(company.turnover))}
                </strong>
              </p>
            </div>
          </div>
          <div className="sellerShowroom__infoWrapper">
            <div>
              <p className="mb-2">Export:</p>
              <p className="mb-0">
                <strong>{company.export}%</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="sellerShowroom__card" style={{ borderBottom: 0 }}>
        <h3 className="sellerShowroom__title">Location</h3>
        <div className="sellerShowroom__location">
          <StaticGoogleMap
            size="300x300"
            zoom={12}
            className="img-fluid"
            apiKey="AIzaSyChUeLx-Y_B9wP7OZ4PItaiqrYNh4wYl6w"
            maptype="roadmap"
            style={'feature:all|saturation:-100'}
          >
            <Marker
              color="0x00968A"
              size="mid"
              location={`${company.address}, ${company.city}, ${company.country}`}
            />
          </StaticGoogleMap>
        </div>
      </div>
      {!isSellerShop && (
        <Link
          to={`/company/${company.slug}`}
          className="sellerShowroom__viewCompanyFacts"
        >
          <div className="d-flex justify-content-center align-items-center">
            <div>VIEW COMPANY FACTS</div>
            <div className="ms-2">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.99984 6.66309L8.58984 8.07309L13.1698 12.6631L8.58984 17.2531L9.99984 18.6631L15.9998 12.6631L9.99984 6.66309Z"
                  fill="#004D40"
                />
              </svg>
            </div>
          </div>
        </Link>
      )}
    </div>
  )
}

export default CompanyInfo
