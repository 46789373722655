import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

export const useFiltersSelection = () => {
  const [activeFilters, setActiveFilters] = useState([])
  const [activeKeys, setActiveKeys] = useState([])
  const location = useLocation()

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const country = query.get('country')
    const priceRange = query.get('priceRange')
    const availability = query.get('availability')
    const direct = query.get('direct')
    const brand = query.get('brand')
    const category = query.get('category')

    const activeFilters = [
      country ? 'country' : '',
      priceRange ? 'priceRange' : '',
      availability ? 'availability' : '',
      direct ? 'direct' : '',
      brand ? 'brand' : '',
      category ? 'category' : '',
    ].filter((key) => key !== '')

    if (activeFilters) {
      setActiveFilters([...activeFilters])

      for (const filter of activeFilters) {
        console.log(query.get(filter).split(','))
        setActiveKeys((prev) => [...prev, ...query.get(filter).split(',')])
      }
    }
  }, [location])

  return {
    activeFilters,
    activeKeys,
    setActiveKeys,
  }
}
