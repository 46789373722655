import { useMemo } from 'react'

import { SEARCH_OPTIONS } from '../../../data/searchOptions'

import { OptionsList } from './OptionsList'
import { TabSystem } from './../TabSystem'

/**
 * @description Overlay for the search bar
 * @param {Array} options - The options to show
 * @param {Boolean} isLoading - If the options are loading
 * @param {Function} onTabChange - Function to handle tab change
 * @param {Function} onOptClick - Function to handle option click
 * @returns {JSX.Element}
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const OptionsOverlay = ({ options = [], isLoading = false, onTabChange = () => null, onOptClick = () => null }) => {
  // Create the tabs object for the overlay
  const OPTIONS_TABS = useMemo(
    () => SEARCH_OPTIONS.map((opt) => ({ title: opt, key: opt.toLowerCase() })),
    [],
  )

  return (
    <div className="search-bar__overlay">
      <TabSystem
        className="search-bar__overlay__tabs"
        tabs={[
          ...OPTIONS_TABS.map((opt) => ({
            ...opt,
            content: (
              <OptionsList data={options} isLoading={isLoading} onItemClick={onOptClick} />
            ),
          })),
        ]}
        defaultTab={OPTIONS_TABS[0].key}
        onChange={onTabChange}
      />
    </div>
  )
}
