import React from 'react'
import { Skeleton } from 'antd'

import ProductPackaging from '../../../../../pages/marketplace/product-details/ProductPackaging'

import { DescriptionCard } from './index'

export const ListDetails = ({ product, expanded }) => {
  if (!product || !expanded) {
    return null
  }

  return (
    <div
      className={`expanded-details ${
        expanded === product.id ? 'expanded-details__visible' : ''
      }`}
    >
      {!product || !product.options ? (
        <Skeleton active />
      ) : (
        <>
          <DescriptionCard product={product} type={'desc'} />
          <DescriptionCard product={product} type={'detail'} />
          <div className="product-details">
            <ProductPackaging packaging={product.get_packaging || []} />
          </div>
        </>
      )}
    </div>
  )
}
