import { Button } from 'antd'

export const PriceUnavailable = ({ className = '', title = 'Price Unavailable', onClick = () => null }) => {
  return (
    <div className={`product-card__prices__price--unavailable ${className}`}>
      <span>{title}</span>
      <Button
        onClick={onClick}
        type="primary"
        className="ant-btn-primary__outlined ant-btn-ttu product-card__prices__price--unavailable__btn"
      >
        Contact Seller
      </Button>
    </div>
  )
}
