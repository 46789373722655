import { orderByProducts, orderAlphabetically } from './'

/**
 * Orders countries based on availability.
 *
 * @param {Array} countries - The array of country objects.
 * @returns {Array} - The ordered array of countries.
 * @author Rafael Rapizo Nery
 */
export const orderByAvailability = (countries = []) => {
  // Initialize arrays to store available and unavailable countries.
  let [ordered, availableArr, unavailableArr] = [[], [], []]

  // Iterate through each country to categorize them based on availability.
  for (let c of countries) {
    c.isAvailable
      ? (availableArr = [...availableArr, c])
      : (unavailableArr = [...unavailableArr, c])
  }

  // Combine the available countries ordered by products and unavailable countries ordered alphabetically.
  ordered = [...orderByProducts(availableArr), ...orderAlphabetically(unavailableArr)]

  return ordered
}
