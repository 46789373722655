// A view controller component for changing the view of the list
// Should have two icons, one for grid and one for view
// the component should accept a callback function to change the view

export const ViewController = ({ onChange, view = 'grid' }) => {
  const changeView = (viewType) => {
    onChange(viewType)
  }

  return (
    <div className="view-controller">
      <div className="view-controller__icon" onClick={() => changeView('grid')}>
        <img
          className={`${view === 'grid' && 'active'}`}
          src={`${process.env.REACT_APP_CDN_URL}/icons/grid.svg`}
          alt="grid"
        />
      </div>
      <div className="view-controller__icon" onClick={() => changeView('list')}>
        <img
          className={`${view === 'list' && 'active'}`}
          src={`${process.env.REACT_APP_CDN_URL}/icons/list.svg`}
          alt="list"
        />
      </div>
    </div>
  )
}
