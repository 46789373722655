/**
 * @description CompanyCard's Image component
 * @param {String} image - Company's image
 * @param {Function} onClick - Function to execute on click
 * @returns {JSX.Element}
 * @example
 * <Image image={image} onClick={onClick} />
 * @author Rafael Rapizo Nery
 * @created 2024-02-06
 */
export const Image = ({ image, onClick = () => null }) => {
  return (
    <div className="company-card__image">
      <img
        src={
          image ??
          `${process.env.REACT_APP_CDN_URL}/web/common/company-logo-placeholder.svg`
        }
        alt={`${image ?? 'Company'}'s logo`}
        onClick={onClick}
      />
    </div>
  )
}
