import { useEffect, useState } from 'react'
import { Pallet } from '../../../../../models/Pallet'

export const PalletCard = ({ code, onClick, selected }) => {
  const [isActive, setIsActive] = useState()
  const palletData = new Pallet(code)

  const handleSetActive = () => {
    setIsActive(true)

    onClick(code)
  }

  useEffect(() => {
    setIsActive(selected)
  }, [selected])

  return (
    <div className={`pallet-card ${isActive && 'pallet-card--active'}`} onClick={handleSetActive}>
      <img src={palletData.img} alt={`${palletData.getTitle()} icon`} />
      <div className="pallet-card__description">
        <p>{palletData.getTitle()}</p>
        <span>{palletData.getFormattedDimensions()}</span>
      </div>
    </div>
  )
}
