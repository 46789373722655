import React, { Suspense, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import MainLayout from './MainLayout'
import AuthLayout from './AuthLayout'
import MarketplaceLayout from './MarketplaceLayout'

import { history } from '../index'
import { Skeleton } from 'antd'

const mapStateToProps = ({ user }) => ({ user })

const Layouts = {
  login: AuthLayout,
  main: MainLayout,
  marketplace: MarketplaceLayout,
}

const IndexLayout = ({ user, children, location: { pathname, search } }) => {
  const [prevPathName, setPrevPathname] = useState(null)

  useEffect(() => {
    if (history.location.pathname !== prevPathName) {
      window.scrollTo(0, 0)
    }
    setPrevPathname(history.location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname])

  // Layout Rendering
  const getLayout = () => {
    if (/^\/secured(?=\/|$)/i.test(pathname)) {
      return 'login'
    }
    return 'marketplace'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.authorized
  const userInfo = user.user
  const isUserLoading = user.loading
  const isAuthLayout = getLayout() === 'login'

  const BootstrappedLayout = () => {
    // Admin login
    const params = new URLSearchParams(search)
    const adminAccessToken = params.get('adminAccessToken')
    if (adminAccessToken && adminAccessToken.length > 10) {
      localStorage.setItem('apiToken', adminAccessToken)
      history.push('/dashboard')
      window.location.reload()
    }
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && getLayout() !== 'marketplace' && !isUserAuthorized) {
      return <Redirect to="/secured/login" />
    }
    return (
      <Container>
        {React.cloneElement(children, {
          isSeller: userInfo.is_seller,
          isSellerVerified: userInfo.is_seller_verified,
        })}
      </Container>
    )
  }

  return (
    <>
      <HelmetProvider>
        <Helmet titleTemplate="%s | TradeToWorld" title="TradeToWorld" />
        <Suspense fallback={<Skeleton />}>{BootstrappedLayout()}</Suspense>
      </HelmetProvider>
    </>
  )
}

export default withRouter(connect(mapStateToProps)(IndexLayout))
