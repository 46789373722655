import { useEffect, useState } from 'react'

import { history } from '../..'

import { Query } from '../../models/Query'

import { fetchAutocomplete } from '../../util/functions/fetch/fetchAutocomplete'
import { message } from 'antd'

/**
 * @description Custom hook for the search bar component to handle the search input and the search target tabs state and logic for the search bar component
 * @returns {Object} An object with the following properties:
 * - target: The current selected search target
 * - search: The current search input value
 * - handleTabChange: A function to handle the search target tab change
 * - handleInputChange: A function to handle the search input change
 * - handleSubmit: A function to handle the search form submit
 * - isLoading: A boolean to indicate if the autocomplete options are being fetched
 * - autocompleteOpts: An array of autocomplete options
 * - isOptsListOpen: A boolean to indicate if the autocomplete options list is open
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const useSearchBar = () => {
  let [target, searchTarget] = useState('all')
  let [search, setSearch] = useState('')
  let [autocompleteOpts, setAutocompleteOpts] = useState([])
  let [isLoading, setIsLoading] = useState(false)
  let [isOptsListOpen, setIsOptsListOpen] = useState(false)

  /**
   * @description Fetches the autocomplete options for the search input
   * @param {String} search The search input value
   * @param {String} target The current selected search target
   * @returns {Promise} A promise that resolves to the autocomplete options array
   * @async
   * @function getAutocompleteOpts
   * @inner
   * @memberof useSearchBar
   * @version 1.0.0
   * @example <caption>Usage</caption>
   * getAutocompleteOpts('test', 'all')
   *  .then((data) => console.log(data))
   * .catch((err) => console.error(err))
   * @see fetchAutocomplete
   * @author Rafael Rapizo Nery
   */
  let getAutocompleteOpts = async (search, target) => {
    setIsLoading(true)
    let data = await fetchAutocomplete({ search, target })
    setAutocompleteOpts(data)
    setIsLoading(false)
  }

  /**
   * @description Handles the search target tab change
   * @param {String} key The new selected search target
   * @function handleTabChange
   * @inner
   * @memberof useSearchBar
   * @author Rafael Rapizo Nery
   * @version 1.0.0
   */
  let handleTabChange = (key) => {
    searchTarget(key)
  }

  /**
   * @description Handles the search input change
   * @param {Event} e The input change event
   * @function handleInputChange
   * @inner
   * @memberof useSearchBar
   * @author Rafael Rapizo Nery
   * @version 1.0.0
   */
  let handleInputChange = (e) => {
    setSearch(e.target.value)
  }

  /**
   * @description Handles the search form submit
   * @param {String} search The search input value
   * @param {String} target The current selected search target
   * @function handleSubmit
   * @inner
   * @memberof useSearchBar
   * @author Rafael Rapizo Nery
   * @version 1.0.0
   * @example <caption>Usage</caption>
   * handleSubmit('test', 'all')
   * @see Query
   */
  let handleSubmit = (search, target) => {
    if (search.length === 0) return message.error('Please enter a search term')

    const TARGET_QUERY = new Query(null, 'target').append(target).buildQuery()
    const SEARCH_QUERY = new Query(null, 'search').append(search).buildQuery()

    history.push(`/search?${TARGET_QUERY}&${SEARCH_QUERY}`)
  }

  useEffect(() => {
    if (search.length > 0) setIsOptsListOpen(true)
    else setIsOptsListOpen(false)

    getAutocompleteOpts(search, target)
  }, [search, target])

  return {
    target,
    search,
    handleTabChange,
    handleInputChange,
    handleSubmit: (term = null) => handleSubmit(term ?? search, target),
    isLoading,
    autocompleteOpts,
    isOptsListOpen,
  }
}
