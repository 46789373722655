/* eslint import/no-cycle:0 */
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { connect } from 'react-redux'

import IndexLayout from './layouts/IndexLayout'

import { AUTH_ROUTES } from './routes/auth'
import marketplaceRoutes from './routes/marketplace'

const routes = [...AUTH_ROUTES, ...marketplaceRoutes]

const mapStateToProps = () => ({
  routerAnimation: 'slide-fadein-up',
})

const Router = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <IndexLayout>
        <Route
          render={(state) => {
            const { location } = state
            return (
              <Switch location={location} key={location.pathname}>
                {/*<Route exact path="/" render={() => <Redirect to="/dashboard" />} />*/}
                {routes.map(({ path, Component, exact }) => (
                  <Route
                    path={path}
                    key={path}
                    exact={exact || true}
                    render={() => {
                      return <Component />
                    }}
                  />
                ))}
                <Redirect to="/" />
              </Switch>
            )
          }}
        />
      </IndexLayout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
