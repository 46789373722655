import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import { Skeleton } from 'antd'
import { StickyContainer } from 'react-sticky'

import HorizontalView from '../../../components/pages/marketplace/product-details/layouts/HorizontalView'
// import VerticalView from '../../../components/pages/marketplace/product-details/layouts/VerticalView'

import axiosRequest from '../../../hooks/useAxiosRequest'

const mapStateToProps = ({ user }) => ({ user })

/**
 * @description ProductDetailsMarketplacePage component is used to display the product details in the marketplace page. It is used to display the product details in the marketplace page.
 * @category Page
 * @namespace Pages - Marketplace - ProductDetails
 * @component ProductDetailsMarketplacePage
 * @param {Object} props
 * @param {Object} props.user - The user to be displayed.
 * @example
 * return (
 * <ProductDetailsMarketplacePage user={user} />
 * )
 * @requires React
 * @requires react-redux
 * @requires react-router-dom
 * @requires antd/Skeleton
 * @requires react-sticky
 * @requires Components - HorizontalView
 * @requires Components - VerticalView
 * @requires Hooks - useAxiosRequest
 * @todo Add the proptypes
 * @todo Add the tests
 * @todo Add the styles
 * @todo Add the storybook
 * @todo Add the documentation
 * @todo Refactor for modularity
 * @todo Refactor for performance
 *
 * @created 02-29-2024
 * @version 0.1.1
 * @since 0.1.0
 *
 * @author Rafael Rapizo Nery
 */
const ProductDetailsMarketplacePage = ({ user }) => {
  const { slug } = useParams()
  const [loading, setLoading] = useState(true)
  const [product, setProduct] = useState(null)
  const [mainImageSizes, setMainImageSizes] = useState(null)

  useEffect(() => {
    axiosRequest(`/api/v1/marketplace/product/${slug}`, null, 'get', true)
      .then((res) => {
        if (res && res.data.success) {
          setProduct(res.data.data)
          if (
            res.data.data &&
            res.data.data.product.get_all_images &&
            res.data.data.product.get_all_images[0]
          ) {
            const img = new Image()
            img.src = res.data.data.product.get_all_images[0].original
            img.addEventListener('load', function () {
              setMainImageSizes({ width: this.naturalWidth, height: this.naturalHeight })
            })
          } else {
            setMainImageSizes({ width: 1, height: 0 })
          }
          setLoading(false)
        }
      })
      .catch(() => {
        alert('Something goes wrong, try again later...')
      })
  }, [slug])

  if (loading || !product || !mainImageSizes) {
    return (
      <div className="container">
        <Skeleton active />
      </div>
    )
  }

  return (
    <StickyContainer>
      <HorizontalView product={product} user={user} />
    </StickyContainer>
  )
}

export default withRouter(connect(mapStateToProps)(ProductDetailsMarketplacePage))
