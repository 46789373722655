import QueryString from 'query-string'

const convertUrlParamsToObj = (query) => {
  const parseQuery = QueryString.parse(query)
  Object.entries(parseQuery).forEach(([key, value]) => {
    if (value.indexOf(',') > -1) {
      parseQuery[key] = value.split(',')
    }
  })
  if (!parseQuery.page) {
    parseQuery.page = 1
  }
  return parseQuery
}

export default convertUrlParamsToObj
