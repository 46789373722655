import { useEffect, useState } from 'react'
import { Button, Checkbox, Collapse, Drawer, Skeleton } from 'antd'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { FilterOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'

import { useFiltersSelection } from '../../../../../hooks/ui/FiltersSelection/useFiltersSelection'

import { CategoriesTree } from '../CategoriesTree'
import { PriceRangeController } from '../Filters/PriceRangeController'

import './FiltersMobile.scss'
import TagList from '../../../../ui/TagList/TagList'
import RemovableTag from '../../../../ui/Tag/RemovableTag/RemovableTag'
import { FilterHelper } from '../../../../../models/helpers/Filter/FilterHelper.class'

const FiltersMobile = ({
  filters = [],
  isLoading = false,
  onCategoryChange = () => null,
  onPriceRangeChange = () => null,
  onSelectchange = () => null,
  onClearFilters = () => null,
  onApplyFilters = () => null,
  isSellerShowroom = false,
}) => {
  const [filterHelper, setFilterHelper] = useState(null)

  // save for category
  const [category, setCategory] = useState([])
  const [isSubCategories, setIsSubCategories] = useState(false)
  const location = useLocation()
  const [priceRange, setPriceRange] = useState({})
  const [checkFilters, setCheckFilters] = useState([])

  const [isOpen, setIsOpen] = useState(false)
  const { activeFilters, activeKeys, setActiveKeys } = useFiltersSelection()

  const selectCategory = (subIndustriesId, categoryName) => {
    setCategory([subIndustriesId, categoryName])
  }
  const saveCategory = () => onCategoryChange(category[0], category[1])

  const selectPriceRange = ({ min, max }) => {
    setPriceRange({ min, max })
  }
  const savePriceRange = () => onPriceRangeChange({ ...priceRange })

  const selectCheckFilters = (query, value) =>
    setCheckFilters([...checkFilters, { query, value }])

  const saveCheckFilters = () => checkFilters.map((filter) => onSelectchange(filter))

  const applyFilters = () => {
    if (isSellerShowroom) return onApplyFilters()
    else {
      if (category.length > 0) saveCategory()
      if (priceRange.min || priceRange.max) savePriceRange()
      if (checkFilters.length > 0) saveCheckFilters()
      setIsOpen(!isOpen)
    }
  }

  const clearFilters = () => {
    setActiveKeys([])
    onClearFilters()
    setIsOpen(!isOpen)
  }

  // FIXME: Refactor this
  const createTags = (filterHelper) => {
    return filterHelper
      ? filterHelper.getFiltersFromActiveKeys().map((tag) => ({
          key: tag.value,
          label: tag.key,
          value: tag.value,
          query: tag.query,
        }))
      : []
  }

  const handleRemoveTag = (tag, isSellerShowroom = false) => {
    setActiveKeys((prev) => prev.filter((key) => key !== `${tag.value}`))
    selectCheckFilters(tag.query, `${tag.value}`)

    return isSellerShowroom
      ? onSelectchange({
          query: tag.query,
          value: `${tag.value}`,
        })
      : null
  }

  // FIXME: Refactor this
  useEffect(() => {
    let params = new URLSearchParams(location.search)
    if (params.get('category')) {
      setIsSubCategories(true)
    }
  }, [location])

  useEffect(() => {
    if (filters) {
      setFilterHelper(new FilterHelper({ activeKeys, activeFilters, filters }))
    }
  }, [filters, activeKeys, activeFilters])

  return (
    <>
      {filters ? (
        <Drawer
          destroyOnClose
          visible={isOpen}
          footer={
            <Button
              type="primary"
              className="ant-btn-primary ant-btn-ttu w-100"
              onClick={applyFilters}
            >
              apply filter
            </Button>
          }
          className="filters-mobile__drawer"
          placement="bottom"
          height={'calc(100vh - 64px)'}
          onClose={() => setIsOpen(!isOpen)}
        >
          {isLoading ? (
            <Skeleton active />
          ) : (
            <>
              <div className="filters-mobile__drawer__header">
                <h3>Filter</h3>
                <span
                  className={`filters-mobile__drawer__header__clear-btn ${
                    activeFilters.length > 0 ||
                    new URLSearchParams(location.search).get('category')
                      ? 'filters-mobile__drawer__header__clear-btn--active'
                      : ''
                  }`}
                  onClick={clearFilters}
                >
                  Clear all
                </span>{' '}
                <TagList
                  className="filters-mobile__drawer__header__active-filters"
                  tagClassName="filters-mobile__drawer__header__active-filters__tag"
                  tags={createTags(filterHelper)}
                  renderTag={(tag) => (
                    <RemovableTag
                      tag={tag}
                      onRemove={() => handleRemoveTag(tag, isSellerShowroom)}
                    />
                  )}
                />
              </div>
              <div className="filters-mobile__drawer__body">
                <Collapse
                  defaultActiveKey={activeFilters}
                  expandIconPosition="right"
                  expandIcon={(collapseProps) => {
                    if (collapseProps.isActive) {
                      return <CaretUpOutlined />
                    }
                    return <CaretDownOutlined />
                  }}
                >
                  {filters && filters.categories && (
                    <Collapse.Panel
                      key="categories"
                      header={`Shop by ${isSubCategories ? 'Sub-category' : 'Category'}`}
                    >
                      <CategoriesTree
                        isMenu
                        onChange={selectCategory}
                        categoriesData={filters.categories ?? []}
                      />
                    </Collapse.Panel>
                  )}
                  {filters && filters.minMaxPrice && (
                    <Collapse.Panel key="minMaxPrice" header="Price">
                      <PriceRangeController
                        defaultMin={
                          filters && filters.minMaxPrice
                            ? filters.minMaxPrice.minPrice
                            : 0
                        }
                        defaultMax={
                          filters && filters.minMaxPrice
                            ? filters.minMaxPrice.maxPrice
                            : 0
                        }
                        callback={selectPriceRange}
                      />
                    </Collapse.Panel>
                  )}
                  {filters.select &&
                    filters.select.map((filter) => {
                      return (
                        <Collapse.Panel
                          id={filter.query}
                          key={filter.query}
                          header={filter.title}
                        >
                          {filter.options &&
                            filter.options.map((option, index) => {
                              return (
                                <Checkbox.Group
                                  defaultValue={activeKeys}
                                  className="d-flex flex-column"
                                  key={index}
                                  options={option.options}
                                >
                                  <Checkbox
                                    className="mb-2"
                                    checked={activeKeys
                                      .map((value) => Number.parseInt(value))
                                      .includes(`${option.value}`)}
                                    name={filter.key}
                                    value={`${option.value}`}
                                    onChange={(e) => {
                                      activeKeys.includes(`${option.value}`)
                                        ? setActiveKeys((prev) =>
                                            prev.filter(
                                              (key) => key !== `${option.value}`,
                                            ),
                                          )
                                        : setActiveKeys((prev) => [
                                            ...prev,
                                            `${option.value}`,
                                          ])

                                      selectCheckFilters(filter.query, `${option.value}`)

                                      return isSellerShowroom
                                        ? onSelectchange({
                                            query: filter.query,
                                            value: `${option.value}`,
                                          })
                                        : null
                                    }}
                                  >
                                    {option.icon && (
                                      <img
                                        className="me-1"
                                        src={option.icon}
                                        alt={`${option.icon} icon`}
                                      />
                                    )}
                                    {option.key}
                                  </Checkbox>
                                </Checkbox.Group>
                              )
                            })}
                        </Collapse.Panel>
                      )
                    })}
                </Collapse>
              </div>
            </>
          )}
        </Drawer>
      ) : null}

      <div className="filters-mobile">
        <div className="filters-mobile__button" onClick={() => setIsOpen(!isOpen)}>
          <FilterOutlined color="#26A69B" /> <span>Filter</span>
        </div>
      </div>
    </>
  )
}

export default FiltersMobile
