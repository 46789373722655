export class FilterHelper {
  activeKeys = []
  activeFilters = []
  filters = null

  constructor({ activeKeys, activeFilters, filters }) {
    this.activeKeys = activeKeys
    this.activeFilters = activeFilters
    this.filters = filters
  }

  getFiltersFromActiveKeys = () => {
    if (!this.filters) return []
    return this.activeFilters.reduce(this.reduceFilters, [])
  }

  reduceFilters = (filtersFound, query) => {
    let filter = this.getFilterForQuery(query)
    return filter.length > 0 ? [...filtersFound, ...filter] : filtersFound
  }

  getFilterForQuery = (query) => {
    let filter = this.selectFilter(query)
    return filter ? this.mapFilterToQuery(filter, query) : []
  }

  selectFilter = (query) => {
    let filter = this.filters.select.find((f) => f.query === query)

    return filter ? this.filterOptions(filter, this.activeKeys) : []
  }

  filterOptions = (filter, keys = []) =>
    filter.options.filter((option) => keys.includes(`${option.value}`))

  mapFilterToQuery = (filter, query) => filter.map((f) => ({ ...f, query }))
}
