import { Button } from 'antd'

import { redirectProductDetails } from '../../../../../util/functions'

/**
 * @description CompanyCard's ProductsPreview component
 * @param {Array} data - Array of products
 * @param {Function} onViewProducts - Function to view products
 * @returns {JSX.Element}
 * @example
 * <ProductsPreview data={products} onViewProducts={onViewProducts} />
 * @see https://ant.design/components/dropdown/#components-dropdown-demo-hover
 * @see https://ant.design/components/dropdown/#API
 * @author Rafael Rapizo Nery
 * @created 2024-02-06
 */
export const ProductsPreview = ({ data, onViewProducts = () => null }) => {
  return (
    <div className="company-card__footer__products-preview">
      <div className="company-card__footer__products-preview__list">
        {data.map((product, index) => (
          <div
            key={index}
            className="company-card__footer__products-preview__list__item"
            onClick={() => redirectProductDetails(product.slug)}
          >
            <img src={product.get_main_image.preview} alt={product.name} />
          </div>
        ))}
        {data.length === 3 && (
          <div className="company-card__footer__products-preview__list__item company-card__footer__products-preview__list__item--etc">
            <span>...</span>
          </div>
        )}
      </div>
      <Button
        onClick={onViewProducts}
        type="primary"
        className="company-card__footer__products-preview__btn ant-btn-primary ant-btn-ttu"
      >
        view products
      </Button>
    </div>
  )
}
