import Card from '../Card'
import {
  MainImage,
  Data,
  PriceWrapper,
  Tags,
  PreviewData,
  ListDetails,
  RateWrapper,
} from './components'

/**
 * @description ProductCard component.
 * @namespace Card - ProductCard
 * @component
 *
 * @param {Object} props - Component props.
 * @param {string} props.className - Additional CSS classes.
 * @param {string} props.view - View mode ('list' or 'grid').
 * @param {Object} props.product - Product data.
 * @param {boolean} props.isSellerShowroom - Flag indicating if the card is displayed in the seller's showroom.
 * @param {boolean} props.isShowAttributes - Flag indicating if product attributes should be displayed.
 * @param {boolean} props.isShowDescription - Flag indicating if product description should be displayed.
 * @param {boolean} props.shopOptions - Flag indicating if shop options should be displayed.
 * @param {boolean} props.disableProductLinks - Flag indicating if product links should be disabled.
 * @param {boolean} props.expanded - Flag indicating if the card is expanded.
 * @param {Function} [props.setExpanded] - Function to set the expanded state of the card.
 * @param {Function} [props.onClick] - Function to handle click events on the card.
 * @param {Function} [props.handleChangeVariant] - Function to handle variant changes.
 * @return {JSX.Element} - Rendered component.
 *
 * @example
 * <ProductCard
 * className="product-card--ss"
 * view="list"
 * product={product}
 * isSellerShowroom
 * isShowAttributes
 * isShowDescription
 * shopOptions
 * disableProductLinks
 * expanded
 * setExpanded={() => null}
 * onClick={() => null}
 * handleChangeVariant={() => null}
 * />
 *
 * @version 1.0.0
 * @since 1.0.0
 * @created 02-19-2024
 *
 * @author Rafael Rapizo Nery
 *
 * @todo Add the prop types
 * @todo Add the tests
 * @todo Add the styles
 * @todo Add the storybook
 * @todo Add the localization
 *
 */
export const ProductCard = ({
  className,
  view,
  product,
  isSellerShowroom,
  isShowAttributes,
  isShowDescription,
  shopOptions,
  disableProductLinks,
  expanded,
  setExpanded = () => null,
  onClick = () => null,
  handleChangeVariant = () => null,
}) => {
  return (
    <Card
      className={`product-card product-card--${view === 'list' && 'list'} ${className}`}
      onClick={onClick}
    >
      <MainImage
        shopOptions={shopOptions}
        disableProductLinks={disableProductLinks}
        expanded={expanded}
        setExpanded={setExpanded}
        product={product}
        isSellerShowroom={isSellerShowroom}
        view={view}
      />
      <Data
        isSellerShowroom={isSellerShowroom}
        disableProductLinks={disableProductLinks}
        expanded={expanded}
        product={product}
        setExpanded={setExpanded}
        shopOptions={shopOptions}
        layout={view}
        handleChangeAttribute={handleChangeVariant}
      />
      {/* TODO: Dynamic rating */}
      <div className="product-card__footer">
        <RateWrapper rating={0} reviewCount={0} />
        <div className="product-card__footer__ttw-code">
          <span>{product.product_data ? product.product_data.ttw_code : null}</span>
        </div>
      </div>

      <RateWrapper isSellerShowroom={isSellerShowroom} />
      <PriceWrapper
        expanded={expanded}
        isSellerShowroom={isSellerShowroom}
        product={product}
        setExpanded={setExpanded}
        shopOptions={shopOptions}
        layout={view}
      />
      {view === 'grid' && <Tags product={product} layout={view} />}
      {view === 'list' && (
        <>
          <PreviewData
            expanded={expanded}
            isShowAttributes={isShowAttributes}
            isShowDescription={isShowDescription}
            product={product}
            attClassName={'product-card__attributes'}
            descClassName={'product-card__description'}
          />
          <ListDetails
            product={product}
            expanded={expanded}
            handleChangeProductVariant={handleChangeVariant}
          />
        </>
      )}
    </Card>
  )
}
