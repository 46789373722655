import axios from 'axios'

/**
 * @class Mailer
 * @description
 * Mailer class is used to send emails
 * @method getType
 * @method setType
 * @method getData
 * @method setData
 * @method sendMail
 * @param {string} type
 * @returns {Mailer} The Mailer class.
 * @example
 * const mailer = new Mailer('contact-seller')
 *
 * @since 0.1.1
 * @version 0.1.2
 * @author Rafael Rapizo Nery
 */
export class Mailer {
  #type
  #data

  constructor(type) {
    this.#type = type
  }

  getType() {
    return this.#type
  }
  setType(type) {
    this.#type = type
    return this
  }

  getData() {
    return this.#data
  }
  setData(data) {
    this.#data = data
    return this
  }

  /**
   * @description Send an email.
   * @method sendMail
   * @returns {Promise} The response of the mailer.
   */
  async sendMail() {
    // ${process.env.REACT_APP_INVIO_URL}
    try {
      let res = await axios.post(`${process.env.REACT_APP_INVIO_URL}/mailer/send`, {
        ...this.#data,
        type: this.#type,
      })

      return res
    } catch (error) {
      console.error(error)
    }
  }
}
