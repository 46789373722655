import axios from 'axios'


import convertObjToUrlParams from '../../../helpers/convert-obj-to-url-params'

var CANCEL_TOKEN

export const query = async (endpoint, params, callback = () => null, baseURL) => {
  const queryStr = convertObjToUrlParams(params)

  if (CANCEL_TOKEN) {
    CANCEL_TOKEN.cancel('Request cancelled')
  }
  CANCEL_TOKEN = axios.CancelToken.source()

  // use axios to fetch
  try {
    let res = await axios.get(`${baseURL ?? process.env.REACT_APP_MAIN_API_URL}/${endpoint}${queryStr}`, {
      cancelToken: CANCEL_TOKEN.token,
    })
    // if success
    if (res.status === 200) {
      const data = [...res.data.data.data]
      const pagination = { ...res.data.data }
      callback(data)
      return { data, pagination }
    } else {
      // error handling
      if (axios.isCancel(res)) {
        console.log('Request cancelled', res)
      } else {
        throw new Error(`Error fetching ${endpoint}`)
      }
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      console.error(error)
    }
  }
}
