import { Dropdown, Input } from 'antd'

import { useSearchBar } from '../../../hooks/ui/useSearchBar'

import { EnterBtn } from './EnterBtn'
import { OptionsOverlay } from './OptionsOverlay'

/**
 * @description Search bar component
 * @returns {JSX.Element}
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const SearchBar = () => {
  let {
    handleTabChange,
    handleInputChange,
    handleSubmit,
    autocompleteOpts,
    isLoading,
    isOptsListOpen,
  } = useSearchBar()

  return (
    <Dropdown
      visible={isOptsListOpen}
      overlay={
        <OptionsOverlay
          options={autocompleteOpts}
          isLoading={isLoading}
          onTabChange={handleTabChange}
          onOptClick={handleSubmit}
        />
      }
    >
      <div className="search-bar">
        <Input
          onPressEnter={() => handleSubmit()}
          placeholder="Search"
          className="search-bar__input"
          onChange={handleInputChange}
          suffix={<EnterBtn onClick={() => handleSubmit()} />}
        />
      </div>
    </Dropdown>
  )
}
