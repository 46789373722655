export class Link {
  #title
  #url
  #isLocal

  setTitle(title = '') {
    this.#title = title
    return this
  }

  setURL(url = '') {
    this.#url = url
    return this
  }

  setIsLocal(isLocal = true) {
    this.#isLocal = isLocal
    return this
  }

  getTitle() {
    return this.#title
  }
  getURL() {
    return this.#url
  }
  getIsLocal() {
    return this.#isLocal
  }
}
