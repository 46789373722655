import { Modal } from 'antd'

import { ModalCustomsFeesBody } from './'
import { CustomFee } from '../../../../models/entities/CustomFee/CustomFee.class'

export const ModalCustomsFees = ({
  isShow = false,
  setIsShow,
  supplier = null,
  slug = null,
}) => {
  const handleSubmit = async (values = null, sellerId, buyerId) => {
    const { exportMarket, importMarket } = values

    const customFee = new CustomFee(exportMarket, importMarket, 2369)
    customFee.setSellerCompanyId(sellerId)
    customFee.setBuyerCompanyId(buyerId)

    setIsShow()
    await customFee.solicitEvaluation()
  }

  if (!isShow) return null

  return (
    <Modal
      className="modal-customs-fees"
      bodyStyle={{ paddingBottom: 0 }}
      visible={isShow}
      onCancel={setIsShow}
      children={
        <ModalCustomsFeesBody slug={slug} supplier={supplier} onSubmit={handleSubmit} />
      }
      footer={null}
    />
  )
}
