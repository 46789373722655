export const Prices = ({ product }) => {
  // console.log(product.get_prices)
  // if (product.get_prices.length === 1) {
  // Check
  if (product.get_prices.length > 0) {
    if (product.get_prices && product.get_prices[0]) {
      if (parseFloat(product.get_prices[0].price) === 0.0) {
        return null
      }
    }
    let lowestIndex = product.get_prices[0]
    const lowestPrice = 999999999999999999
    // Find the lowest price
    product.get_prices[0].get_items &&
      product.get_prices[0].get_items.forEach((price, index) => {
        if (price.option === 'byQty' && parseFloat(price.price) < lowestPrice) {
          lowestIndex = product.get_prices[0].get_items[index]
        }
      })
    return (
      <>
        <div className="d-flex justify-content-between align-items-center">
          <div className="prices">
            <span>{`${
              lowestIndex.discount &&
              lowestIndex.discount !== 0 &&
              lowestIndex.discount !== 0.0 &&
              lowestIndex.discount !== '0.00'
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    parseFloat(lowestIndex.price * ((100 - lowestIndex.discount) / 100)),
                  )
                : new Intl.NumberFormat('en-US').format(parseFloat(lowestIndex.price))
            } ${lowestIndex.currency_string || 'USD'}`}</span>
          </div>
          {lowestIndex.discount &&
          lowestIndex.discount !== 0 &&
          lowestIndex.discount !== 0.0 &&
          lowestIndex.discount !== '0.00' ? (
            <div className="discount">
              Save {parseFloat(lowestIndex.discount).toFixed(0)}%
            </div>
          ) : null}
        </div>
        <p className="min-order">Minimum order 20 Unit</p>
      </>
    )
  }
  let min = 99999999999999999999999999
  let max = 0
  product.get_prices.forEach((price) => {
    if (parseFloat(price.price) < min) {
      min = parseFloat(price.price)
    }
  })
  max = min
  product.get_prices.forEach((price) => {
    if (parseFloat(price.price) > max) {
      max = parseFloat(price.price)
    }
  })
  return (
    <div className="prices">
      {/* <span>{`FF $${min} - EXW $${max}`}</span> */}
      <span>{`${product.price} ${product.currency}`}</span>
    </div>
  )
}
