import { all, takeEvery, put, select } from 'redux-saga/effects'

import { actions } from './actions'
import { Order } from '../../models/Order'

const getOrders = (state) => state.cart.orders

export function* ADD({ payload }) {
  const { product, quantity, selectedPrice } = payload
  const orders = yield select(getOrders)

  const newOrder = new Order(product, quantity, selectedPrice)

  yield put({ type: 'cart/SET_STATE', payload: { orders: [...orders, newOrder] } })
}

export function* REMOVE({ payload }) {
  const { productId } = payload

  const orders = yield select(getOrders)

  const updatedOrders = orders.filter((o) => o.product.id !== productId)
  console.log(updatedOrders)

  yield put({ type: 'cart/SET_STATE', payload: { orders: [...updatedOrders] } })
}

export function* SET_QTY({ payload }) {
  const { productId, qty } = payload

  const orders = yield select(getOrders)

  for (const o of orders) {
    if (o.product.id === productId) {
      o.setQuantity(qty)
      console.log(o)
    } else {
      continue
    }
  }

  yield put({ type: 'cart/SET_STATE', payload: { orders: [...orders] } })
}

export function* CLEAR() {
  yield put({ type: 'cart/SET_STATE', payload: { orders: [] } })
}

export default function* rooSaga() {
  yield all([
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.REMOVE, REMOVE),
    takeEvery(actions.SET_QTY, SET_QTY),
    takeEvery(actions.CLEAR, CLEAR),
  ])
}
