import { Skeleton } from 'antd'
import Modal from 'antd/lib/modal/Modal'

import { ProductCardReadOnly } from '../../cards/ProductCard/ProductCardReadOnly'
import { FaChevronLeft } from 'react-icons/fa'

/**
 * @description A modal to display the product details
 * @namespace Modal - ProductDetailsModal
 * @param {Object} props
 * @param {boolean} props.isModalVisible - Whether the modal is visible
 * @param {function} props.onClose - The function to close the modal
 * @param {Object} props.product - The product to display
 * @param {boolean} props.isLoading - Whether the product is loading
 * @param {function} props.onVariantChange - The function to handle the variant change
 * @param {function} props.onCardClick - The function to handle the card click
 *
 * @example
 * <ProductDetailsModal
 *  isModalVisible={true}
 * onClose={() => null}
 * product={product}
 * isLoading={false}
 * onVariantChange={() => null}
 * onCardClick={() => null}
 * />
 *
 * @version 1.0.0
 * @since 1.0.0
 * @author Rafael Rapizo Nery
 */
export const ProductDetailsModal = ({
  isModalVisible = true,
  onClose,
  product,
  isLoading,
  onVariantChange = () => null,
  onCardClick = () => null,
}) => {
  return (
    <>
      <Modal
        className="product-card--ss__modal"
        title="Product Details"
        visible={isModalVisible}
        onCancel={onClose}
        footer={null}
      >
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <div className="product-card--ss__modal__close d-none" onClick={onClose}>
              <FaChevronLeft />
              <span>back to products</span>
            </div>
            <ProductCardReadOnly
              product={product}
              onClick={onCardClick}
              onVariantChange={onVariantChange}
            />
          </>
        )}
      </Modal>
    </>
  )
}
