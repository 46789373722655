import { SyncLoader } from 'react-spinners'
import { limit } from '../../../util/functions/limit'

/**
 * @description List of options for the search bar
 * @param {Array} data - The options to show
 * @param {Boolean} isLoading - If the options are loading
 * @param {Function} onItemClick - Function to handle option click
 * @returns {JSX.Element}
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const OptionsList = ({
  data = [],
  isLoading = true,
  onItemClick = () => null,
}) => {
  // Get the max length of the string
  let getMaxLength = (arr) => {
    let screenW = window.innerWidth
    if (screenW < 576) {
      return 39
    } else {
      return 66
    }
  }

  return (
    <>
      {isLoading ? (
        <SyncLoader className="search-bar__overlay__tabs__loader" />
      ) : (
        <ul className="search-bar__overlay__tabs__opt-list">
          {data.length > 0 ? (
            data.map((item) => (
              <li
                className="search-bar__overlay__tabs__opt-list__item"
                onClick={() => onItemClick(item)}
                key={item}
              >
                {item.length > getMaxLength()
                  ? `${limit(item, getMaxLength())}...`
                  : item}
              </li>
            ))
          ) : (
            <li className="search-bar__overlay__tabs__opt-list__item">
              No results found
            </li>
          )}
        </ul>
      )}
    </>
  )
}
