import { useState, useEffect } from 'react'

import { fetchFilters } from '../../util/functions/fetch/fetchFilters'

export const useFilters = () => {
  const [filters, setFilters] = useState([])
  const [loading, setLoading] = useState(true)

  const handleFetchFilters = async () => {
    setLoading(true)
    const data = await fetchFilters()
    setFilters(data)
    setLoading(false)
  }

  useEffect(() => {
    handleFetchFilters()
  }, [])

  return { filters, loading }
}
