import { Button } from 'antd'

export const EnterBtn = ({ text = 'Search', icon = null, onClick = () => null }) => {
  return (
    <Button type="primary" className="search-bar__input__enter-button" onClick={onClick}>
      {icon || (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.0461 17.9029C13.859 18.6002 12.4762 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19 13.0114 18.2577 14.8495 17.032 16.2552L19.8795 19.6174C20.34 20.1611 20.2725 20.9752 19.7288 21.4357C19.185 21.8962 18.3709 21.8288 17.9104 21.285L15.0461 17.9029ZM17 11C17 14.3137 14.3137 17 11 17C7.68629 17 5 14.3137 5 11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11Z"
            fill="white"
          />
        </svg>
      )}
      <span className="search-bar__input__enter-button__text">{text}</span>
    </Button>
  )
}
