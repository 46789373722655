import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'

import { actions } from '../../redux/cart/actions'

import { LogisticQuote } from '../../models/LogisticQuote'

import {
  CartEmpty,
  Summary,
  Products,
  LogisticQuoteForm,
} from '../../components/pages/marketplace/cart'

export const Cart = () => {
  const dispatch = useDispatch()
  const { orders } = useSelector((state) => state.cart)
  const { user } = useSelector((state) => state.user)
  const [isShowQt, setIsShowQt] = useState(false)
  const [selectedOrders, setSelectedOrders] = useState([])
  const [crrCompanySlug, setCrrCompanySlug] = useState(null)
  const [crrQt, setCrrQt] = useState(null)

  const getCompanies = (cart) => {
    const allOrders = [...cart]
    const companies = []

    for (const o of allOrders) {
      if (companies.find((c) => c.name === o.company.name)) {
        continue
      } else {
        companies.push(o.company)
      }
    }

    return companies
  }

  const parseOrderPerCompanies = (company, orders) => {
    const parsedOrders = []

    for (const o of orders) {
      if (o.company.name === company.name) {
        parsedOrders.push(o)
      }
    }

    return parsedOrders
  }

  const handleShowQt = (slug = null) => {
    const filteredOrders = selectedOrders.filter((o) => o.company.slug === slug)

    if (selectedOrders.length < 1 && !isShowQt) {
      return message.error({ content: "You haven't selected any product." })
    }
    if (slug) {
      setCrrCompanySlug(slug)
      setCrrQt(new LogisticQuote(user.id, filteredOrders))
    } else {
      setCrrCompanySlug(null)
      setCrrQt(null)
    }

    setIsShowQt(!isShowQt)
  }

  const handleSelectAll = () => {
    const pIds = []

    for (const o of orders) {
      pIds.push(o)
    }

    setSelectedOrders([...pIds])
  }

  const handleRemoveAll = () => {
    setSelectedOrders([])
  }

  const handleSelectAllCompany = (companyId) => {
    const ordersCompany = orders.filter((o) => o.company.id === companyId)

    const filteredOrders = selectedOrders.filter((o) => o.company.id !== companyId)

    setSelectedOrders([...filteredOrders, ...ordersCompany])
  }

  const handleRemoveAllCompany = (companyId) => {
    const filteredOrders = selectedOrders.filter((o) => o.company.id !== companyId)

    setSelectedOrders([...filteredOrders])
  }

  const handleSelect = (productId) => {
    if (selectedOrders.find((o) => o.product.id === productId)) {
      setSelectedOrders((prev) => prev.filter((o) => o.product.id !== productId))
    } else {
      setSelectedOrders((prev) => [
        ...prev,
        orders.find((o) => o.product.id === productId),
      ])
    }
  }

  const handleDeleteSelected = () => {
    for (const o of orders) {
      console.log(o)
      for (const s of selectedOrders) {
        console.log(s)
        if (o.product.id === s.product.id) {
          dispatch({
            type: actions.REMOVE,
            payload: {
              productId: o.product.id,
            },
          })
          setSelectedOrders((prev) => [
            ...prev.filter((i) => i.product.id !== o.product.id),
          ])
        }
      }
    }
  }

  const isSelectedAll = () => {
    if (orders.length === selectedOrders.length) {
      return true
    } else {
      return false
    }
  }

  const isSelected = (pId) => {
    if (selectedOrders.find((o) => o.product.id === pId)) {
      return true
    } else {
      return false
    }
  }

  const isSelectedAllCompany = (companyId) => {
    const ordersFromCompany = orders.filter((o) => o.company.id === companyId)
    let selectedFromCompany = []

    if (selectedOrders.length >= 1) {
      selectedFromCompany = [...selectedOrders.filter((o) => o.company.id === companyId)]
    }

    if (selectedFromCompany.length === ordersFromCompany.length) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    console.log(selectedOrders)
  }, [selectedOrders])

  return (
    <div className="container cart">
      <div className="row">
        {orders.length >= 1 ? (
          <>
            {!isShowQt ? (
              <Products
                orders={orders}
                selectedOrders={selectedOrders}
                onSelect={handleSelect}
                onSelectAll={handleSelectAll}
                isSelectedAll={isSelectedAll}
                onRemoveAll={handleRemoveAll}
                isSelected={isSelected}
                isSelectedAllCompany={isSelectedAllCompany}
                onSelectAllCompany={handleSelectAllCompany}
                onRemoveAllCompany={handleRemoveAllCompany}
                companies={getCompanies(orders)}
                parseOrderPerCompanies={parseOrderPerCompanies}
                onDeleteSelected={handleDeleteSelected}
                showLogisticQuote={handleShowQt}
              />
            ) : (
              <LogisticQuoteForm
                showLogisticQuote={handleShowQt}
                companySlug={crrCompanySlug}
                quote={crrQt}
              />
            )}
            <Summary
              isLogisticQt={isShowQt}
              selectedOrders={selectedOrders}
              companies={getCompanies(orders)}
              parseOrderPerCompanies={parseOrderPerCompanies}
              crrQt={crrQt}
            />
          </>
        ) : (
          <CartEmpty />
        )}
      </div>
    </div>
  )
}
