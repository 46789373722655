import React, { useState } from 'react'

export const RoleSelectionCard = ({
  img,
  title,
  description,
  onChange,
  role,
  isChecked,
  readOnly = false,
}) => {
  const [isRoleSelected, setIsRoleSelected] = useState(isChecked || false)

  const changeRole = () => {
    setIsRoleSelected(!isRoleSelected)
    onChange(role)
  }

  return (
    <div onClick={changeRole} className="role-selection-card">
      {!readOnly && (
        <input type="checkbox" name={title} id={title} checked={isRoleSelected} />
      )}
      <img src={img} alt="Buyer Role Selection" />
      <div className="role-selection-card__text">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  )
}
