import React, { Suspense } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import MainSidebar from './partials/MainSidebar'
import MainFooter from './partials/MainFooter'
import MainLayoutHeader from './partials/MainLayoutHeader'
import { Skeleton } from 'antd'

const mapStateToProps = ({ user }) => ({ user })

const MainLayout = ({ children, user }) => {
  return (
    <div className="ttw-layout">
      <MainSidebar user={user} />
      <div className="layout__content">
        <MainLayoutHeader />
        <main className="ttw-main">
          <Suspense fallback={<Skeleton />}>{children}</Suspense>
        </main>
        <MainFooter />
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
