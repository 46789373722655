import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import {
  Skeleton,
  Modal,
  Input,
  Form,
  Button,
  notification,
  message,
  Pagination,
} from 'antd'

import axiosRequest from '../../hooks/useAxiosRequest'

import Header from '../../components/pages/marketplace/explore/Header'
import ProductsList from '../../components/pages/marketplace/explore/ProductsList/ProductsList'
import CompanyInfo from '../../components/pages/marketplace/seller-showroom/CompanyInfo'

import placeholder from '../../assets/placeholder/company logo placeholder.png'

const mapStateToProps = ({ user }) => ({ user })

const SellerShop = ({ user }) => {
  const userData = user.user

  const { slug } = useParams()
  const [bannerUploadModal, setBannerUploadModal] = useState({ visible: false, data: {} })
  const [data, setData] = useState(null)
  const [pageTitle, setPageTitle] = useState('Seller Showroom')
  const [page, setPage] = useState(1)
  const [view, setView] = useState('list')
  const [pageBannerPath, setBannerPath] = useState(null)

  useEffect(() => {
    axiosRequest(`/api/v1/marketplace/showroom/${slug}?page=${page}`, null, 'get', true)
      .then((res) => {
        if (res.data) {
          const { company } = res.data.data
          setPageTitle(company.name)
          console.log(res.data.data)
          setData(res.data.data)
          if (
            res.data.data &&
            res.data.data.company &&
            res.data.data.company.bannerImage
          ) {
            setBannerPath(res.data.data.company.bannerImage)
          }
        }
      })
      .catch(() => {
        notification.error({
          message: 'Something goes wrong, please try again later',
          description: 'Server error',
        })
      })
  }, [slug, page])

  const handleBannerUpload = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      message.loading({ content: 'Please, wait...', key: 'save' })
      const formData = new FormData()
      formData.append('image', file)
      axiosRequest('/api/v1/images/upload?noImageProcess=1', formData, 'post', true).then(
        (res) => {
          if (res.data.success && res.data.image) {
            setBannerPath(res.data.image)
            // window.location.reload()
          }
        },
      )
      e.target.value = ''
    } else {
      message.error('Incorrect image')
    }
  }

  const onBannerDataUpdate = (values) => {
    const formData = values
    formData.path = null
    message.loading({ content: 'Please, wait...', key: 'save' })
    if (pageBannerPath && pageBannerPath !== null) {
      formData.path = pageBannerPath
    }
    if (!data.company.id) {
      message.error({ content: 'Company ID not found', key: 'save' })
      return
    }
    formData.supplierID = data.company.id
    axiosRequest('/api/v1/supplier/banner-upload', formData, 'post', true)
      .then((res2) => {
        message.success('Done')
        window.location.reload()
      })
      .catch(() => {
        message.error('Something goes wrong, try again later...')
      })
      .finally(() => {
        message.success({ content: 'Done', key: 'save' })
      })
  }

  return (
    <div className="sellerShowroom">
      <Helmet title={pageTitle} />
      {!data ? (
        <div className="container">
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      ) : (
        <div>
          <div
            className="sellerShowroom__head sellerShowroom__head-full"
            style={
              data.company.bannerImage
                ? { backgroundImage: `url(${data.company.bannerImage})` }
                : null
            }
          >
            {userData &&
            userData.seller_company &&
            userData.seller_company === data.company.id ? (
              <div
                className="sellerShowroom__head-uploadWrapper"
                onClick={() =>
                  setBannerUploadModal({ visible: true, data: data.company })
                }
              >
                <div className="upload">
                  {/*<input*/}
                  {/*  type="file"*/}
                  {/*  accept="image/png,image/jpeg,image/jpg"*/}
                  {/*  onChange={(e) => handleBannerUpload(e)}*/}
                  {/*/>*/}
                  <div className="uploadIcon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="container">
              <h1>
                {data.company.bannerTitle ? data.company.bannerTitle : data.company.name}
              </h1>
              <h4>
                {data.company.bannerSubTitle
                  ? data.company.bannerSubTitle
                  : 'Seller Showroom'}
              </h4>
            </div>
          </div>
          <div className="sellerShowroom__bg-wrapper sellerShowroom__seller-showroom">
            <div className="container">
              <div className="products-list products-list__white sellerShowroom__products sellerShowroom__body">
                <div className="companyInfo">
                  <div className="sellerShowroom__title">
                    <div className="logo">
                      <img src={data.company.logo} alt={data.company.name} />
                    </div>
                    <div className="name">{data.company.name}</div>
                    {data.company && data.company.description ? (
                      <p className="company-description">{data.company.description}</p>
                    ) : null}
                  </div>
                  <CompanyInfo company={data.company} isSellerShop />
                </div>
                <div className="products mt-4">
                  <Header
                    title="Explore products"
                    /* view={view}
                    setView={setView} */
                    hideNavToggle
                    hideTitle
                  />
                  <ProductsList
                    products={data.products.data}
                    view={view}
                    setView={setView}
                    shopOptions
                    gridClassName="col-12 col-md-6 col-lg-3"
                  />
                  <div className="d-flex justify-content-center mt-4">
                    {data &&
                    data.products &&
                    data.products.per_page < data.products.total ? (
                      <Pagination
                        size="small"
                        current={data.products.current_page}
                        pageSize={data.products.per_page}
                        total={data.products.total}
                        showSizeChanger={false}
                        onChange={(page) => {
                          console.log(page)
                          setPage(page)
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            visible={bannerUploadModal.visible}
            title="Seller Showroom Banner"
            onCancel={() => setBannerUploadModal({ visible: false, data: {} })}
            destroyOnClose
            footer={false}
          >
            <Form
              layout="vertical"
              initialValues={bannerUploadModal.data}
              requiredMark={false}
              onFinish={onBannerDataUpdate}
            >
              <Form.Item name="bannerTitle" label="Banner Title">
                <Input placeholder="Input banner title" />
              </Form.Item>
              <Form.Item name="bannerSubTitle" label="Banner Sub-Title">
                <Input placeholder="Input banner sub-title" />
              </Form.Item>
              {pageBannerPath && (
                <Form.Item label="Page Banner">
                  <img
                    src={pageBannerPath}
                    style={{ maxWidth: '100%', height: 'auto' }}
                    alt=""
                  />
                </Form.Item>
              )}
              <Form.Item label="Upload new page banner">
                <input
                  type="file"
                  accept="image/png,image/jpeg,image/jpg"
                  onChange={(e) => handleBannerUpload(e)}
                />
              </Form.Item>
              <Form.Item className="mb-0">
                <Button
                  className="ant-btn ant-btn-link ant-btn-sm ant-btn-primary px-4"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      )}
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(SellerShop))
