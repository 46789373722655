import { Description, TableDetails } from '.'

export const DescriptionCard = ({ product, productDetails, type }) => {
  const title = type === 'desc' ? 'Product Description' : 'Product Details'

  return (
    <div className="description-card">
      <h2 className="card-title">{title}</h2>
      {type === 'desc' ? (
        <Description product={product} />
      ) : type === 'detail' ? (
        <TableDetails product={product} />
      ) : null}
    </div>
  )
}
