import { useCallback, useEffect, useState } from 'react'
import { Skeleton } from 'antd'

import { IGNORE_LIST } from '../../../data/countries'

import { fetchAvailableCountries, orderByAvailability } from '../../../util/functions'

import { useExplore } from '../../../hooks/ui/useExplore'

import { List } from './components/List'
import { Explore } from './Explore'

/**
 * React component representing a list of countries in the market.
 * Fetches and displays available countries with icons and product information.
 * @author Rafael Rapizo Nery
 */
export const Countries = () => {
  const {
    filtered,
    setFiltered,
    all,
    setAll,
    isLoading,
    fetch,
    search,
    redirect,
  } = useExplore()
  const [pageSize, setPageSize] = useState(14)

  // Dynamic pagination based on screen size based on _explore.scss
  useEffect(() => {
    if (window.innerWidth < 720) {
      setPageSize(8)
    } else if (window.innerWidth > 720 && window.innerWidth < 1440) {
      setPageSize(16)
    } else if (window.innerWidth > 1440 && window.innerWidth < 1920) {
      setPageSize(18)
    } else {
      setPageSize(24)
    }
  }, [])

  /**
   * Callback function to handle fetching countries and updating state.
   * @param {object} res - Response object from the country fetch request.
   */
  const handleFetchCountries = useCallback(
    async (res) => {
      // Fetch available countries with product information
      let availableCountries = await fetchAvailableCountries()

      // Map the response data to a modified country object
      let countries = [
        ...res.data.map((c) => {
          let country = availableCountries.find((ac) => ac.country === c.name.common)

          return {
            name: c.name.common,
            icon: `${process.env.REACT_APP_CDN_URL}/icons/countries/${c.name.common}.svg`,
            isAvailable: !!country,
            products: country ? country.products : 0,
            description: country ? `${country.products} products` : '0 products',
          }
        }),
      ].filter((c) => !IGNORE_LIST.includes(c.name))

      // Order countries by availability (available first, then alphabetical)
      countries = orderByAvailability(countries)

      setAll(countries)
    },
    [setAll],
  )
  // Set filtered countries based on pagination when 'all' or 'pagination' changes
  useEffect(() => setFiltered(all.slice(0, pageSize)), [all, setFiltered, pageSize])

  // Fetch countries when the component mounts or 'handleFetchCountries' changes
  useEffect(() => {
    fetch('https://restcountries.com/v3.1/all', handleFetchCountries)
  }, [fetch, handleFetchCountries])

  return (
    <Explore title="Market" onSearch={(value) => search(value)}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <List
          infinite={true}
          hasMore={pageSize <= filtered.length}
          onNext={() => setPageSize((prev) => prev + pageSize)} // fn
          content={filtered}
          onClickCard={(item) =>
            redirect({ baseURL: '/products', target: 'country' }, [item.name])
          }
          classname={'explore__list--countries'}
          withDescription
        />
      )}
    </Explore>
  )
}
