import { useSelector } from 'react-redux'
import { Button } from 'antd'

import { history } from '../../../../../index'

export const FooterMenu = () => {
  const { orders } = useSelector((state) => state.cart)

  const redirectCart = () => history.push('/cart')

  return (
    <>
      <Button
        type="primary"
        className="ant-btn-primary ant-btn-ttu w-100 py-1"
        disabled={!!!orders.length}
      >
        start order
      </Button>
      <Button
        type="primary"
        className="ant-btn-primary__outlined ant-btn-ttu w-100 py-1 mt-2"
        onClick={redirectCart}
      >
        view my cart
      </Button>
    </>
  )
}
