import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { message } from 'antd'

import { ErrorMessage } from '../../models/Messages'

import { history } from '../..'

import { fetchSearch } from '../../util/functions/fetch/fetchSearch'
import convertUrlToObj from '../../helpers/convert-url-params-to-obj'

/**
 * @description Custom hook for the search page to handle the search results state and logic for the search page component
 * @returns {Object} An object with the following properties:
 * - isLoading: A boolean to indicate if the search results are being fetched
 * - data: An array of search results
 * - pagination: An object with the pagination information
 * - title: The search page title
 * - handlePageChange: A function to handle the search results page change
 * @example <caption>Usage</caption>
 * const { isLoading, data, pagination, title, handlePageChange } = useSearch()
 * @see fetchSearch
 * @requires fetchSearch
 * @requires ErrorMessage
 * @requires convertUrlToObj
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const useSearch = () => {
  const { search } = useLocation()
  const [title, setTitle] = useState('Search:')
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({})
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(16)
  const [isLoading, setIsLoading] = useState(false)

  /**
   * @description Handles the search result item redirect
   * @param {Object} item The search result item
   * @function handleRedirect
   * @inner
   * @memberof useSearch
   * @example <caption>Usage</caption>
   * handleRedirect({ type: 'product', slug: 'test' })
   * @requires history
   * @author Rafael Rapizo Nery
   * @version 1.0.0
   *
   */
  let handleRedirect = (item) => {
    console.log('Redirect to item', item)
    switch (item.type) {
      case 'product':
        history.push(`/product/${(encodeURIComponent(item.slug))}`)
        break
      case 'brand':
        history.push(`products?brand=${item.id}&title=${item.name}`)
        break
      case 'seller':
        history.push(`products?supplier=${item.id}&title=${item.name}`)
        break
      default:
        break
    }
  }

  /**
   * @description Fetches the search results
   * @param {Object} params The query parameters object
   * @returns {Promise} A promise that resolves to the search results array
   * @async
   * @function handleSearch
   * @inner
   * @memberof useSearch
   * @example <caption>Usage</caption>
   * handleSearch({ search: 'test', target: 'all' })
   * .then((data) => console.log(data))
   * .catch((err) => console.error(err))
   * @see fetchSearch
   * @requires fetchSearch
   * @requires ErrorMessage
   * @author Rafael Rapizo Nery
   * @version 1.0.0
   */
  let handleSearch = useCallback(async (params) => {
    setIsLoading(true)
    try {
      let { data, pagination } = await fetchSearch(params)
      setData(data)
      setPagination(pagination)
    } catch (error) {
      if (!TypeError === error) {
        console.error(error)
        message.error(ErrorMessage.genericServerError)
      }
    } finally {
      setIsLoading(false)
    }
  }, [])

  /**
   * @description Handles the search results page change
   * @param {Number} page The new selected page
   * @function handlePageChange
   * @inner
   * @memberof useSearch
   * @example <caption>Usage</caption>
   * handlePageChange(2)
   * @author Rafael Rapizo Nery
   * @version 1.0.0
   */
  let handlePageChange = useCallback((page) => {
    setPage(page)
  }, [])

  // Page size useEffect
  useEffect(() => {
    if (window.innerWidth < 720) {
      setPageSize(8)
    } else if (window.innerWidth > 720 && window.innerWidth < 1440) {
      setPageSize(16)
    } else if (window.innerWidth > 1440 && window.innerWidth < 1920) {
      setPageSize(18)
    } else {
      setPageSize(24)
    }
  }, [])

  // Query useEffect
  useEffect(() => {
    let query = new URLSearchParams(search)
    let params = convertUrlToObj(query.toString())
    window.scrollTo(0, 0)
    handleSearch({ ...params, page, perPage: pageSize })
  }, [search, page, pageSize, handleSearch])

  // Title useEffect
  useEffect(() => {
    let query = new URLSearchParams(search)
    let { target, search: term } = convertUrlToObj(query.toString())

    setTitle(`Search ${target}: ${term}`)
  }, [search])

  return { isLoading, data, pagination, title, handlePageChange, handleRedirect }
}
