export class ErrorMessage {
  static title = 'Error!'
  static genericServerError = 'Something went wrong! Contact Support or try again later!'
  static cart = {
    missingRequiredData:
      "This product doesn't have the required data. Please contact support.",
    noProductSellected: 'Please, select a product.',
    onlyOneProduct: 'Please, select only one product for a Custom Import Duty request.',
  }
  static logisticQuote = {
    missingPckgData:
      "We won't be able to proceed with your Logistic Quote because the product has incomplete packaging data.",
  }
  static INVALID_CREDENTIALS = 'Invalid credentials'

  static ATTRIBUTE_INEXACT_MATCH = 'No product found with the selected attributes.'
}
