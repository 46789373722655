import { Collapse, Checkbox, Skeleton } from 'antd'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

import { useFiltersSelection } from '../../../../hooks/ui/FiltersSelection/useFiltersSelection'

import { CategoriesTree } from './CategoriesTree'
import { PriceRangeController } from './Filters/PriceRangeController'

const Filters = ({
  filters = [],
  isLoading = true,
  onCategoryChange = () => null,
  onPriceRangeChange = () => null,
  onSelectchange = () => null,
}) => {
  const { activeFilters, activeKeys, setActiveKeys } = useFiltersSelection()

  return (
    <div className="filters-wrapper">
      {isLoading && filters ? (
        <Skeleton />
      ) : (
        <>
          {filters && (
            <CategoriesTree
              categoriesData={filters.categories ?? []}
              onChange={onCategoryChange}
            />
          )}
          <Collapse
            defaultActiveKey={activeFilters}
            expandIconPosition="right"
            expandIcon={(collapseProps) => {
              if (collapseProps.isActive) {
                return <CaretUpOutlined />
              }
              return <CaretDownOutlined />
            }}
          >
            <Collapse.Panel key="minMaxPrice" header="Price">
              <PriceRangeController
                defaultMin={filters && filters.minMaxPrice.minPrice}
                defaultMax={filters && filters.minMaxPrice.maxPrice}
                callback={onPriceRangeChange}
              />
            </Collapse.Panel>
            {filters.select &&
              filters.select.map((filter) => {
                return (
                  <Collapse.Panel
                    id={filter.query}
                    key={filter.query}
                    header={filter.title}
                  >
                    {filter.options &&
                      filter.options.map((option, index) => {
                        return (
                          <Checkbox.Group
                            defaultValue={activeKeys}
                            className="d-flex flex-column"
                            key={index}
                            options={option.options}
                          >
                            <Checkbox
                              className="mb-2"
                              // defaultChecked={activeKeys.includes(option.value)}
                              checked={activeKeys.includes(option.value)}
                              name={filter.key}
                              value={option.value}
                              onChange={(e) => {
                                activeKeys.includes(option.value)
                                  ? setActiveKeys((prev) =>
                                      prev.filter((key) => key !== option.value),
                                    )
                                  : setActiveKeys((prev) => [...prev, option.value])

                                onSelectchange({
                                  query: filter.query,
                                  value: e.target.value,
                                })
                              }}
                            >
                              {option.icon && (
                                <img
                                  className="me-1"
                                  src={option.icon}
                                  alt={`${option.icon} icon`}
                                />
                              )}
                              {option.key}
                            </Checkbox>
                          </Checkbox.Group>
                        )
                      })}
                  </Collapse.Panel>
                )
              })}
          </Collapse>
        </>
      )}
    </div>
  )
}

export default Filters
