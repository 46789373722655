import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import user from './user/reducer'
import cart from './cart/reducer'

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    cart
  })

export default createRootReducer
