import { NavLink, useLocation } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * @description MobileMenu component is used to display the mobile menu of the marketplace
 * @param {boolean} menu - Menu state
 * @param {function} setMenu - Set menu state function
 * @returns {JSX.Element}
 * @author Rafael Rapizo Nery
 * @since 1.0.0
 * @version 1.0.0
 */
export const MobileMenu = ({ menu, setMenu }) => {
  const location = useLocation()

  const isSellerShowroom = () => {
    return location.pathname.includes('showroom') || location.pathname.includes('company')
  }

  const getMenuOptions = (slug = null) => {
    return isSellerShowroom() ? getSSMenuOptions(slug) : getDefaultMenuOptions()
  }

  const getDefaultMenuOptions = () => {
    return [
      { title: 'Products', path: '/products' },
      { title: 'Categories', path: '/explore/categories' },
      { title: 'Brands', path: '/explore/brands' },
      { title: 'Markets', path: '/explore/countries' },
      { title: 'About', path: 'https://about.tradeto.world' },
    ]
  }

  const getSSMenuOptions = (slug) => {
    return [
      { title: 'Overview', path: '/' },
      { title: 'company-facts', path: `/company/${encodeURIComponent(slug)}` },
      { title: 'Catalogue', path: `/showroom/${encodeURIComponent(slug)}` },
    ]
  }

  const getSlug = () => {
    return location.pathname.split('/').pop()
  }

  return (
    <>
      <div
        className={`mobile-menu_wrapper ${
          isSellerShowroom() ? 'mobile-menu_wrapper--ss' : ''
        }`}
        data-open={menu ? '1' : '0'}
      >
        <ul>
          {getMenuOptions(getSlug()).map((option, index) => (
            <li key={index}>
              <NavLink to={option.path} onClick={() => setMenu(false)}>
                {option.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}
