import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Input } from 'antd'
import { LeftOutlined, SearchOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet-async'

/**
 * React component representing an exploration page with breadcrumbs, a title, and search functionality.
 * @param {Object} props - Component properties.
 * @param {string} props.title - Title of the exploration page.
 * @param {ReactNode} props.children - Child components or content to be rendered within the exploration page.
 * @param {Function} props.onSearch - Callback function to handle search input changes.
 * @param {Array} props.breadcrumbs - Array of breadcrumb items containing title and link.
 * @returns {JSX.Element} - Rendered Explore component.
 * @author Rafael Rapizo Nery
 */
export const Explore = ({
  title = '',
  children,
  onSearch,
  breadcrumbs = [{ title: 'Home', to: '/' }],
}) => {
  return (
    <>
      <Helmet title={`TradeToWorld | ${title}`} />
      <div className="explore">
        <div className="explore__header">
          <div className="explore__header__breadcrumbs">
            {breadcrumbs.map((item) => (
              <Link to={item.to} className="explore__header__breadcrumbs__item">
                <LeftOutlined />
                <p>{item.title}</p>
              </Link>
            ))}
          </div>
          <div className="explore__header__title">
            <h5>Shop by {title}</h5>
            <div className="explore__header__title__search">
              <Input
                type="text"
                placeholder={`Search${!!title ? ` ${title.toLowerCase()}` : '...'}`}
                onChange={(e) => onSearch(e.target.value)}
                suffix={<SearchOutlined onClick={onSearch} />}
              />
            </div>
          </div>
        </div>
        <div>{children}</div>
      </div>
    </>
  )
}
