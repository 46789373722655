import React from 'react'

const ProductPackaging = ({ packaging }) => {
  console.log({ packaging })

  return (
    <div className="description-card description-card__packaging mt-4">
      <h2 className="card-title">Packaging Details</h2>
      <div className="product-details__packages">
        {packaging && packaging.length > 0 ? (
          packaging.map((pack) => {
            if (pack.get_variants && pack.get_variants.length > 0) {
              return pack.get_variants.map((variant) => (
                <div key={variant.id} className="package-item row">
                  <div className="package-item__head col-12 col-lg-3">
                    <div className="package-icon">
                      <img src={variant.get_variant.icon} alt={variant.get_type.name} />
                    </div>
                    <div className="package-name">
                      <span className="package-name__type">({pack.key})</span>
                      {variant.get_type.name}
                      {variant.innerPackPrice ? (
                        <span>${variant.innerPackPrice}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="package-item__content col-12 col-lg-9">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <p className="mb-0">
                          Units per pack:{' '}
                          <span className='ms-auto md-ms-none'>{variant.productPerInnerPack || '-'}</span>
                        </p>
                      </div>
                      <div className="col-12 col-md-6">
                        <p>
                          Pack dimensions:{' '}
                          <span className='ms-auto md-ms-none'>
                            {variant.innerPackDimensionsWidth &&
                            variant.innerPackDimensionsWidth !== 0 &&
                            variant.innerPackDimensionsWidth !== '0'
                              ? variant.innerPackDimensionsWidth
                              : ''}
                            {variant.innerPackDimensionsHeight &&
                            variant.innerPackDimensionsHeight !== 0 &&
                            variant.innerPackDimensionsHeight !== '0'
                              ? `x${variant.innerPackDimensionsHeight}`
                              : ''}
                            {variant.innerPackDimensionsDepth &&
                            variant.innerPackDimensionsDepth !== 0 &&
                            variant.innerPackDimensionsDepth !== '0'
                              ? `x${variant.innerPackDimensionsDepth}`
                              : ''}{' '}
                            {variant.innerPackDimensionsWidth &&
                            variant.innerPackDimensionsWidth !== 0 &&
                            variant.innerPackDimensionsWidth !== '0' &&
                            variant.innerPackDimensionsHeight &&
                            variant.innerPackDimensionsHeight !== 0 &&
                            variant.innerPackDimensionsHeight !== '0' &&
                            variant.innerPackDimensionsDepth &&
                            variant.innerPackDimensionsDepth !== 0 &&
                            variant.innerPackDimensionsDepth !== '0'
                              ? variant.innerPackDimensionsType
                              : '-'}
                          </span>
                        </p>
                      </div>
                      <div className="col-12 col-md-6">
                        <p>
                          Total weight (product+packaging):{' '}
                          <span className='ms-auto md-ms-none'>
                            {variant.innerPackWeightTotalValue
                              ? variant.innerPackTotalWeight.value +
                                ' ' +
                                variant.innerPackTotalWeight.type
                              : '-'}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
            return null
          })
        ) : (
          <div className="package-item">
            <p>No packaging information available</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductPackaging
