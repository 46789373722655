import React, { Suspense, lazy, useState } from 'react'
import { Skeleton, Tabs } from 'antd'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import { DiscountBadge } from '../../../../ui/cards/ProductCard/components/DiscountBadge'
import { NewArrivalBadge } from '../../../../ui/cards'

const ProductCarousel = lazy(() => import('../Carousel'))
const ProductInfo = lazy(() => import('../ProductInfo'))
const ProductDescription = lazy(() => import('../ProductDescription'))
const ProductDetails = lazy(() => import('../ProductDetails'))
const ProductPrices = lazy(() => import('../ProductPrices'))
const SeekTradeSupport = lazy(() => import('../SeekTradeSupport'))
const ProductPackaging = lazy(() => import('../ProductPackaging'))
const Attributes = lazy(() =>
  import('../../../../../pages/marketplace/products/components/Attributes/Attributes'),
)
const PriceCTA = lazy(() => import('../PriceCTA/PriceCTA'))

/**
 * @description HorizontalView component is used to display the product details in a horizontal layout.
 * @category Component
 * @namespace Pages - Marketplace - ProductDetails - HorizontalView
 * @component HorizontalView
 *
 * @param {Object} props
 * @prop {Object} props.product - The product to be displayed.
 * @prop {Object} props.user - The user to be displayed.
 *
 * @example
 * return (
 *  <HorizontalView product={product} user={user} />
 * )
 *
 * @requires React
 * @requires antd/Tabs
 * @requires Components - ProductCarousel
 * @requires Components - ProductInfo
 * @requires Components - ProductDescription
 * @requires Components - ProductDetails
 * @requires Components - ProductAttributes
 * @requires Components - ProductPrices
 * @requires Components - AddToCart
 * @requires Components - SeekTradeSupport
 * @requires Components - ProductPackaging
 *
 * @todo Add the proptypes
 * @todo Add the tests
 * @todo Add the styles
 * @todo Add the storybook
 * @todo Add the documentation
 * @todo Refactor for modularity
 * @todo Refactor for performance
 *
 * @created 02-29-2024
 * @version 0.1.1
 * @since 0.1.0
 *
 * @author Rafael Rapizo Nery
 */
const HorizontalView = ({ product, user }) => {
  const [selectedPrice, setSelectedPrice] = useState(null)

  return (
    <div className="product-details">
      <div className="container">
        <div className="product-details__wrapper">
          <div className="row">
            <ErrorBoundary
              message="Error"
              description="Something went wrong, please try again later."
            >
              <div className="badges">
                <DiscountBadge product={product.product} />
                <NewArrivalBadge
                  isShow={
                    new Date().valueOf() - new Date(product.product.created_at).valueOf() <=
                    1209600033
                  }
                />
              </div>
            </ErrorBoundary>
            <div className="col-12 col-md-6">
              <Suspense fallback={<Skeleton />}>
                <ProductCarousel images={product.product.get_all_images || []} />
              </Suspense>
            </div>
            <div className="col-12 col-md-6">
              <Suspense fallback={<Skeleton />}>
                <ProductInfo product={product} />
                <Attributes product={product} isOptionClickable={true} isLinkDisabled isProductDetails />
                <ErrorBoundary
                  description="Something went wrong with the product prices. Please try again later."
                  message="Error"
                >
                  <ProductPrices
                    hidden={!(user && user.user && user.user.id)}
                    prices={product.product.sales_terms}
                    selectedPrice={selectedPrice}
                    setSelectedPrice={setSelectedPrice}
                  />
                </ErrorBoundary>
                <ErrorBoundary
                  message="Error"
                  description="Something went wrong, please try again later."
                >
                  <PriceCTA
                    layout="horizontal"
                    product={product}
                    price={selectedPrice}
                    isUserAuthorized={!!(user && user.user && user.user.id)}
                  />
                </ErrorBoundary>
              </Suspense>
            </div>
            <div className="col-12">
              <Suspense fallback={<Skeleton />}>
                <SeekTradeSupport supplier={product.company} />
              </Suspense>
            </div>
          </div>
        </div>
      </div>
      <div className="product-description">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-9">
              <Suspense fallback={<Skeleton />}>
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane tab="Product Description" key={1}>
                    <ProductDescription product={product} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Product Details" key={2}>
                    <ProductDetails product={product} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Packaging Details" key={3}>
                    <ProductPackaging packaging={product.product.get_packaging} />
                  </Tabs.TabPane>
                </Tabs>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HorizontalView
