import { history } from "../.."

/**
 * @description This class is a helper class for history
 * @class
 * @name HistoryHelper
 * @export
 * @static
 * @author Rafael Rapizo Nery
 * @created 2024-02-06
 */
export class HistoryHelper {
  static redirect = (path) => history.push(path)
}
