import { useEffect, useState } from 'react'
import { Popover, Checkbox } from 'antd'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import { actions } from '../../../../../redux/cart/actions'

import { ProductQtyController } from '../../../../ui/ProductQtyController'

export const ProductItem = ({
  product,
  totalPrice,
  selectedPrice,
  quantity,
  data,
  isSelected,
  onSelect,
}) => {
  const dispatch = useDispatch()
  const [qty, setQty] = useState(quantity)

  const handleDeleteProduct = () => {
    dispatch({
      type: actions.REMOVE,
      payload: {
        productId: product.id,
      },
    })
  }

  useEffect(() => {
    console.log(qty)
    dispatch({
      type: actions.SET_QTY,
      payload: {
        productId: product.id,
        qty,
      },
    })
  }, [dispatch, product, qty])

  return (
    <div className="cart__products__wrapper__list__item">
      <div className="row">
        <div className="col-lg-2 cart__products__wrapper__list__item__preview">
          <Checkbox
            checked={isSelected(product.id)}
            onClick={() => onSelect(product.id)}
          ></Checkbox>
          <img src={product.get_all_images[0].original} alt={`${product.name}`} />
        </div>
        <div className="col-lg-10 cart__products__wrapper__list__item__info">
          <div className="row">
            <div className="col-lg-11 mb-3">
              <Link to={`/product/${encodeURIComponent(product.slug)}`}>{product.name}</Link>
            </div>
            <div className="col-lg-1">
              <i onClick={handleDeleteProduct} className="fe fe-trash"></i>
            </div>
            <div className="col-lg-5">
              <div className="row">
                {data.options &&
                  Object.keys(data.options).map((p) => (
                    <div className="col-lg-6">
                      <p>
                        {p}: <span>{data.options[p][0].value ?? '--'}</span>
                      </p>
                    </div>
                  ))}
                <div className="col-lg-12 cart__products__wrapper__list__item__info__packaging">
                  Packaging & Logistic
                  <Popover
                    className="rounded"
                    content={
                      <>
                        {product.get_packaging[0] &&
                          product.get_packaging.map((p) => {
                            if (p.get_variants.length === 0) return null

                            return (
                              <div className="cart__products__wrapper__list__item__info__packaging__item">
                                <p>{p.name}:</p>

                                <span>
                                  <img
                                    src={p.get_variants[0].get_type.icon || ''}
                                    alt={`${p.get_variants[0].get_type.name || ''} Icon`}
                                  />
                                  <span>{p.get_variants[0].get_type.name || ''}</span>
                                </span>
                              </div>
                            )
                          })}
                        {product.get_packaging[0] && (
                          <>
                            <div className="cart__products__wrapper__list__item__info__packaging__item">
                              <p>Weight:</p>
                              <span>
                                {product.get_packaging[0].get_variants.length > 0 &&
                                product.get_packaging[0].get_variants[0].get_variant
                                  .innerPackWeightValue
                                  ? product.get_packaging[0].get_variants[0].get_variant
                                      .innerPackWeightValue
                                  : 'Contact the supplier for further information'}{' '}
                                {product.get_packaging[0].get_variants.length > 0
                                  ? product.get_packaging[0].get_variants[0].get_variant
                                      .innerPackWeightType
                                  : null}
                              </span>
                            </div>
                            <div className="cart__products__wrapper__list__item__info__packaging__item">
                              <p>Volume:</p>
                              <span>
                                {product.get_packaging[0].get_variants.length &&
                                product.get_packaging[0].get_variants[0].get_variant
                                  .innerPackVolumeValue ? (
                                  <>
                                    {
                                      product.get_packaging[0].get_variants[0].get_variant
                                        .innerPackDimensionsWidth
                                    }{' '}
                                    x{' '}
                                    {
                                      product.get_packaging[0].get_variants[0].get_variant
                                        .innerPackDimensionsHeight
                                    }{' '}
                                    x{' '}
                                    {
                                      product.get_packaging[0].get_variants[0].get_variant
                                        .innerPackDimensionsDepth
                                    }{' '}
                                    cm (
                                    {
                                      product.get_packaging[0].get_variants[0].get_variant
                                        .innerPackVolumeValue
                                    }{' '}
                                    {
                                      product.get_packaging[0].get_variants[0].get_variant
                                        .innerPackVolumeType
                                    }
                                    )
                                  </>
                                ) : (
                                  'Contact the supplier for further information'
                                )}
                              </span>
                            </div>
                          </>
                        )}
                        <div className="cart__products__wrapper__list__item__info__packaging__item">
                          <p>Customs code:</p>
                          <span>
                            {product.product_data ? product.product_data.hts : '--'}
                          </span>
                        </div>
                      </>
                    }
                  >
                    <i className="fe fe-info"></i>
                  </Popover>
                </div>
              </div>
            </div>
            <div className="col-lg-3 text-center ">
              <ProductQtyController
                qty={qty}
                selectedPrice={selectedPrice}
                setQty={setQty}
                defaultQty={qty}
              />
            </div>
            <div className="col-lg-4">
              <p>
                ${totalPrice.toFixed(2)} {selectedPrice.currency}
              </p>
              <p>({selectedPrice.currency} {parseFloat(selectedPrice.price_value).toFixed(2)} per { selectedPrice.order_measure })</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
