import { Button } from 'antd'

export const ModalCustomsFeesFooter = ({ onSubmit }) => {
  return (
    <div className="modal-customs-fees__footer">
      <Button
        htmlType="submit"
        type="primary"
        className="ant-btn-primary ant-btn-ttu ant-btn--spread w-100"
        onClick={onSubmit}
      >
        send request
      </Button>
    </div>
  )
}
