import { useState } from 'react'

import useResponsive from '../../../../../../hooks/Responsive/useResponsive'

import { SortControllerDesktop } from './SortControllerDesktop/SortControllerDesktop'
import { SortControllerMobile } from './SortControllerMobile/SortControllerMobile'

/**
 * @name SortController
 * @description Sort controller component factory.
 * @param {string} defaultValue - The default value of the controller.
 * @param {Array} options - The options of the controller.
 * @param {Function} onChange - The function to handle the change event.
 * @returns {JSX.Element} - Rendered component.
 * @uthor Rafael Rapizo Nery
 */
export const SortController = ({
  defaultValue,
  options = { title: '', callback: () => null, param: '' },
  onChange,
}) => {
  const [type, setType] = useState('desktop')
  const [value, setValue] = useState(defaultValue ?? '')
  useResponsive({
    onMobile: () => setType('mobile'),
    onTablet: () => setType('desktop'),
    onDesktop: () => setType('desktop'),
  })

  const handleSortChange = (value, param) => {
    setValue(value)
    onChange({ [param]: 'true' })
  }

  return (
    <>
      {type === 'desktop' ? (
        <SortControllerDesktop
          value={value}
          options={options}
          onChange={handleSortChange}
        />
      ) : (
        <SortControllerMobile
          value={value}
          options={options}
          onChange={handleSortChange}
        />
      )}
    </>
  )
}
