import { useState } from 'react'
import { Button, message } from 'antd'
import { ModalCustomsFees } from '../../../../ui/modals/'
import { ErrorMessage } from '../../../../../models/Messages'

export const ProductsWrapperFooter = ({
  totalItens,
  totalPrice,
  showLogisticQuote,
  companySlug,
  selectedOrders,
}) => {
  const [isShowCustomsFees, setIsShowCustomsFees] = useState(false)

  const handleShowCustomsFees = () => {
    if (!selectedOrders || selectedOrders.length <= 0)
      return message.error(ErrorMessage.cart.noProductSellected)
    if (selectedOrders.length > 1) return message.error(ErrorMessage.cart.onlyOneProduct)
    setIsShowCustomsFees(!isShowCustomsFees)
  }

  return (
    <>
      <ModalCustomsFees
        isShow={isShowCustomsFees}
        setIsShow={handleShowCustomsFees}
        slug={companySlug}
      />
      <div className="cart__products__wrapper__footer">
        <div className="cart__products__wrapper__footer__ship-total">
          <div className="row">
            <div className="col-lg-7 fw-bold ">
              <p>Shipment Total</p>
            </div>
            <div className="col-lg-3">
              <p>({totalItens} items)</p>
            </div>
            <div className="col-lg-2 text-end fw-bold">${totalPrice}</div>
          </div>
        </div>
        <div className="cart__products__wrapper__footer__fees">
          <Button
            onClick={() => showLogisticQuote(companySlug)}
            type="ghost"
            className="ant-btn-ttu ant-btn-primary__outlined"
          >
            <i className="fe fe-truck"></i>
            Logistic Quote
          </Button>
          <Button
            type="ghost"
            className="ant-btn-ttu ant-btn-primary__outlined"
            onClick={handleShowCustomsFees}
          >
            <i className="fe fe-file-text"></i>
            Customs Import Duties
          </Button>
        </div>
      </div>
    </>
  )
}
