import React from 'react'
import { StaticGoogleMap, Marker } from 'react-static-google-map'

import MarketplaceCard from '../../../ui/cards/marketplace/MarketplaceCard'

import styles from './contacts.module.scss'

const Contacts = ({ data }) => {
  return (
    <MarketplaceCard title="Contacts">
      <div className={styles.contacts}>
        <div className="row mb-4">
          <div className={`col-12 col-md-6 ${styles.item}`}>
            <div className="d-flex justify-content-start align-items-center">
              <div className="me-3">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.54 5C6.6 5.89 6.75 6.76 6.99 7.59L5.79 8.79C5.38 7.59 5.12 6.32 5.03 5H6.54ZM16.4 17.02C17.25 17.26 18.12 17.41 19 17.47V18.96C17.68 18.87 16.41 18.61 15.2 18.21L16.4 17.02ZM7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.51C21 15.96 20.55 15.51 20 15.51C18.76 15.51 17.55 15.31 16.43 14.94C16.33 14.9 16.22 14.89 16.12 14.89C15.86 14.89 15.61 14.99 15.41 15.18L13.21 17.38C10.38 15.93 8.06 13.62 6.62 10.79L8.82 8.59C9.1 8.31 9.18 7.92 9.07 7.57C8.7 6.45 8.5 5.25 8.5 4C8.5 3.45 8.05 3 7.5 3Z"
                    fill="#004D40"
                  />
                </svg>
              </div>
              <div>
                <a href={`tel:${data.get_user[0].phone_code}${data.get_user[0].phone}`}>
                  {data.get_user[0].phone_code} {data.get_user[0].phone}
                </a>
              </div>
            </div>
          </div>
          <div className={`col-12 col-md-6 ${styles.item}`}>
            <div className="d-flex justify-content-start align-items-center">
              <div className="me-3">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.35 18.92 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4.26 14C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM8.03 8H5.08C6.04 6.34 7.57 5.07 9.41 4.44C8.81 5.55 8.35 6.75 8.03 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM14.34 14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12C14.5 12.68 14.43 13.34 14.34 14ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36Z"
                    fill="#004D40"
                  />
                </svg>
              </div>
              <div>
                <a href={data.website} target="_blank" rel="noreferrer nofollow noopener">
                  {data.website}
                </a>
              </div>
            </div>
          </div>
          <div className={`col-12 col-md-6 ${styles.item}`}>
            <div className="d-flex justify-content-start align-items-center">
              <div className="me-3">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 10.99L4 6H20ZM20 18H4V8L12 13L20 8V18Z"
                    fill="#004D40"
                  />
                </svg>
              </div>
              <div>
                <a href={`mailto:${data.get_user[0].email}`}>{data.get_user[0].email}</a>
              </div>
            </div>
          </div>
          <div className={`col-12 col-md-6 ${styles.item}`}>
            <div className="d-flex justify-content-start align-items-center">
              <div className="me-3">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM7 9C7 6.24 9.24 4 12 4C14.76 4 17 6.24 17 9C17 11.88 14.12 16.19 12 18.88C9.92 16.21 7 11.85 7 9Z"
                    fill="#004D40"
                  />
                  <path
                    d="M12 11.5C13.3807 11.5 14.5 10.3807 14.5 9C14.5 7.61929 13.3807 6.5 12 6.5C10.6193 6.5 9.5 7.61929 9.5 9C9.5 10.3807 10.6193 11.5 12 11.5Z"
                    fill="#004D40"
                  />
                </svg>
              </div>
              <div>
                {data.address}, {data.city}, {data.country}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.map}>
          <StaticGoogleMap
            size="600x180"
            zoom={14}
            scale={2}
            className="img-fluid"
            apiKey="AIzaSyChUeLx-Y_B9wP7OZ4PItaiqrYNh4wYl6w"
            maptype="roadmap"
            style={'feature:all|saturation:-100'}
          >
            <Marker
              color="0x00968A"
              size="small"
              location={`${data.address}, ${data.city}, ${data.country}`}
            />
          </StaticGoogleMap>
        </div>
      </div>
    </MarketplaceCard>
  )
}

export default Contacts
