import { Dropdown } from 'antd'

const OVERLAY_STYLE = {
  backgroundColor: 'rgb(0,0,0,0.8)',
  padding: '2px 4px',
  borderRadius: '4px',
  color: 'white',
}

export const IconList = ({ data = [], title = '', className = '', isLogo = false }) => {
  return (
    <div className={className}>
      <span>{title}:</span>
      <ul className="logo-list">
        {data.map((item, index) => (
          <Dropdown
            overlayStyle={OVERLAY_STYLE}
            placement="bottomCenter"
            overlay={item.name}
          >
            <li className="logo-list__logo" key={index}>
              <img src={item[isLogo ? 'logo' : 'icon']} alt={`${item.name}'s logo`} />
            </li>
          </Dropdown>
        ))}
      </ul>
    </div>
  )
}
