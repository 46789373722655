import { useEffect, useState } from 'react'

import { ProductCardSS } from '../../ProductCard/ProductCardSS'
import { CardList } from '../CardList'

/**
 * @description CardListSS component is a styled component for the CardList component.
 * It uses the ProductCardSS component to render the list of products.
 * @param {string} view - The view of the card list.
 * @param {function} setView - The function to set the view of the card list.
 * @param {array} products - The list of products to be rendered.
 * @param {string} className - The additional class name for the card list.
 * @see ProductCardSS
 * @see CardList
 * @example
 * <CardListSS view="grid" products={products} />
 * @requires react
 * @requires useState
 * @requires useEffect
 * @requires ProductCardSS
 *
 * @method useEffect - Configures the view according to the number of products.
 *
 * @since 0.1.1
 * @version 0.1.1
 * @author Rafael Rapizo Nery
 */
export const CardListSS = ({ view, setView, products, className }) => {
  const [isDefaultExpanded, setIsDefaultExpanded] = useState(false)
  const [isShowDescription, setIsShowDescription] = useState(false)

  // useEffect for configuring the view according to the number of products
  useEffect(() => {
    if (products.length === 1) {
      setView('list')
      setIsDefaultExpanded(true)
    } else if (products.length >= 2 && products.length <= 3) {
      setView('list')
      setIsShowDescription(true)
    } else if (products.length >= 4 && products.length <= 6) {
      setView('list')
    } else {
      setView('grid')
    }
  }, [])

  return (
    <CardList view={view} className={className}>
      {products.map((product) => (
        <ProductCardSS
          view={view}
          key={product.id}
          product={product}
          defaultExpanded={isDefaultExpanded}
          showDescription={isShowDescription}
        />
      ))}
    </CardList>
  )
}
