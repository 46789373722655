import { useState, useEffect } from 'react'
import { Form, Input, Select, Radio } from 'antd'

import PHONE_CODES from '../../../../../data/phone-codes'
import COUNTRIES_LIST from '../../../../../data/countries'

import { getAddresses } from '../../../../../util/functions/getAddressess'

export const SellerAddress = ({ seller, addressHandler }) => {
  const [isNewAddress, setIsNewAddress] = useState(false)
  const [address, setAddress] = useState(null)

  const resetAddress = () => {
    setAddress({
      city: '',
      country: '',
      street: '',
      zip: '',
    })
  }

  const handleSelectAddress = (zip = '', addressess = []) => {
    const address = addressess.find((a) => a.zip === zip)

    addressHandler(address)
  }

  const handleOpenNewAddress = () => {
    resetAddress()
    setIsNewAddress(true)
  }

  const handleSetAddress = (value, field) => {
    const updatedAddress = { ...address }

    updatedAddress[field] = value

    setAddress(updatedAddress)
  }

  const handleDefaultAddress = () => {
    const hqAddress = getAddresses(seller).find((a) => !a.isStock)

    setAddress(hqAddress)
    setIsNewAddress(false)
  }

  useEffect(() => {
    if (address !== null) {
      addressHandler(address)
    }
  }, [address, addressHandler])

  return (
    <div className="logistic_quote__seller">
      <div className="row">
        <div className="col-12">
          <p>Seller Information</p>
        </div>
        <div className="col-6">
          <Form.Item
            name={'sCompany'}
            rules={[{ required: true, message: 'The field is required.' }]}
          >
            <Input type="text" placeholder="Seller's Company*" />
          </Form.Item>
        </div>
        <div className="col-6"></div>
        <div className="col-6">
          <Form.Item
            name={'sFirstName'}
            rules={[{ required: true, message: 'The field is required.' }]}
          >
            <Input type="text" placeholder="First Name*" />
          </Form.Item>
        </div>
        <div className="col-6">
          <Form.Item
            name={'sLastName'}
            rules={[{ required: true, message: 'The field is required.' }]}
          >
            <Input type="text" placeholder="Last Name*" />
          </Form.Item>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col-4">
              <Form.Item name={'sPhoneDigit'}>
                <Select className="w-100" showSearch>
                  {PHONE_CODES.map((pc) => (
                    <Select.Option key={pc.code} value={pc.dial_code}>
                      <img
                        src={`https://flagcdn.com/${pc.code.toLowerCase()}.svg`}
                        alt={pc.code}
                        width="16"
                      />
                      <span className="ps-2">{pc.dial_code}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-8">
              <Form.Item
                name={'sPhone'}
                rules={[{ required: true, message: 'The field is required.' }]}
              >
                <Input type="text" placeholder="Phone Number*" />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="col-6">
          <Form.Item
            name={'sEmail'}
            rules={[{ required: true, message: 'The field is required.' }]}
          >
            <Input type="email" placeholder="Email*" />
          </Form.Item>
        </div>
        <div className="col-6">
          <p>Loading Address</p>
          <div className="logistic_quote__seller__address mb-3">
            <Radio.Group defaultValue={1}>
              <Radio value={1} checked={!isNewAddress} onClick={handleDefaultAddress}>
                Select Address
              </Radio>
              {!isNewAddress && (
                <Select
                  onChange={(e) => handleSelectAddress(e, getAddresses(seller))}
                  defaultValue={getAddresses(seller)
                    .find((a) => !a.isStock)
                    .getAddressMessage()}
                >
                  {getAddresses(seller).map((a) => (
                    <Select.Option
                      value={a.zip}
                      title={a.getAddressMessage()}
                      key={a.zip}
                    >
                      {`${a.getAddressMessage()}`}
                    </Select.Option>
                  ))}
                </Select>
              )}
              <Radio checked={isNewAddress} value={2} onClick={handleOpenNewAddress}>
                Add New Address
              </Radio>
            </Radio.Group>
          </div>
        </div>
        {isNewAddress && (
          <>
            <div className="col-lg-6"></div>
            <div className="col-lg-6">
              <Form.Item>
                <Select
                  placeholder="Country*"
                  showSearch
                  onChange={(e) => handleSetAddress(e, 'country')}
                >
                  {COUNTRIES_LIST.map((c) => (
                    <Select.Option value={c.name} key={c.code}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item>
                <Input
                  type="text"
                  placeholder="Zip code"
                  onChange={(e) => handleSetAddress(e.target.value, 'zip')}
                />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item rules={[{ required: true, message: 'The field is required.' }]}>
                <Input
                  type="text"
                  placeholder="City*"
                  onChange={(e) => handleSetAddress(e.target.value, 'city')}
                />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item rules={[{ required: true, message: 'The field is required.' }]}>
                <Input
                  type="text"
                  placeholder="Street*"
                  onChange={(e) => handleSetAddress(e.target.value, 'street')}
                />
              </Form.Item>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
