import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { Skeleton, notification, message, Pagination } from 'antd'

import { history } from '../../..'

import axiosRequest from '../../../hooks/useAxiosRequest'
import useResponsive from '../../../hooks/Responsive/useResponsive'
import useSellerShowroom from '../../../hooks/ui/SellerShowroom/useSellerShowroom'

import Header from '../../../components/pages/marketplace/explore/Header'
import CompanyInfo from '../../../components/pages/marketplace/seller-showroom/CompanyInfo'
import { CompanyBanner } from './components/CompanyBanner'
import { CardListSS } from '../../../components/ui/cards/CardList/CardListSS/CardListSS'

const mapStateToProps = ({ user }) => ({ user })

const SellerShowroom = ({ user }) => {
  const userData = user.user

  const { slug } = useParams()
  const [data, setData] = useState(null)
  const [pageTitle, setPageTitle] = useState('Seller Showroom')
  const [view, setView] = useState('list')

  // Filters state
  const [viewPort, setViewPort] = useState('desktop')
  const {
    filters,
    sortOptions,
    products,
    pagination,
    handlePageChange,
    handleSortChange,
    handleApplyFilters,
    handleClearFilters,
    handleRemoveFilterValue,
    handleSelectChange,
    isLoading,
    tags,
  } = useSellerShowroom(slug.split('-')[0])

  useResponsive({
    onMobile: () => setViewPort('mobile'),
    onTablet: () => setViewPort('tablet'),
    onDesktop: () => setViewPort('desktop'),
  })

  useEffect(() => {
    axiosRequest(`/api/v1/marketplace/showroom/${slug}?page=1`, null, 'get', true)
      .then((res) => {
        if (res.data) {
          const { company } = res.data.data

          // redirect if blocked user
          if (company.get_user[0].status !== 1) {
            message.warning(
              "For security reasons, this company's page is currently unavailable. Please, try again later.",
            )
            return history.push('/')
          }
          setPageTitle(company.name)

          if (res.data.data.products.data) {
            res.data.data.products.data = [...res.data.data.products.data].sort(
              (a, b) => {
                if (Number.parseInt(a.order) >= 0 && Number.parseInt(b.order) >= 0) {
                  return Number.parseInt(a.order) - Number.parseInt(b.order)
                }
                return 0
              },
            )
          }

          setData(res.data.data)
        }
      })
      .catch(() => {
        notification.error({
          message: 'Something goes wrong, please try again later',
          description: 'Server error',
        })
      })
  }, [slug])

  return (
    <div className="sellerShowroom">
      <Helmet title={pageTitle} />
      {!data ? (
        <div className="container">
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      ) : (
        <div>
          <CompanyBanner
            company={data.company}
            bannerSrc={data.company.bannerImage}
            isEditable={
              userData &&
              userData.seller_company &&
              userData.seller_company === data.company.id
            }
          />
          <div className="sellerShowroom__bg-wrapper sellerShowroom__seller-showroom">
            <div className="container">
              <div className="products-list products-list__white sellerShowroom__products sellerShowroom__body">
                <div className="companyInfo">
                  <div className="sellerShowroom__title">
                    <div className="logo">
                      <img src={data.company.logo} alt={data.company.name} />
                    </div>
                    <div className="name">{data.company.name}</div>
                    {data.company && data.company.description ? (
                      <p className="company-description">{data.company.description}</p>
                    ) : null}
                  </div>
                  {viewPort === 'desktop' && (
                    <CompanyInfo company={data.company} isSellerShop={false} />
                  )}
                </div>
                <div className="products mt-4">
                  <Header
                    title="Explore products"
                    view={view}
                    setView={setView}
                    hideNavToggle
                    hideTitle
                    filters={filters}
                    sortOptions={sortOptions}
                    onSelectchange={handleSelectChange}
                    onClearFilters={handleClearFilters}
                    onApplyFilters={handleApplyFilters}
                    onSortChange={handleSortChange}
                    onRemoveFilter={handleRemoveFilterValue}
                    tags={tags}
                    isSellerShowroom
                  />
                  {/* TODO: Create tag list of current */}

                  {isLoading ? (
                    <Skeleton />
                  ) : (
                    <>
                      <CardListSS view={view} setView={setView} products={products} />
                      <div className="d-flex justify-content-center my-4">
                        {products &&
                        pagination &&
                        pagination.per_page < pagination.total ? (
                          <Pagination
                            size="small"
                            current={pagination.current_page}
                            pageSize={pagination.per_page}
                            total={pagination.total}
                            showSizeChanger={false}
                            onChange={(page) => {
                              handlePageChange(page)
                              window.scrollTo(0, 0)
                            }}
                          />
                        ) : null}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(SellerShowroom))
