import { message } from 'antd'
import { ErrorMessage, SuccessMessage } from '../../Messages'
import axiosRequest from '../../../hooks/useAxiosRequest'

/**
 * CustomFee class
 * @class
 * @classdesc This class is used to create a CustomFee object
 * @category Models
 * @namespace Models - CustomFee
 * @example
 * const customFee = new CustomFee('Brazil', 'USA', '1234')
 * customFee.setSellerCompanyId('1234')
 * customFee.setBuyerCompanyId('5678')
 * customFee.setBroker('1234')
 * customFee.toJSON()
 * @since 1.0.2
 * @created 03-26-2024
 * @author Rafael Rapizo Nery
 */
export class CustomFee {
  #exportMarket
  #importMarket
  #seller_company_id
  #buyer_company_id
  #product_id
  #broker

  /*
  --------------------
  CONSTRUCTOR
  --------------------
  */
  constructor(exportMarket, importMarket, productId) {
    this.#exportMarket = exportMarket
    this.#importMarket = importMarket
    this.#product_id = productId
  }

  /*
  --------------------
  GETTERS AND SETTERS
  --------------------
  */
  getExportMarket() {
    return this.#exportMarket
  }

  setExportMarket(exportMarket) {
    this.#exportMarket = exportMarket
  }

  getImportMarket() {
    return this.#importMarket
  }

  setImportMarket(importMarket) {
    this.#importMarket = importMarket
  }

  getSellerCompanyId() {
    return this.#seller_company_id
  }

  setSellerCompanyId(sellerCompanyId) {
    this.#seller_company_id = sellerCompanyId
  }

  getBuyerCompanyId() {
    return this.#buyer_company_id
  }

  setBuyerCompanyId(buyerCompanyId) {
    this.#buyer_company_id = buyerCompanyId
  }

  getProductId() {
    return this.#product_id
  }

  setProductId(productId) {
    this.#product_id = productId
  }

  getBroker() {
    return this.#broker
  }

  setBroker(broker) {
    this.#broker = broker
  }

  toJSON() {
    return {
      exporting_market: this.#exportMarket,
      importing_market: this.#importMarket,
      seller_company_id: this.#seller_company_id,
      buyer_company_id: this.#buyer_company_id,
      product_id: this.#product_id,
      broker: this.#broker,
    }
  }

  /*
  --------------------
  METHODS
  --------------------
  */

  /**
   * @description This method is used to request the PDF of the customs fee
   * @method
   * @memberof Models - CustomFee
   * @returns {Promise} - The response of the request
   * @example
   * const res = customFee.requestPDF()
   * @since 1.0.2
   * @created 03-26-2024
   * @modified 03-26-2024
   * @author Rafael Rapizo Nery
   */
  async requestPDF() {
    message.loading('Sending request...')
    let res = await axiosRequest(
      '/api/v2/pdf/custom-fee-request',
      this.toJSON(),
      'post',
      true,
    )
    message.destroy()
    return res
  }
  handleResponse(res) {
    if (res.status === 200) {
      message.success(SuccessMessage.customsFee.requestSent())
    }
  }

  handleError(error) {
    console.error(error)
    message.error(ErrorMessage.genericServerError)
  }

  /**
   * @description This method is used to solicit the evaluation of the customs fee
   * @method
   * @memberof Models - CustomFee
   * @returns {Promise} - The response of the request
   * @example
   * const res = customFee.solicitEvaluation()
   * @since 1.0.2
   * @created 03-26-2024
   * @author Rafael Rapizo Nery
   */
  async solicitEvaluation() {
    try {
      let res = await this.requestPDF()
      return this.handleResponse(res)
    } catch (error) {
      return this.handleError(error)
    }
  }
}
