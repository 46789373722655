import { useState } from 'react'
import { Input, Slider } from 'antd'

export const PriceRangeController = ({
  callback = () => null,
  defaultMin = 1,
  defaultMax = 30000,
}) => {
  const [priceRange, setPriceRange] = useState({ min: defaultMin, max: defaultMax })

  const handlePriceRangeInput = (key, value) => {
    if(isNaN(value)) value = 0
    setPriceRange({ ...priceRange, [key]: value })
    callback({...priceRange, [key]: value})
  }

  const handlePriceRangeChange = (min, max) => {
    setPriceRange({ min, max })
    callback({ min, max })
  }


  return (
    <div className="row">
        <div className="col-6">
          <Input
            value={parseFloat(priceRange.min) || null}
            placeholder="$0"
            onChange={(e) => handlePriceRangeInput('min', parseFloat(e.target.value))}
          />
        </div>
        <div className="col-6">
          <Input
            value={priceRange.max || null}
            placeholder="$200"
            onChange={(e) => handlePriceRangeInput('max', parseFloat(e.target.value))}
          />
        </div>
        <div className="col-12 mt-2">
          <Slider
            range
            min={0}
            max={defaultMax || 0}
            value={[priceRange.min || 0, priceRange.max || 0]}
            onChange={(val) => {
              console.log(val)
              handlePriceRangeChange(val[0], val[1])
            }}
          />
        </div>
      </div>

  )
}
