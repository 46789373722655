import { useState } from 'react'

import {
  HeaderIcons,
  HamburgerMenu,
  HeaderLogo,
  NavLinks,
  MobileMenu,
} from './components/'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * @description Header component is used to display the header of the marketplace
 * @param {object} user - The user object
 * @param {boolean} disableMenu - Disable the menu
 * @returns {JSX.Element}
 * @author Rafael Rapizo Nery
 * @since 1.0.0
 * @version 1.0.0
 */
export const Header = ({ user, disableMenu = false }) => {
  const [menu, setMenu] = useState(false)
  const location = useLocation()

  const isSellerShowroom = () => {
    return location.pathname.includes('showroom') || location.pathname.includes('company')
  }

  return (
    <>
      <div
        className={`marketplace-header ${
          disableMenu ? 'marketplace-header__disabled' : 'marketplace-header__enabled'
        } ${isSellerShowroom() && 'marketplace-header--ss'}`}
        data-open={menu ? '1' : '0'}
      >
        <HamburgerMenu setMenu={setMenu} menu={menu} />
        <HeaderLogo
          disableMenu={disableMenu}
          logo={`${process.env.REACT_APP_CDN_URL}/web/${
            isSellerShowroom()
              ? 'common/ttw-logo-primary.svg'
              : 'marketplace/logo-marketplace.png'
          }`}
        />
        <NavLinks disableMenu={disableMenu} />
        <HeaderIcons user={user} disableMenu={disableMenu} />
      </div>
      <MobileMenu menu={menu} setMenu={setMenu} />
    </>
  )
}
