import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, Select, Skeleton, notification } from 'antd'

import COUNTRIES_LIST from '../../../../data/countries'

import { ErrorMessage } from '../../../../models/Messages'

import axiosRequest from '../../../../hooks/useAxiosRequest'
import { ModalCustomsFeesFooter } from './ModalCustomsFeesFooter'

export const ModalCustomsFeesBody = ({
  slug = null,
  supplier,
  onSubmit = () => null,
}) => {
  const [supplierData, setSupplierData] = useState(supplier ? supplier : null)
  const { user } = useSelector((state) => state.user)

  const getDefaultCountryCode = (company) => {
    const companyCountry = company && company.country ? company.country : null
    if (!companyCountry) return null
    try {
      const country = COUNTRIES_LIST.find(
        (c) => c.name.toLowerCase() === companyCountry.toLowerCase(),
      )
      return country.code.toUpperCase()
    } catch (error) {
      return null
    }
  }

  useEffect(() => {
    const fetchData = async (slug) => {
      try {
        const res = await axiosRequest(
          `/api/v1/marketplace/company/${slug}`,
          null,
          'get',
          false,
        )
        if (res.status !== 200) {
          return notification.error({
            message: ErrorMessage.title,
            description: ErrorMessage.genericServerError,
          })
        } else {
          setSupplierData(res.data.company)
        }
      } catch (error) {
        return notification.error({
          message: ErrorMessage.title,
          description: ErrorMessage.genericServerError,
        })
      }
    }
    if (slug) fetchData(slug)
  }, [slug])

  if (!supplierData) return <Skeleton />

  return (
    <>
      <div className="modal-customs-fees__body">
        <div className="modal-customs-fees__body__header text-center ">
          <h5>Customs Import Duties</h5>
          <p>
            If you need to know in advance what import tariffs apply to these goods,
            contact our custom Broker
          </p>
        </div>
        <div className="modal-customs-fees__body__form">
          <Form
            style={{ zIndex: 100000 }}
            layout="vertical"
            initialValues={{
              exportMarket: getDefaultCountryCode(supplierData),
              importMarket: getDefaultCountryCode(user.get_user_company),
              broker: 'ttw_broker',
            }}
            onFinish={(values) => onSubmit(values, supplierData.id, user.id)}
          >
            <Form.Item
              label="Exporting Market"
              colon={false}
              name="exportMarket"
              rules={[
                {
                  required: true,
                  message: 'Please select the exporting market',
                },
              ]}
            >
              <Select placeholder="Country">
                {COUNTRIES_LIST.map((country) => (
                  <Select.Option
                    key={country.code}
                    value={country.code}
                    className="modal-customs-fees__body__form__select-opt"
                  >
                    <img
                      src={`https://flagcdn.com/${country.code.toLowerCase()}.svg`}
                      alt={country.code}
                      width="16"
                    />
                    <span>{country.name}</span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Importing Market"
              colon={false}
              name="importMarket"
              rules={[
                {
                  required: true,
                  message: 'Please select the importing market',
                },
              ]}
            >
              <Select placeholder="Country">
                {COUNTRIES_LIST.map((country) => (
                  <Select.Option
                    key={country.code}
                    value={country.code}
                    className="modal-customs-fees__body__form__select-opt"
                  >
                    <img
                      src={`https://flagcdn.com/${country.code.toLowerCase()}.svg`}
                      alt={country.code}
                      width="16"
                    />
                    <span>{country.name}</span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Send to" colon={false} name="broker">
              <Select>
                <Select.Option value={'ttw_broker'}>TradeToWorld</Select.Option>
                {/* TODO: Map out possible options of brokers for the customs fees request:
                    What is this data? The brokers should be queried according to country of export / import validity.
                      (meaning: the select dropdown should contain all brokers working both in the exporting country and the importing country)
                    What else?
                */}
              </Select>
            </Form.Item>
            <ModalCustomsFeesFooter />
          </Form>
        </div>
      </div>
    </>
  )
}
