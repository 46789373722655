import { useEffect, useState } from 'react'
import { Form, Skeleton, Button, message } from 'antd'

import { Address } from '../../../../../models/Address'

import { SellerAddress, BuyerAddress } from './'



export const AddressForm = ({ seller, buyer, onAddressDataSubmit }) => {
  const [sellerAddress, setSellerAddress] = useState(
    seller ? new Address(seller.country, seller.city, seller.address, seller.zip) : null,
  )
  const [buyerAddress, setBuyerAddress] = useState(
    buyer && buyer.get_user_company
      ? {
          shippingAddress: new Address(
            buyer.get_user_company.country,
            buyer.get_user_company.city,
            buyer.get_user_company.address,
            buyer.get_user_company.zip,
          ),
          billingAddress: new Address(
            buyer.get_user_company.country,
            buyer.get_user_company.city,
            buyer.get_user_company.address,
            buyer.get_user_company.zip,
          ),
        }
      : {shippingAddress: new Address(), billingAddress: new Address()},
  )

  const onFinish = (values) => {
    for (const key of Object.keys(sellerAddress)) {
      if (typeof sellerAddress[key] !== 'boolean' && !sellerAddress[key]) {
        return message.error({
          content: 'Please, provide the full loading address of the seller.',
        })
      } else {
        continue
      }
    }
    for (const key of Object.keys(buyerAddress.billingAddress)) {
      if (
        typeof buyerAddress.billingAddress[key] !== 'boolean' &&
        !buyerAddress.billingAddress[key] && key !== 'billing_registration_number' && key !== 'billing_tax_number'
      ) {
        return message.error({
          content: 'Please, provide the full billing address of the seller.',
        })
      } else {
        continue
      }
    }

    const sellerData = {
      first_name: values.sFirstName,
      last_name: values.sLastName,
      company: values.sCompany,
      email: values.sEmail,
      phone_code: values.sPhoneDigit,
      phone: values.sPhone,
      address: sellerAddress,
    }
    const buyerData = {
      first_name: values.bFirstName,
      last_name: values.bLastName,
      company: values.bCompany,
      email: values.bEmail,
      phone_code: values.bPhoneDigit,
      phone: values.bPhone,
      address: buyerAddress, // why isn't the shipping address getting vals?
    }

    onAddressDataSubmit(sellerData, buyerData)
  }

  const onFinishFailed = (e) => {
    return message.error({ content: 'Please, fill all the required fields.' })
  }

  if (seller && buyer) {
    return (
      <Form
        onFinish={(values) => onFinish(values)}
        onFinishFailed={(values) => onFinishFailed(values)}
        initialValues={{
          sFirstName: seller ? seller.get_user[0].first_name : null,
          sLastName: seller ? seller.get_user[0].last_name : null,
          sCompany: seller ? seller.name : null,
          sEmail: seller ? seller.email : null,
          sPhoneDigit: seller ? seller.get_user[0].phone_code : null,
          sPhone: seller ? seller.get_user[0].phone : null,
          sAddress: seller ? seller.address : null,
          /* ----------------------------------------- */
          bFirstName: buyer ? buyer.first_name : null,
          bLastName: buyer ? buyer.last_name : null,
          bCompany: buyer && buyer.get_user_company? buyer.get_user_company.name : '',
          bEmail: buyer ? buyer.email : null,
          bPhoneDigit: buyer ? buyer.phone_code : null,
          bPhone: buyer ? buyer.phone : null,
          bShippingAddress: buyer && buyer.get_user_company? buyer.get_user_company.address : null,
          bShippingCountry: buyer && buyer.get_user_company? buyer.get_user_company.country : null,
          bShippingCity: buyer && buyer.get_user_company? buyer.get_user_company.city : null,
          bShippingZip: buyer && buyer.get_user_company? buyer.get_user_company.zip : null,
          bShippingSt: buyer && buyer.get_user_company? buyer.get_user_company.address : null,
        }}
      >
        <SellerAddress addressHandler={setSellerAddress} seller={seller} />
        <BuyerAddress addressHandler={setBuyerAddress} buyer={buyer} defaultBillingAddress={buyerAddress.shippingAddress}/>
        <Button type="submit" htmlType="submit" className="ant-btn-primary ant-btn-ttu">
          Continue
        </Button>
      </Form>
    )
  } else {
    return <Skeleton />
  }
}
