/**
 * @author Rafael Rapizo Nery
 *
 * @class
 * @description An order of a specific product, cotaining product data, quantity and total price.
 *
 * @property {Product} product Product details.
 * @property {OrderData} data Product variant data.
 * @property {Number} quantity Selected quantity.
 * @property {Price} selectedPrice Chosen Price object containing currency, discount, available quantity, etc.
 * @property {Number} totalPrice Total price of order.
 */
export class Order {
  product
  data
  quantity
  company
  selectedPrice
  totalPrice

  /**
   * @author Rafael Rapizo Nery
   *
   * @constructor
   *
   * @param {Product} product Product Object.
   * @param {Number} quantity Chosen Quantity.
   * @param {Price} price Selected Price object;
   *
   * @returns {Order} Complete order with Product info spread across keys product & data, and with automated calculation of total price.
   */
  constructor(product, quantity, price) {
    this.product = product.product
    this.data = {
      options: product.options,
      prices: product.product.sales_terms,
    }
    this.quantity = parseInt(quantity)
    this.company = product.company
    this.selectedPrice = price

    console.log({ price })

    this.totalPrice = this.quantity * parseInt(this.selectedPrice.price_value)
  }

  /**
   * @author Rafael Rapizo Nery
   *
   * @function updateTotalPrice
   *
   * @description Internally updates total price based of current quantity and price.
   */
  updateTotalPrice() {
    this.totalPrice = this.quantity * parseFloat(this.selectedPrice.price_value)
  }

  /**
   * @author Rafael Rapizo Nery
   *
   * @function setQuantity
   *
   * @description Update quantity and triggers update of total price.
   *
   * @param {Number} qty Updated Quantity
   */
  setQuantity(qty) {
    this.quantity = qty
    this.updateTotalPrice()
  }
}
