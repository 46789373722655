import { Tabs } from 'antd'

export const TabSystem = ({
  tabs = { key: '', content: '', title: '' },
  currentTab,
  onChange = (key) => key,
  className = '',
  tabClassName = '',
}) => {
  const handleTabChange = (key) => {
    console.log(key)
    onChange(key)
  }

  return (
    <Tabs className={className} activeKey={currentTab} onChange={handleTabChange}>
      {tabs.map((tab) => (
        <Tabs.TabPane
          className={tabClassName}
          tab={tab.title}
          tabKey={tab.title}
          key={tab.key}
        >
          {tab.content}
        </Tabs.TabPane>
      ))}
    </Tabs>
  )
}
