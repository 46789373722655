import { Tag } from '../Tag/Tag'

import './TagList.scss'

const TagList = ({
  className = null,
  tags = [],
  tagClassName = null,
  posfix = null,
  renderTag = (tag) => (
    <Tag key={tag.key} tag={tag} tagClassName={tagClassName} posfix={posfix} />
  ),
}) => {
  return (
    <div className={`tag-list ${className ? className : ''}`}>
      {tags.map((tag) => renderTag(tag))}
    </div>
  )
}

export default TagList
