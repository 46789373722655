import { Data, MainImage, PriceWrapper, PreviewData } from '.'
import { Tags, ListDetails } from '../ListDetails'

export const ProductItem = ({
  product,
  view = 'grid',
  expanded = null,
  setExpanded = (f) => f,
  handleGridToDetail,
  shopOptions = false,
  isSellerShowroom = false,
  disableProductLinks = false,
  isShowAttributes = false,
  isShowDescription = false,
}) => {
  return (
    <div
      className={`${view === 'list' ? 'product-item__list' : 'product-item'} `}
      onClick={() => (view === 'grid' && isSellerShowroom ? handleGridToDetail() : null)}
    >
      <div className={view === 'list' && 'd-flex justify-content-start'}>
        <MainImage
          shopOptions={shopOptions}
          disableProductLinks={disableProductLinks}
          expanded={expanded}
          setExpanded={setExpanded}
          product={product}
        />
        <Data
          disableProductLinks={disableProductLinks}
          expanded={expanded}
          product={product}
          setExpanded={setExpanded}
          shopOptions={shopOptions}
          layout={view}
        />
        <PriceWrapper
          expanded={expanded}
          isSellerShowroom={isSellerShowroom}
          product={product}
          setExpanded={setExpanded}
          shopOptions={shopOptions}
          layout={view}
        />
        {view === 'grid' && <Tags product={product} layout={view} />}
      </div>
      {view === 'list' && (
        <>
          <PreviewData
            expanded={expanded}
            isShowAttributes={isShowAttributes}
            isShowDescription={isShowDescription}
            product={product}
          />
          <ListDetails product={product} expanded={expanded} />
        </>
      )}
    </div>
  )
}
