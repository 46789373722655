/**
 * @description HamburgerMenu component is used to display the hamburger menu of the marketplace header
 * @param {function} setMenu - Set menu state function
 * @param {boolean} menu - Menu state
 * @returns {JSX.Element}
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 * @since 1.0.0
 */
export const HamburgerMenu = ({ setMenu, menu }) => {
  return (
    <div className="header-mobile">
      <button onClick={() => setMenu(!menu)}>
        {!menu ? (
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="64" height="64" fill="#004D40" />
            <path d="M22 34H40V36H22V34Z" fill="white" />
            <path d="M22 27H40V29H22V27Z" fill="white" />
          </svg>
        ) : (
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="64" height="64" fill="#004D40" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M38 26.41L36.59 25L31 30.59L25.41 25L24 26.41L29.59 32L24 37.59L25.41 39L31 33.41L36.59 39L38 37.59L32.41 32L38 26.41Z"
              fill="white"
            />
          </svg>
        )}
      </button>
    </div>
  )
}
