import { useCallback, useEffect, useState } from 'react'
import { Skeleton } from 'antd'


import { useExplore } from '../../../hooks/ui/useExplore'

import { Explore } from './Explore'
import { List } from './components/List'

/**
 * React component representing a list of brands in the marketplace.
 * Fetches and displays brands with logos, providing an exploration experience.
 */
export const Brands = () => {
  const { filtered, isLoading, fetch, search, setAll, redirect, hasMore } = useExplore()
  const [pagination, setPagination] = useState(null)
  const [pageSize, setPageSize] = useState(36)
  const [page, setPage] = useState(1)

  // Dynamic pagination based on screen size based on _explore.scss
  useEffect(() => {
    if (window.innerWidth < 720) {
      setPageSize(8)
    } else if (window.innerWidth > 720 && window.innerWidth < 1440) {
      setPageSize(16)
    } else if (window.innerWidth > 1440 && window.innerWidth < 1920) {
      setPageSize(18)
    } else {
      setPageSize(24)
    }
  }, [])

  /**
   * Callback function to handle fetching brands and updating state.
   * @param {object} res - Response object from the brand fetch request.
   */
  const handleFetchBrands = useCallback(
    (res) => {
      let allBrands = [...res.data.brands.data.map((b) => ({ ...b, icon: b.logo }))]
      let pagination = { ...res.data.brands }

      setAll((prev) => [
        ...new Map([...prev, ...allBrands].map((item) => [item['id'], item])).values(),
      ])
      setPagination(pagination)
    },
    [setAll],
  )

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_MAIN_API_URL}/api/v1/marketplace/brands?page=${page}&perPage=${pageSize}`,
      handleFetchBrands,
    )
  }, [fetch, page, pageSize, handleFetchBrands])

  return (
    <Explore title="Brand" onSearch={(value) => search(value)}>
      {isLoading ? (
        <>
          <Skeleton />
        </>
      ) : (
        <>
          <List
            infinite
            onNext={() => setPage((prev) => prev + 1)}
            hasMore={hasMore(filtered, pagination)}
            content={filtered}
            onClickCard={(item) =>
              redirect(
                {
                  baseURL: '/products',
                  target: 'brand',
                  divider: ',',
                  posfix: `title=${item.name}`,
                },
                [item.id],
              )
            }
            classname={'explore__list--brands'}
          />
        </>
      )}
    </Explore>
  )
}
