import React from 'react'
import { Link } from 'react-router-dom'

import styles from './shop-by-wrapper.module.scss'

const ShopByWrapper = ({
  children,
  title,
  viewAllLink = null,
  viewAllMobileText = 'View All',
}) => {
  return (
    <div className={styles.shopByWrapper}>
      <div className="homepage-container">
        <div className="row">
          <div className="col-12 col-md-3 col-lg-2">
            <h2>{title}</h2>
            {viewAllLink && (
              <div className='view-all-link'>
                <Link to={viewAllLink}>View All</Link>
              </div>
            )}
          </div>
          <div className="col-12 col-md-9 col-lg-10">{children}</div>
          <div className="col-12">
            <div className={styles.viewAllMobile}>
              <Link to={viewAllLink}>{viewAllMobileText}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShopByWrapper
