import React from 'react'

import { Header } from './marketplace' // since the Header has been applied to 'secure' routes, turn it into a general ui component

const AuthLayout = ({ children }) => {
  return (
    <>
      <Header />
      <main className="secured-main">
        <div>{children}</div>
      </main>
    </>
  )
}

export default AuthLayout
