import { useEffect, useState } from 'react'

import { ProductItem } from './ProductItem/index'
import { useDescriptionScaffold } from '../../../../../hooks/useDescriptionScaffold'

const ProductsList = ({
  products,
  view,
  gridClassName = null,
  shopOptions = false,
  isSellerShowroom = false,
  disableProductLinks = false,
  setView,
}) => {
  const {
    expanded,
    setExpanded,
    isShowAttributes,
    isShowDescription,
  } = useDescriptionScaffold(products)

  useEffect(() => {
    if (view === 'list' && products.length >= 7 && !expanded) {
      setView('grid')
    }
  }, [view, products, expanded, setView])

  const isDetailActive = (product, view, products) => {
    if (view === 'list' && expanded === product.id && products.length >= 7) {
      window.scrollTo(0, 300)
      return { order: -1 }
    } else {
      return null
    }
  }

  const handleGridToDetail = () => {
    setView('list')
  }

  return (
    <div className="products__list">
      <div className="row">
        {products && products.length > 0
          ? products.map((product) => {
              return (
                <div
                  key={product.id}
                  style={isDetailActive(product, view, products)}
                  className={
                    view === 'grid'
                      ? gridClassName
                        ? gridClassName
                        : 'col-6 col-md-6 col-lg-3'
                      : 'col-12'
                  }
                >
                  <ProductItem
                    products={products}
                    product={product}
                    view={view}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    handleGridToDetail={handleGridToDetail}
                    shopOptions={shopOptions}
                    isSellerShowroom={isSellerShowroom}
                    disableProductLinks={disableProductLinks}
                    isShowAttributes={isShowAttributes}
                    isShowDescription={isShowDescription}
                  />
                </div>
              )
            })
          : null}
      </div>
    </div>
  )
}

export default ProductsList
