import { Button, Input, message } from 'antd'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useEffect, useState } from 'react'

export const ProductQtyController = ({ selectedPrice, setQty, qty, defaultQty }) => {
  const [isCart, setIsCart] = useState(false)
  const { pathname } = useLocation()

  const handleAdd = (
    crrQty = qty,
    max_qty = selectedPrice.max_qty,
    availableQty = selectedPrice.availableQty,
    callback = setQty,
  ) => {
    if (max_qty && crrQty >= max_qty)
      return message.warning({
        content: "You've reached the maximum quantity allowed per order of this product.",
      })

    if (availableQty !== null && crrQty >= availableQty)
      return message.warning({
        content: 'There are no items in stock to satisfy the specifed quantity.',
      })

    return callback((prev) => prev + 1)
  }

  const handleSubtract = (
    crrQty = qty,
    min_qty = selectedPrice.min_qty,
    callback = setQty,
  ) => {
    if (crrQty <= min_qty)
      return message.warning({
        content: "You've reached the minimum quantity allowed per order of this product.",
      })

    return callback((prev) => prev - 1)
  }

  const validateQty = (
    value,
    min_qty = selectedPrice.min_qty,
    max_qty = selectedPrice.max_qty,
    callback = setQty,
  ) => {
    let callbackValue = value

    if (isNaN(value)) {
      callbackValue = min_qty
    }
    if (value <= min_qty) {
      message.warning({
        content: "You've reached the minimum quantity allowed per order of this product.",
      })
      callbackValue = min_qty
    }
    if (value >= max_qty) {
      message.warning({
        content: "You've reached the maximum quantity allowed per order of this product.",
      })
      callbackValue = max_qty
    }

    return callback(callbackValue)
  }

  useEffect(() => {
    const handleSelectedPriceChange = (qty, callback) => {
      return callback(qty)
    }
    handleSelectedPriceChange(defaultQty, setQty)
  }, [defaultQty, setQty])

  useEffect(() => {
    if (pathname === '/cart') {
      setIsCart(true)
    }
  }, [pathname])

  return (
    <div className="qty">
      <div className="row">
        <div className={`${isCart ? 'col-12' : 'col-5'}`}>
          <div className="qty__wrapper">
            <div className="d-flex justify-content-start align-items-center">
              <div className="wrap">
                <Button
                  disabled={
                    selectedPrice && selectedPrice.min_qty && qty <= selectedPrice.min_qty
                  }
                  onClick={() => handleSubtract()}
                >
                  <MinusOutlined />
                </Button>
              </div>
              <div className="wrap">
                <Input
                  className="text-center"
                  value={qty && !isNaN(qty) ? qty : null}
                  onChange={(e) => setQty(parseInt(e.target.value, 10))}
                  onBlur={(e) => validateQty(e.target.value)}
                />
              </div>
              <div className="wrap">
                <Button
                  disabled={
                    selectedPrice && selectedPrice.max_qty && qty >= selectedPrice.max_qty
                  }
                  onClick={() => handleAdd()}
                >
                  <PlusOutlined />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={`${isCart ? 'col-12' : 'col-7'}`}>
          <div className={`qty-info ${isCart && 'qty-info--cart'}`}>
            {!isCart && (
              <p>
                {selectedPrice && selectedPrice.availableQty
                  ? selectedPrice.availableQty
                  : 0}{' '}
                in Stock
              </p>
            )}
            <p>
              (Min. order{' '}
              {selectedPrice && selectedPrice.min_qty ? selectedPrice.min_qty : 1}{' '}
              {selectedPrice.order_measure})
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
