
export const BodyNotification = ({ order }) => {
  return (
    <div className="cart-popup__body">
      <div className="pb-2 ">
        <div className="row align-items-center ">
          <div className="col-2">
            <img
              className="w-100"
              src={order.product.get_all_images[0].original}
              alt={`${order.product.name}`}
            />
          </div>
          <div className="col-10">
            <div className="row">
              <div className="col-12 mb-2">
                <a
                  className="text-decoration-none"
                  href={`https://tradeto.world/product/${encodeURIComponent(order.product.slug)}`}
                >
                  {order.product.name}
                </a>
              </div>
              <div className="col-3">{order.quantity} items</div>
              <div className="col-9 text-end">
                <strong>$ {order.totalPrice.toFixed(2)}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
