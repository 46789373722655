import { Select } from 'antd'

import './SortControllerDesktop.scss'

/**
 * @name SortControllerDesktop
 * @description Sort controller component for desktop.
 * @param {string} value - The value of the controller.
 * @param {Array} options - The options of the controller.
 * @param {Function} onChange - The function to handle the change event.
 * @returns {JSX.Element} - Rendered component.
 * @author Rafael Rapizo Nery
 */
export const SortControllerDesktop = ({
  options = [{ title: '', callback: () => null, param: '' }],
  onChange = ({ param }) => null,
}) => {
  return (
    <div className="order d-flex">
      <span className="sort-controller-desktop__caption me-2">Sort by:</span>
      <Select
        defaultValue={'Newest'}
        className="sort-controller-desktop__select"
        placeholder="Select an option..."
        onChange={(optionTitle) =>
          onChange(
            optionTitle,
            options.find((option) => option.title === optionTitle).param,
          )
        }
      >
        {options.map((option) => (
          <Select.Option key={option.title} value={option.title}>
            {option.title}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}
