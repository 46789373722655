import { Collapse } from 'antd'

/* import { useState } from 'react' */

import { OrderCard } from '../../../../ui/marketplace/cart'
import checkIcon from '../../../../../assets/marketplace/check-arrow.svg'

import placeholderCompany from '../../../../../assets/placeholder/company logo placeholder.png'
import placeholderProduct from '../../../../../assets/placeholder/TTW NO Product image.png'

export const SummaryBody = ({
  selectedOrders,
  companies,
  parseOrderPerCompanies,
  isLogisticQt,
  crrQt,
}) => {
  /* const [accordionOpenKey, setAccordionOpenKey] = useState(isLogisticQt ? crrQt.seller.id : null) */ // needs work

  const isCompanyInSelectedOrders = (c) => {
    return !!selectedOrders.find((o) => o.company.id === c.id)
  }

  const getTotalItems = (orders) => {
    let totalItems = 0

    for (const o of orders) {
      totalItems += o.quantity
    }

    return totalItems
  }

  return (
    <div className="cart__summary__body">
      {selectedOrders.length >= 1 ? (
        <Collapse
          className="w-100"
          accordion
          bordered={false}
          expandIconPosition="right" // needs work
          /* activeKey={isLogisticQt ? crrQt.seller.id : accordionOpenKey} */ expandIcon={({
            isActive,
          }) =>
            isActive ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17 14L12 9L7 14L17 14Z"
                  fill="#AEAEAE"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 10L12 15L17 10L7 10Z"
                  fill="#AEAEAE"
                />
              </svg>
            )
          }
        >
          {companies.map((c) => {
            if (isCompanyInSelectedOrders(c)) {
              if (isLogisticQt && c.id !== crrQt.seller.id) return null

              return (
                <Collapse.Panel
                  header={
                    <div className="brand-logo">
                      <a
                        href={`/#/company/${c.slug}`}
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                      >
                        <img src={c.logo} alt={c.name} />
                      </a>
                      <p>{c.name}</p>
                      <span>
                        ({getTotalItems(parseOrderPerCompanies(c, selectedOrders))}{' '}
                        {parseOrderPerCompanies(c, selectedOrders).length > 1
                          ? 'items'
                          : 'item'}
                        )
                      </span>
                    </div>
                  }
                  key={c.id}
                >
                  <div className="cart__summary__body__collapse-body">
                    {/* {!isLogisticQt && selectedOrders[0].product.get_packaging <= 0 } */}
                    {!isLogisticQt &&
                      parseOrderPerCompanies(c, selectedOrders).map((o) => (
                        <div className="cart__summary__body__collapse-body__item">
                          {o.product.get_packaging.length >= 1
                            ? o.product.get_packaging.map((p) => {
                                if (
                                  p.key &&
                                  p.get_variants &&
                                  p.get_variants.length > 0
                                ) {
                                  return (
                                    <>
                                      <div className="cart__summary__body__collapse-body__item__info">
                                        <p>Master Packaging:</p>
                                        <span>
                                          <img
                                            src={p.get_variants[0].get_type.icon}
                                            alt=""
                                          />
                                          <span>{p.get_variants[0].get_type.name}</span>
                                        </span>
                                      </div>
                                      <div className="cart__summary__body__collapse-body__item__info">
                                        <p>Weight:</p>
                                        <span>
                                          {p.get_variants[0].get_variant
                                            .innerPackWeightValue
                                            ? p.get_variants[0].get_variant
                                                .innerPackWeightValue
                                            : 'Contact the supplier for further information'}{' '}
                                          {
                                            p.get_variants[0].get_variant
                                              .innerPackWeightType
                                          }
                                        </span>
                                      </div>
                                      <div
                                        className={`cart__summary__body__collapse-body__item__info ${
                                          o.product.get_packaging.length === 0 && 'mt-2'
                                        }`}
                                      >
                                        <p>Volume:</p>
                                        <span>
                                          {p.get_variants[0].get_variant
                                            .innerPackVolumeValue ? (
                                            <>
                                              {
                                                p.get_variants[0].get_variant
                                                  .innerPackDimensionsWidth
                                              }{' '}
                                              x{' '}
                                              {
                                                p.get_variants[0].get_variant
                                                  .innerPackDimensionsHeight
                                              }{' '}
                                              x{' '}
                                              {
                                                p.get_variants[0].get_variant
                                                  .innerPackDimensionsDepth
                                              }{' '}
                                              cm (
                                              {
                                                p.get_variants[0].get_variant
                                                  .innerPackVolumeValue
                                              }{' '}
                                              {
                                                p.get_variants[0].get_variant
                                                  .innerPackVolumeType
                                              }
                                              )
                                            </>
                                          ) : (
                                            'Contact the supplier for further information'
                                          )}
                                        </span>
                                      </div>
                                    </>
                                  )
                                }
                              })
                            : "This product has no packaging information. If you want to continue with this order, please contact the product's supplier for further information."}
                        </div>
                      ))}
                    {isLogisticQt &&
                      selectedOrders.map((o) => {
                        if (o.company.id === c.id && o.company.id === crrQt.seller.id) {
                          return (
                            <OrderCard
                              order={o}
                              key={o.product.id}
                              className={'cart__summary__body__collapse-body__product'}
                            />
                          )
                        } else {
                          return null
                        }
                      })}
                  </div>
                </Collapse.Panel>
              )
            } else {
              return null
            }
          })}
        </Collapse>
      ) : (
        <>
          <img src={checkIcon} alt="Check Order Icon" />
          <p>Please select products to proceed with your request</p>
        </>
      )}
    </div>
  )
}
