import { useCallback, useEffect, useRef, useState } from 'react'
import { Divider } from 'antd'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Truncate from 'react-truncate'
import { PriceWrapper } from './PriceWrapper'

export function Title({
  disableProductLinks,
  shopOptions,
  expanded,
  setExpanded,
  product,
  view = 'grid',
  isSellerShowroom = false,
}) {
  const [titleLimit, setTitleLimit] = useState(43)
  const [truncateLimit, setTruncateLimit] = useState(2)

  const [isOverFlowing, setIsOverFlowing] = useState(false)
  const titleRef = useRef(null)

  const handleViewChange = useCallback(() => {
    if (view === 'grid' && window.innerWidth < 575) {
      setTitleLimit(30)
      setTruncateLimit(2)
    }
    if (view === 'list' && window.innerWidth < 575) {
      setTitleLimit(1000)
      setTruncateLimit(4)
    }
  }, [view])

  useEffect(() => {
    handleViewChange()
  }, [handleViewChange])

  useEffect(() => {
    const isOver = product.name.length >= titleLimit

    if (view === 'list' && window.innerWidth <= 575) {
      setIsOverFlowing(false)
    } else {
      setIsOverFlowing(
        (view === 'list' ? false : isOver) || (view === 'grid' ? isOver : false),
      )
    }
  }, [product.name, titleLimit, view, truncateLimit])

  return (
    <>
      <h2 className="product-card__data__title">
        {disableProductLinks ? (
          <Link
            to={`/product/${encodeURIComponent(product.slug)}`}
            onClick={(e) => {
              if (shopOptions || disableProductLinks) {
                e.preventDefault()
              }

              if (disableProductLinks) {
                if (product.id === expanded) {
                  setExpanded(null)
                } else {
                  setExpanded(product.id)
                }
              }
            }}
          >
            {isOverFlowing ? (
              <Truncate lines={truncateLimit} ellipsis={'...'}>
                {product.name}
              </Truncate>
            ) : (
              <span ref={titleRef}>{product.name}</span>
            )}
          </Link>
        ) : (
          <Link to={`/product/${encodeURIComponent(product.slug)}`}>
            {isOverFlowing ? (
              <Truncate lines={truncateLimit} ellipsis={'...'}>
                {product.name}
              </Truncate>
            ) : (
              <span ref={titleRef}>{product.name}</span>
            )}
          </Link>
        )}
      </h2>
      {!isSellerShowroom && <Divider className="product-card__data__divider" />}
      <PriceWrapper
        product={product}
        isSellerShowroom={false}
        shopOptions={false}
        layout={view}
      />
    </>
  )
}
