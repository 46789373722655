import React from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Menu } from 'antd'

const MainLayoutHeader = () => {
  return (
    <header className="main-layout-header">
      <div className="d-flex justify-content-between align-items-center">
        <div />
        <div className="header-user-menu">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <Link to="/profile/company-profile">Company Profile</Link>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item>
                  <Link
                    to="/users/logout"
                    onClick={(e) => {
                      e.preventDefault()
                      localStorage.removeItem('apiToken')
                      window.location.reload()
                    }}
                  >
                    Logout
                  </Link>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <div className="user-dropdown">
              <i className="fe fe-user" />
            </div>
          </Dropdown>
        </div>
      </div>
    </header>
  )
}

export default MainLayoutHeader
