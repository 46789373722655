import { lazy } from 'react'

const Login = lazy(() => import('../pages/auth/Login/Login'))
const Signup = lazy(() => import('../pages/auth/Signup/Signup'))

export const AUTH_ROUTES = [
  {
    path: '/secured/login',
    Component: Login,
  },
  {
    path: '/secured/register',
    Component: Signup,
  },
]
