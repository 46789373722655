
/**
 * @description Helper class for Company model
 * @class CompanyHelper
 * @export CompanyHelper
 * @author Rafael Rapizo Nery
 * @created 2024-02-06
 */
export class CompanyHelper {
  static hasImageGallery = (company) => {
    return !!company.get_images[0]
  }
}
