import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * @description NavLinks component is used to display the navigation links of the marketplace header
 * @param {boolean} disableMenu - Disable the menu
 * @returns {JSX.Element}
 * @author Rafael Rapizo Nery
 * @since 1.0.0
 * @version 1.0.0
 */
export const NavLinks = ({ disableMenu = false }) => {
  const location = useLocation()

  const getMenuOptions = (slug = null) => {
    return disableMenu ? getSSMenuOptions(slug) : getDefaultMenuOptions()
  }

  const getDefaultMenuOptions = () => {
    return [
      { title: 'Products', path: '/products' },
      { title: 'Categories', path: '/explore/categories' },
      { title: 'Brands', path: '/explore/brands' },
      { title: 'Markets', path: '/explore/countries' },
      { title: 'About', path: 'https://about.tradeto.world' },
    ]
  }

  const getSSMenuOptions = (slug) => {
    return [
      { title: 'Catalogue', path: `/showroom/${encodeURIComponent(slug)}` },
      { title: 'company-profile', path: '' },
      { title: 'business-facts', path: `/company/${encodeURIComponent(slug)}` },
    ]
  }

  const getSlug = () => {
    return location.pathname.split('/').pop()
  }

  return (
    <>
      <div className="header-menu">
        <ul>
          {getMenuOptions(getSlug()).map((option, index) => {
            if (option.path === '')
              return (
                <li>
                  <Link disabled>{option.title}</Link>
                </li>
              )

            if (option.path.includes('http'))
              return (
                <li key={index}>
                  <a href={option.path} rel="noreferrer" target="_blank">
                    {option.title}
                  </a>
                </li>
              )

            return (
              <li key={index}>
                <Link to={option.path}>{option.title}</Link>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}
