export class Query {
  #queryParams = []
  #divider
  #posfix

  constructor(baseURL = '', target = '', divider = ',', posfix = '') {
    this.baseURL = baseURL
    this.target = target

    this.#divider = divider
    this.#posfix = posfix
  }

  buildParams() {
    let params = ''

    this.#queryParams.forEach(
      (p, i) =>
        (params += i === this.#queryParams.length - 1 ? `${p}` : `${p}${this.#divider}`),
    )

    return params
  }

  buildQuery() {
    return `${this.target}=${this.buildParams()}`
  }

  append(param) {
    this.#queryParams.push(param)
    return this
  }

  build() {
    return `${this.baseURL}?${this.target}=${this.buildParams()}&${this.#posfix}`
  }
}
