import { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'
import axios from 'axios'

import { ErrorMessage } from '../../models/Messages'
import { Query } from '../../models/Query'

import { history } from '../..'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * Custom React hook for managing data exploration.
 *
 * @function
 * @version 1.0.0
 * @author Rafael Rapizo Nery
 * @returns {object} An object containing state and functions for data exploration.
 * @property {Array} all - The complete list of data.
 * @property {function} setAll - Function to set the complete list of data.
 * @property {Array} filtered - The filtered list of data.
 * @property {function} setFiltered - Function to set the filtered list of data.
 * @property {boolean} isLoading - Indicates whether data is currently being loaded.
 * @property {function} setIsLoading - Function to set the loading state.
 * @property {function} fetch - Function to fetch data from the API.
 * @property {function} search - Function to filter data based on a search value.
 * @property {function} redirect - Function to redirect to a new URL based on specified parameters and queries.
 */
export const useExplore = () => {
  const [all, setAll] = useState([])
  const [filtered, setFiltered] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  /**
   * Fetches data from the API and handles the response.
   *
   * @function
   * @private
   * @async
   * @param {string} url - The API endpoint URL.
   * @param {function} resCallback - Callback function to handle the API response.
   */
  const fetch = useCallback(async (url, resCallback) => {
    setIsLoading(true)
    try {
      let res = await axios.get(url)

      if (res.status === 200) {
        resCallback(res)
        setIsLoading(false)
      } else {
        throw new Error(`Failed to fetch data. Status: ${res.status}`)
      }
    } catch (error) {
      message.error(ErrorMessage.genericServerError)
      console.error(error)
      setIsLoading(false)
    }
  }, [])

  /**
   * Filters the data based on a search value.
   *
   * @function
   * @private
   * @param {string} value - The search value.
   * @param {Array} target - The target data array to filter.
   * @param {function} callback - Callback function to set the filtered data.
   */
  const search = useCallback(
    (value = '', target = all, callback = setFiltered) => {
      let filtered = target.filter((v) =>
        v.name.toLowerCase().includes(value.toLowerCase()),
      )
      callback(filtered)
    },
    [all, setFiltered],
  )

  /**
   * Redirects to a new URL based on specified parameters and queries.
   *
   * @function
   * @private
   * @param {object} options - Options for building the URL.
   * @param {Array} queries - An array of queries to append to the URL.
   */
  const redirect = useCallback(
    (
      { baseURL, target, divider = ',', posfix = '&page=1' },
      queries = [],
      load = null,
    ) => {
      let query = new Query(baseURL, target, divider, posfix)
      queries.forEach((q) => query.append(q))
      return history.push(query.build(), [load])
    },
    [],
  )

  /**
   * Check if there is more content to be loaded. Filtered must be an array with all the same data as all, and its length must be less than pagination.total.
   * @param {Array} filtered - The filtered list of data.
   * @param {object} pagination - The pagination object.
   * @returns {boolean} Whether there is more content to be loaded.
   */
  const hasMore = useCallback(
    (filtered, pagination) => {
      if (filtered.length === all.length) {
        return filtered.length < pagination.total
      } else {
        return false
      }
    },
    [all],
  )

  useEffect(() => setFiltered(all), [all])

  return {
    all,
    setAll,
    filtered,
    setFiltered,
    isLoading,
    setIsLoading,
    fetch,
    search,
    redirect,
    hasMore,
  }
}
