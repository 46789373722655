import { FaLinkedin } from 'react-icons/fa'

export const Logo = () => {
  return (
    <div className="col-12 d-none d-md-flex  flex-column justify-content-between ">
      <img
        className="w-100 marketplace-footer__logo"
        src={`${process.env.REACT_APP_CDN_URL}/web/marketplace/logo-marketplace.png`}
        alt="TTW's Logo"
      />
      <div className="marketplace-footer__linkedin-desktop">
        <div>
          <a
            href="https://www.linkedin.com/company/tradetoworld/"
            target="_blank"
            rel="noreferrer"
            className="m-0 text-nowrap"
          >
            <FaLinkedin />
            Follow us on Linkedin
          </a>
        </div>
      </div>
    </div>
  )
}
