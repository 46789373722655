import ProductDetailsMarketplacePage from '../pages/marketplace/products/ProductDetails'
import Catalogue from '../pages/marketplace/Catalogue/Catalogue'

import SellerShowroom from '../pages/marketplace/SellerShowroom/SellerShowroom'
import SellerShop from '../pages/marketplace/SellerShop'
import MarketplaceHome from '../pages/marketplace/Home/Home'
import SearchPage from '../pages/marketplace/SearchPage'

import { Cart } from '../pages/Cart/Cart'
import { Categories } from '../pages/marketplace/explore/Categories'
import { Countries } from '../pages/marketplace/explore/Countries'
import { Brands } from '../pages/marketplace/explore/Brands'
import { lazy } from 'react'


import CompanyFactsPage from '../pages/marketplace/company/CompanyFacts'
const CompanyFacts = lazy(() => import('../pages/marketplace/SellerShowroom/CompanyFacts/CompanyFacts'))

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: '/',
    Component: MarketplaceHome,
  },
  {
    path: '/products',
    Component: Catalogue,
  },
  {
    path: '/search',
    Component: SearchPage,
  },
  {
    path: '/company/:slug',
    Component: CompanyFacts,
  },
  {
    path: '/showroom/:slug',
    Component: SellerShowroom,
  },
  {
    path: '/shop/:slug',
    Component: SellerShop,
  },
  {
    path: '/explore/categories',
    Component: Categories,
  },
  {
    path: '/explore/countries',
    Component: Countries,
  },
  {
    path: '/explore/brands',
    Component: Brands,
  },
  {
    path: '/product/:slug',
    Component: ProductDetailsMarketplacePage,
  },
  {
    path: '/cart',
    Component: Cart,
  },
]
