import { Checkbox } from 'antd'

export const ProductsHeader = ({
  isSelectedAll,
  onRemoveAll,
  onSelectAll,
  orders,
  selectedOrders,
  onDeleteSelected,
}) => {
  return (
    <div className="cart__products__header">
      <Checkbox
        checked={isSelectedAll()}
        onClick={isSelectedAll() ? () => onRemoveAll() : () => onSelectAll()}
      >
        Select all ({orders.length})
      </Checkbox>
      {selectedOrders.length >= 1 && (
        <div
          onClick={onDeleteSelected}
          className="d-inline cart__products__header__delete-controller"
        >
          <span className="">
            <i className="fe fe-trash"></i>
            Delete selected
          </span>
        </div>
      )}
    </div>
  )
}
