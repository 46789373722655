import React from 'react'
import { Rate } from 'antd'

import { SupplierInfo } from './SupplierInfo'
import { ExpandController } from './ExpandController'
import { Title } from './Title'
import ProductAttributes from '../../../../pages/marketplace/product-details/ProductAttributes'

export const Data = ({
  shopOptions,
  disableProductLinks,
  expanded,
  setExpanded,
  product,
  isSellerShowroom = false,
  layout = 'list',
  handleChangeAttribute = () => null,
}) => {
  /* const Wrapper =
    layout === 'list'
      ? React.createElement('div', { className: 'product-data' })
      : React.Fragment */

  return (
    <>
      <div className="product-card__data">
        <SupplierInfo product={product} isSellerShowroom={isSellerShowroom} />
        <Title
          disableProductLinks={disableProductLinks}
          shopOptions={shopOptions}
          expanded={expanded}
          setExpanded={setExpanded}
          product={product}
          view={layout}
          isSellerShowroom={isSellerShowroom}
        />
        {layout === 'list' ? (
          <>
            <ProductAttributes
              product={product}
              options
              preventLinks
              handleChangeProductVariant={handleChangeAttribute}
              className="product-card__attributes"
            />
            <ExpandController
              product={product}
              expanded={expanded}
              setExpanded={setExpanded}
            />
          </>
        ) : null}
      </div>
    </>
  )
}
