export const TableDetails = ({ product }) => {
  return (
    <table className="product-details__table">
      <tbody>
        <tr>
          <td>Category</td>
          <td>{product.get_sub_industries.map(s => s.name).join(', ') || '--'}</td>
        </tr>
        {product && product.product && product.product.product_data ? (
          <>
            {product.product_data.dimension_width && (
              <tr>
                <td>Width</td>
                {product.product_data.dimension_width &&
                product.product_data.dimension_width !== 0 &&
                product.product_data.dimension_width !== '0' ? (
                  <td>
                    {product.product_data.dimension_width}{' '}
                    {product.product_data.dimension_measure}
                  </td>
                ) : (
                  <td>-</td>
                )}
              </tr>
            )}
            {product.product_data.dimension_height && (
              <tr>
                <td>Height</td>
                {product.product_data.dimension_height &&
                product.product_data.dimension_height !== 0 &&
                product.product_data.dimension_height !== '0' ? (
                  <td>
                    {product.product_data.dimension_height}{' '}
                    {product.product_data.dimension_measure}
                  </td>
                ) : (
                  <td>-</td>
                )}
              </tr>
            )}
            {product.product_data.dimension_depth && (
              <tr>
                <td>Depth</td>
                {product.product_data.dimension_depth &&
                product.product_data.dimension_depth !== 0 &&
                product.product_data.dimension_depth !== '0' ? (
                  <td>
                    {product.product_data.dimension_depth}{' '}
                    {product.product_data.dimension_measure}
                  </td>
                ) : (
                  <td>-</td>
                )}
              </tr>
            )}
            {product.product_data.weight_value && (
              <tr>
                <td>Weight</td>
                {product.product_data.weight_value &&
                product.product_data.weight_value !== 0 &&
                product.product_data.weight_value !== '0' ? (
                  <td>
                    {product.product_data.weight_value}{' '}
                    {product.product_data.weight_measure}
                  </td>
                ) : (
                  <td>-</td>
                )}
              </tr>
            )}
            {product.get_product_attributes && product.get_product_attributes.length > 0
              ? product.get_product_attributes.map((attr) => (
                  <tr key={attr.id}>
                    <td>{attr.get_attribute_details.name}</td>
                    <td>{attr.value}</td>
                  </tr>
                ))
              : null}
            {product.product_data.product_have_barcode && (
              <tr>
                <td>Barcode</td>
                <td>
                  {product.product_data.barcode_type} {product.product_data.barcode}
                </td>
              </tr>
            )}
            {product.product_data.usp && (
              <tr>
                <td>USP</td>
                <td>{product.product_data.usp}</td>
              </tr>
            )}
          </>
        ) : null}
      </tbody>
    </table>
  )
}
