const SidebarMenu = [
  {
    category: false,
    name: 'Dashboard',
    key: 'dashboard',
    icon: 'fe fe-grid',
    url: '/dashboard',
    replaceUrl: false,
    adminOnly: false,
  },
  {
    category: false,
    name: 'Marketplace',
    key: 'marketplace',
    icon: 'fe fe-globe',
    url: '/marketplace/products',
    replaceUrl: false,
    adminOnly: true,
  },
  {
    category: false,
    name: 'Products',
    key: 'products',
    icon: 'fe fe-box',
    url: '/products-parent',
    replaceUrl: false,
    adminOnly: false,
    childs: [
      {
        category: false,
        name: 'Products Catalog (Listing)',
        key: 'products_list_page',
        icon: 'fe fe-box',
        url: '/products',
        replaceUrl: false,
      },
    ],
  },
  {
    category: false,
    name: 'My Profile',
    key: 'my-profile',
    icon: 'fe fe-user',
    url: '/profile',
    replaceUrl: false,
    adminOnly: false,
    childs: [
      {
        category: false,
        name: 'Company Profile',
        key: 'company-profile',
        icon: 'fe fe-box',
        url: '/profile/company-profile',
      },
      // {
      //   category: false,
      //   name: 'Brands',
      //   key: 'brands',
      //   icon: 'fe fe-box',
      //   url: '/profile/brands',
      //   replaceUrl: false,
      // },
    ],
  },
]

export default SidebarMenu
