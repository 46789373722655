import React from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import PerfectScrollbar from 'react-perfect-scrollbar'

import SidebarMenu from '../../data/SidebarMenu'

const MainSidebar = ({ user }) => {
  const generateMenu = () => {
    return SidebarMenu.map((item) => {
      if (item.adminOnly && user.user.is_admin !== 3) {
        return null
      }
      if (item.category) {
        return <Menu.ItemGroup key={Math.random()} title={item.title} />
      }
      if (item.childs && item.childs[0]) {
        return (
          <Menu.SubMenu
            title={item.name}
            icon={<span className={`${item.icon} icon-collapsed-hidden`} />}
            key={item.key}
          >
            {item.childs && (
              <>
                {item.childs.map((subMenu) => {
                  return (
                    <Menu.Item key={subMenu.key}>
                      <Link to={subMenu.url}>
                        <span>{subMenu.name}</span>
                      </Link>
                    </Menu.Item>
                  )
                })}
                {item.key === 'my-profile' &&
                user.user &&
                user.user.get_user_company &&
                user.user.get_user_company.id ? (
                  <>
                    <Menu.Item>
                      <Link
                        to={`/marketplace/showroom/${user.user.get_user_company.slug}`}
                      >
                        <span>Seller Showroom</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        to={`/marketplace/company/${user.user.get_user_company.slug}`}
                      >
                        <span>Company Facts</span>
                      </Link>
                    </Menu.Item>
                  </>
                ) : null}
              </>
            )}
          </Menu.SubMenu>
        )
      }
      return (
        <Menu.Item key={item.key}>
          <Link to={item.url}>
            <span className={`${item.icon} icon-collapsed-hidden`} />
            <span>{item.name}</span>
          </Link>
        </Menu.Item>
      )
    })
  }

  return (
    <div className="ttw-sidebar">
      <div className="sidebar__logo">
        <img src="https://d17jozkkv5mv8m.cloudfront.net/web/common/ttw-logo.png" alt="TTW" />
      </div>
      <PerfectScrollbar>
        <Menu mode="inline" inlineIndent="15" className="sidebar__menu">
          {generateMenu()}
        </Menu>
      </PerfectScrollbar>
    </div>
  )
}

export default MainSidebar
