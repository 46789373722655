import { useState } from 'react'
import { Carousel, Image } from 'antd'

import MarketplaceCard from '../../../ui/cards/marketplace/MarketplaceCard'
import PicturePreview from '../../../ui/modals/PicturePreview/PicturePreview'

import styles from './carousel.module.scss'

const PhotoGallery = ({ data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)

  return (
    <>
      <PicturePreview
        isVisible={isModalVisible}
        handleVisibility={setIsModalVisible}
        afterChange={setCurrentImage}
        modalInitialSlide={currentImage}
        adaptativeHeight={true}
        images={data.map((image) => ({ id: image.id, original: image.image }))}
      />
      <MarketplaceCard title="Gallery">
        <div className={`no-padding-bottom ${styles.carousel}`}>
          <Carousel
            dots={false}
            arrows
            draggable
            touchMove
            infinite={false}
            slidesToScroll={1}
            slidesToShow={6}
            responsive={[
              {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 6,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                },
              },
            ]}
            nextArrow={
              <div>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    opacity="0.6"
                    cx="15"
                    cy="15"
                    r="14.5"
                    fill="white"
                    stroke="#EDEDED"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.3334 10L12.1584 11.175L15.975 15L12.1584 18.825L13.3334 20L18.3334 15L13.3334 10Z"
                    fill="#808080"
                  />
                </svg>
              </div>
            }
            prevArrow={
              <div>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    opacity="0.6"
                    cx="15"
                    cy="15"
                    r="14.5"
                    transform="rotate(-180 15 15)"
                    fill="white"
                    stroke="#EDEDED"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.6666 20L17.8416 18.825L14.025 15L17.8416 11.175L16.6666 10L11.6666 15L16.6666 20Z"
                    fill="#808080"
                  />
                </svg>
              </div>
            }
            afterChange={(current) => setCurrentImage(current)}
          >
            {data.map((image) => (
              <div key={image.id} className={styles.item}>
                <div className={styles.itemWrapper}>
                  <Image
                  wrapperClassName={styles.antImage}
                    src={image.image}
                    alt="Image"
                    preview={false}
                    onClick={() => setIsModalVisible(!isModalVisible)}
                    className={styles.image}
                  />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </MarketplaceCard>
    </>
  )
}

export default PhotoGallery
