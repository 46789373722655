import { useState } from 'react'
import { Form, Input, Select, Radio } from 'antd'

import PHONE_CODES from '../../../../../data/phone-codes'
import COUNTRIES_LIST from '../../../../../data/countries'

import { Address } from '../../../../../models/Address'

export const BuyerAddress = ({ buyer, addressHandler, defaultBillingAddress }) => {
  const [isBillingAddressDefault, setIsBillingAddressDefault] = useState(true)
  const [address, setBillingAddress] = useState(defaultBillingAddress)

  const handleSetAddress = (value, field, key = 'billingAddress') => {
    const addressUpdt = { ...address }

    addressUpdt[field] = value

    setBillingAddress(addressUpdt)

    if (key === 'all')
      addressHandler((prev) => ({
        shippingAddress: addressUpdt,
        billingAddress: addressUpdt,
      }))
    else addressHandler((prev) => ({ ...prev, [key]: addressUpdt }))
  }

  const handleIsBillingAddressDefault = (addressRef, isDefault = true) => {
    if (isDefault) {
      setIsBillingAddressDefault(true)
    } else {
      setIsBillingAddressDefault(false)
    }
    setBillingAddress(addressRef)
  }

  return (
    <div className="logistic_quote__buyer">
      <div className="row">
        <div className="col-12">
          <p>Buyer Information</p>
        </div>
        <div className="col-6">
          <Form.Item
            name={'bCompany'}
            rules={[{ required: true, message: 'The field is required.' }]}
          >
            <Input type="text" placeholder="Buyer's Company*" />
          </Form.Item>
        </div>
        <div className="col-6"></div>
        <div className="col-6">
          <Form.Item
            name={'bFirstName'}
            rules={[{ required: true, message: 'The field is required.' }]}
          >
            <Input type="text" placeholder="First Name*" />
          </Form.Item>
        </div>
        <div className="col-6">
          <Form.Item
            name={'bLastName'}
            rules={[{ required: true, message: 'The field is required.' }]}
          >
            <Input type="text" placeholder="Last Name*" />
          </Form.Item>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col-4">
              <Form.Item name={'bPhoneDigit'}>
                <Select className="w-100" showSearch>
                  {PHONE_CODES.map((pc) => (
                    <Select.Option key={pc.code} value={pc.dial_code}>
                      <img
                        src={`https://flagcdn.com/${pc.code.toLowerCase()}.svg`}
                        alt={pc.code}
                        width="16"
                      />
                      <span className="ps-2">{pc.dial_code}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-8">
              <Form.Item
                name={'bPhone'}
                rules={[{ required: true, message: 'The field is required.' }]}
              >
                <Input type="text" placeholder="Phone Number*" />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="col-6">
          <Form.Item
            name={'bEmail'}
            rules={[{ required: true, message: 'The field is required.' }]}
          >
            <Input type="email" placeholder="Email*" />
          </Form.Item>
        </div>
        <div className="col-12 mb-3">
          <p>Shipping Address</p>
          <div className="row">
            <div className="col-6">
              <Select
                className="w-100"
                defaultValue={
                  buyer.get_user_company
                    ? buyer.get_user_company.name
                    : 'Please, register a company for autocomplete.'
                }
              >
                <Select.Option
                  value={
                    buyer.get_user_company
                      ? buyer.get_user_company.name
                      : 'Please, register a company for autocomplete.'
                  }
                >
                  {buyer.get_user_company
                    ? buyer.get_user_company.name
                    : 'Please, register a company for autocomplete.'}
                </Select.Option>
              </Select>
            </div>
            <div className="col-6">
              <Form.Item
                name={'bShippingCountry'}
                rules={[{ required: true, message: 'The field is required.' }]}
              >
                <Input
                  type="text"
                  placeholder="Country, Region*"
                  onChange={(e) =>
                    handleSetAddress(
                      e.target.value,
                      'country',
                      isBillingAddressDefault ? 'all' : 'shippingAddress',
                    )
                  }
                />
              </Form.Item>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <Form.Item
                    name={'bShippingCity'}
                    rules={[{ required: true, message: 'The field is required.' }]}
                  >
                    <Input
                      type="text"
                      placeholder="City*"
                      onChange={(e) =>
                        handleSetAddress(
                          e.target.value,
                          'city',
                          isBillingAddressDefault ? 'all' : 'shippingAddress',
                        )
                      }
                    />
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Form.Item name={'bShippingZip'}>
                    <Input
                      type="text"
                      placeholder="Zip code"
                      onChange={(e) =>
                        handleSetAddress(
                          e.target.value,
                          'zip',
                          isBillingAddressDefault ? 'all' : 'shippingAddress',
                        )
                      }
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="col-6">
              <Form.Item
                name={'bShippingSt'}
                rules={[{ required: true, message: 'The field is required.' }]}
              >
                <Input
                  type="text"
                  placeholder="Street*"
                  onChange={(e) =>
                    handleSetAddress(
                      e.target.value,
                      'street',
                      isBillingAddressDefault ? 'all' : 'shippingAddress',
                    )
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="col-6">
            <p>Billing Address</p>
            <div className="logistic_quote__seller__address">
              <Radio.Group name="bAddress" defaultValue={1}>
                <Radio
                  name={'bAddress'}
                  value={1}
                  checked={isBillingAddressDefault}
                  onClick={() => handleIsBillingAddressDefault(defaultBillingAddress)}
                >
                  Same as shipping address
                </Radio>
                <Radio
                  name={'bAddress'}
                  value={2}
                  checked={!isBillingAddressDefault}
                  onClick={() => handleIsBillingAddressDefault(new Address(), false)}
                >
                  Different than shipping address
                </Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
        {!isBillingAddressDefault && (
          <>
            <div className="col-lg-6">
              <Form.Item>
                <Input
                  type="text"
                  placeholder="Company*"
                  onChange={(e) => handleSetAddress(e.target.value, 'billing_company')}
                />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item>
                <Input
                  type="text"
                  placeholder="Registration Number"
                  onChange={(e) =>
                    handleSetAddress(e.target.value, 'billing_registration_number')
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-6"></div>
            <div className="col-lg-6">
              <Form.Item>
                <Input
                  type="text"
                  placeholder="Sales Tax Number"
                  onChange={(e) => handleSetAddress(e.target.value, 'billing_tax_number')}
                />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item>
                <Select
                  placeholder="Country*"
                  showSearch
                  onChange={(e) => handleSetAddress(e, 'country')}
                >
                  {COUNTRIES_LIST.map((c) => (
                    <Select.Option value={c.name} key={c.code}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item>
                <Input
                  type="text"
                  placeholder="Zip code"
                  onChange={(e) => handleSetAddress(e.target.value, 'zip')}
                />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item rules={[{ required: true, message: 'The field is required.' }]}>
                <Input
                  type="text"
                  placeholder="City*"
                  onChange={(e) => handleSetAddress(e.target.value, 'city')}
                />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item rules={[{ required: true, message: 'The field is required.' }]}>
                <Input
                  type="text"
                  placeholder="Street*"
                  onChange={(e) => handleSetAddress(e.target.value, 'street')}
                />
              </Form.Item>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
