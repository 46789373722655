export const usePrices = (orders) => {
  const getPrice = () => {
    let total = 0.0

    for (const o of orders) {
      total += o.totalPrice
    }

    return total
  }

  const getDiscount = () => {
    let total = 0.0

    for (const o of orders) {
      total += parseFloat(o.selectedPrice.discount_percentage) * o.quantity
    }

    return total
  }

  const getOriginalPrice = () => {
    return getPrice() + getDiscount()
  }

  return { total: getPrice(), discount: getDiscount(), original: getOriginalPrice() }
}
