import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { Carousel, Skeleton } from 'antd'

import { history } from '../../..'

import axiosRequest from '../../../hooks/useAxiosRequest'
import {
  fetchAvailableCountries,
  orderByProducts,
  redirectProductDetails,
} from '../../../util/functions'
import { limit } from '../../../util/functions/limit'

import PromotionBanner from '../../../components/pages/marketplace/home/PromotionBanner'
import ProductsWrapper from '../../../components/pages/marketplace/home/ProductsWrapper'
import ShopByWrapper from '../../../components/pages/marketplace/home/ShopByWrapper'
import BeAPartOfTTW from '../../../components/pages/marketplace/home/BeAPartOfTTW'
import LatestSuppliers from '../../../components/pages/marketplace/home/suppliers/LatestSuppliers'
// New components import
import { Hero } from './components/Hero'
import { Card } from '../explore/components/Card'
import { ProductCard } from '../../../components/ui/cards/ProductCard/ProductCard'

import styles from './home.module.scss'

import susBg from '../../../assets/marketplace-sustainability.jpg'
import susIcon from '../../../assets/marketplace-sus-icon.svg'
import news1 from '../../../assets/news-1.png'
import news2 from '../../../assets/news-2.png'
import news3 from '../../../assets/news-3.png'
import hm1 from '../../../assets/home-feature-1.png'
import hm2 from '../../../assets/home-feature-2.png'
import hm3 from '../../../assets/home-feature-3.png'
import hm4 from '../../../assets/home-feature-4.png'
import axios from 'axios'

// TODO: Implement Our Trade Services section

const MarketplaceHome = () => {
  const { user } = useSelector((state) => state)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [specialProducts, setSpecialProducts] = useState([])
  const [industries, setIndustries] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [filters, setFilters] = useState({})
  const [countries, setCountries] = useState([])

  const featureCarouselRef = useRef(null)
  const specialCarouselRef = useRef(null)

  const handleFeaturedCarouselChange = useCallback((direction) => {
    if (direction === 'next') {
      featureCarouselRef.current.next()
    } else {
      featureCarouselRef.current.prev()
    }
  }, [])

  const handleSpecialCarouselChange = useCallback((direction) => {
    if (direction === 'next') {
      specialCarouselRef.current.next()
    } else {
      specialCarouselRef.current.prev()
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    getProducts()
    getSpecialPriceProducts()
    homePageIndustries()
    getCountries()
  }, [filters, page])

  const getProducts = () => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTS_API_URL}/special-products/new-arrivals`)
      .then((res) => {
        if (res.status === 200) {
          let products = res.data.map((product) => ({
            ...product,
            slug: encodeURIComponent(product.slug),
            get_main_image: product.product_images[0],
          }))
          setProducts(products)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getSpecialPriceProducts = () => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTS_API_URL}/special-products/special-prices`)
      .then((res) => {
        if (res.status === 200) {
          let products = res.data.map((product) => ({
            ...product,
            slug: encodeURIComponent(product.slug),
            get_main_image: product.product_images[0],
          }))

          setSpecialProducts(products)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const homePageIndustries = () => {
    axiosRequest(
      '/api/v1/marketplace/industry-categories?available=true',
      null,
      'get',
      true,
    )
      .then((res) => {
        if (res && res.data.success) {
          setIndustries(res.data.industries)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const getCountries = async () => {
    const countries = await fetchAvailableCountries()
    setCountries(countries)
    setLoading(false)
  }

  return (
    <>
      <Helmet title="TradeToWorld" />
      <Hero />
      {!user.authorized && <PromotionBanner />}
      <ProductsWrapper
        title="New Arrivals"
        viewAllLink="/products"
        viewAllMobileText="View all products"
        stylesObj={{ paddingBottom: 0 }}
        handleCarouselChange={handleFeaturedCarouselChange}
      >
        <div className="products-list mx-md-2">
          <div className="products" style={{ flexBasis: '100%', marginTop: 0 }}>
            {loading || products.length === 0 ? (
              <Skeleton active />
            ) : (
              <>
                <Carousel
                  dots={false}
                  className="products__list products__list--carousel row"
                  slidesToShow={window.innerWidth >= 1024 ? 4 : 3}
                  slidesToScroll={1}
                  touchMove={false}
                  ref={featureCarouselRef}
                >
                  {products.map((product, index) => {
                    return (
                      <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                        <ProductCard
                          product={product}
                          view="grid"
                          className="product-card--home"
                          onClick={() => redirectProductDetails(product.slug)}
                        />
                      </div>
                    )
                  })}
                </Carousel>
                <div
                  className="products__list products__list--default row"
                  style={{ marginTop: 0 }}
                >
                  {limit(products, 4).map((product, index) => {
                    return (
                      <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                        <ProductCard
                          product={product}
                          view="grid"
                          className="product-card--home"
                          onClick={() => redirectProductDetails(product.slug)}
                        />
                      </div>
                    )
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </ProductsWrapper>
      <ProductsWrapper
        title="Special Price"
        viewAllLink="/products"
        viewAllMobileText="View all products"
        stylesObj={{ paddingBottom: 0 }}
        handleCarouselChange={handleSpecialCarouselChange}
      >
        <div className="products-list mx-md-2">
          <div className="products" style={{ flexBasis: '100%', marginTop: 0 }}>
            {loading || products.length === 0 ? (
              <Skeleton active />
            ) : (
              <>
                <Carousel
                  dots={false}
                  className="products__list products__list--carousel row"
                  slidesToShow={window.innerWidth >= 1024 ? 4 : 3}
                  slidesToScroll={1}
                  touchMove={false}
                  ref={specialCarouselRef}
                >
                  {specialProducts.map((product, index) => {
                    return (
                      <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                        <ProductCard
                          product={product}
                          view="grid"
                          className="product-card--home"
                          onClick={() => redirectProductDetails(product.slug)}
                        />
                      </div>
                    )
                  })}
                </Carousel>
                <div
                  className="products__list products__list--default row"
                  style={{ marginTop: 0 }}
                >
                  {limit(specialProducts, 4).map((product, index) => {
                    return (
                      <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                        <ProductCard
                          product={product}
                          view="grid"
                          className="product-card--home"
                          onClick={() => redirectProductDetails(product.slug)}
                        />
                      </div>
                    )
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </ProductsWrapper>
      <ShopByWrapper
        title="Shop By Category"
        viewAllLink="/explore/categories"
        viewAllMobileText="View all categories"
      >
        {loading || Object.keys(industries).length === 0 ? (
          <Skeleton active />
        ) : (
          <div className={styles.industries}>
            <div className="row gap-2 gap-md-3 gap-lg-4 mb-3">
              {limit(Object.keys(industries), window.innerWidth >= 1024 ? 5 : 6).map(
                (key, index) => {
                  return (
                    <Card
                      className="col-6 col-md-4 col-lg-2"
                      title={industries[key].name}
                      icon={industries[key].icon}
                      iconRounded
                      onClick={() =>
                        history.push(
                          `/products?category=${industries[key].get_sub_industries
                            .map((subInd) => subInd.id)
                            .join(',')}&page=1`,
                        )
                      }
                    />
                  )
                },
              )}
            </div>
          </div>
        )}
      </ShopByWrapper>
      <ShopByWrapper
        title="Shop By Country Origin"
        viewAllLink="/explore/countries"
        viewAllMobileText="View all countries"
      >
        <div className={`${styles.industries} ${styles.countries}`}>
          <div className="row gap-2 gap-md-3 gap-lg-4 mb-3">
            {limit(
              orderByProducts(countries),
              window.innerWidth >= 1024 ? 5 : window.innerWidth >= 720 ? 3 : 4,
            ).map((country) => {
              return (
                <Card
                  className="col-6 col-md-4 col-lg-3"
                  title={country.country}
                  icon={`${process.env.REACT_APP_CDN_URL}/icons/countries/${country.country}.svg`}
                  description={`${country.products} products`}
                  onClick={() =>
                    history.push(`/products?country=${country.country}&page=1`)
                  }
                />
              )
            })}
          </div>
        </div>
      </ShopByWrapper>
      <div className={styles.cilurarModel} style={{ backgroundImage: `url(${susBg})` }}>
        <div className="homepage-container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <h3>TradeToWorld Circular Model</h3>
              <p>
                The marketplace is willing to adopt a wide approach in the matter of
                social responsibility, being a gateway of multiple initiatives and
                stakeholders that can contribute either to the promotion of sustainable
                trade principles or helping tangibly companies to improve their ESG
                profile.
              </p>
              <p>
                At TradeToWorld, we believe that a value-based trade among fair and
                ethical players leads to a better world.
              </p>
              <p>
                <Link to="/">Learn more</Link>
              </p>
            </div>
            <div className="col-12 col-md-6 order-first order-md-0 text-center">
              <img src={susIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
      <ProductsWrapper hideViewAll hideCarouselControl title="Media">
        <div className={styles.news}>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-7 mb-4 mb-lg-0">
              <iframe
                src="https://www.youtube.com/embed/QzkLCG9eZkw"
                title="Welcome to TradeToWorld!"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-lg-5 d-none d-lg-flex row">
              <div className="col-12 mb-4 mb-lg-0">
                <div className={styles.newsItem}>
                  <div className={styles.logo}>
                    <img src={news1} alt="" />
                    {window.innerWidth < 720 && (
                      <h3>
                        <a
                          href="https://www.youtube.com/@TradeToWorld/videos"
                          target="_blank"
                          rel="noreferrer noopener nofollow"
                        >
                          Watch out our videos on Youtube
                        </a>
                      </h3>
                    )}
                  </div>
                  <div>
                    {window.innerWidth >= 720 && (
                      <h3>
                        <a
                          href="https://tradeto.world/features"
                          target="_blank"
                          rel="noreferrer noopener nofollow"
                        >
                          Watch out our videos on Youtube
                        </a>
                      </h3>
                    )}
                    <p>
                      You want to understand in a few minutes the corefeatures of our
                      marketplace and understand why it is a revolutionary innovation in
                      the online B2B trade?
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-4 mb-lg-0">
                <div className={styles.newsItem}>
                  <div className={styles.logo}>
                    <img src={news2} alt="" />
                    {window.innerWidth < 720 && (
                      <h3>
                        <a
                          href="https://www.kingsleyborello.com/2022/08/15/the-power-of-fair-pricing/"
                          target="_blank"
                          rel="noreferrer noopener nofollow"
                        >
                          Promoting Fair Pricing
                        </a>
                      </h3>
                    )}
                  </div>
                  <div>
                    {window.innerWidth >= 720 && (
                      <h3>
                        <a
                          href="https://www.kingsleyborello.com/2022/08/15/the-power-of-fair-pricing/"
                          target="_blank"
                          rel="noreferrer noopener nofollow"
                        >
                          Promoting Fair Pricing
                        </a>
                      </h3>
                    )}
                    <p>
                      TTW is intimately engaged in changing the rules of the game in
                      international trade: register and start acting with full ethical
                      behavior, adopting transparent pricing policies. Read this article
                      by TTW CEO, Kingsley Borello
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className={styles.newsItem}>
                  <div className={styles.logo}>
                    <img src={news3} alt="" />
                    {window.innerWidth < 720 && (
                      <h3>
                        <a
                          href="https://www.linkedin.com/company/tradetoworld/"
                          target="_blank"
                          rel="noreferrer noopener nofollow"
                        >
                          Follow us on Linkedin
                        </a>
                      </h3>
                    )}
                  </div>
                  <div>
                    {window.innerWidth >= 720 && (
                      <h3>
                        <a
                          href="https://www.linkedin.com/company/tradetoworld/"
                          target="_blank"
                          rel="noreferrer noopener nofollow"
                        >
                          Follow us on Linkedin
                        </a>
                      </h3>
                    )}
                    <p>
                      You want to be ahead with our development? Stay updated with new
                      innovative marketplace features and read our news becoming a
                      Follower of our Linkedin page!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-none col-12 col-lg-6 mb-4 mb-lg-0">
              <div className={styles.newsItem}>
                <div className={styles.logo}>
                  <img src={news1} alt="" />
                  {window.innerWidth < 720 && (
                    <h3>
                      <a
                        href="https://tradeto.world/features"
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                      >
                        Watch out our videos on Youtube
                      </a>
                    </h3>
                  )}
                </div>
                <div>
                  {window.innerWidth >= 720 && (
                    <h3>
                      <a
                        href="https://tradeto.world/features"
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                      >
                        Watch out our videos on Youtube
                      </a>
                    </h3>
                  )}
                  <p>
                    You want to understand in a few minutes the corefeatures of our
                    marketplace and understand why it is a revolutionary innovation in the
                    online B2B trade?
                  </p>
                </div>
              </div>
            </div>
            <div className="d-lg-none col-12 col-lg-6 mb-4 mb-lg-0">
              <div className={styles.newsItem}>
                <div className={styles.logo}>
                  <img src={news2} alt="" />
                  {window.innerWidth < 720 && (
                    <h3>
                      <a
                        href="https://www.kingsleyborello.com/2022/08/15/the-power-of-fair-pricing/"
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                      >
                        Promoting Fair Pricing
                      </a>
                    </h3>
                  )}
                </div>
                <div>
                  {window.innerWidth >= 720 && (
                    <h3>
                      <a
                        href="https://www.kingsleyborello.com/2022/08/15/the-power-of-fair-pricing/"
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                      >
                        Promoting Fair Pricing
                      </a>
                    </h3>
                  )}
                  <p>
                    TTW is intimately engaged in changing the rules of the game in
                    international trade: register and start acting with full ethical
                    behavior, adopting transparent pricing policies. Read this article by
                    TTW CEO, Kingsley Borello
                  </p>
                </div>
              </div>
            </div>
            <div className="d-lg-none col-12 col-lg-6">
              <div className={styles.newsItem}>
                <div className={styles.logo}>
                  <img src={news3} alt="" />
                  {window.innerWidth < 720 && (
                    <h3>
                      <a
                        href="https://www.linkedin.com/company/tradetoworld/"
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                      >
                        Follow us on Linkedin
                      </a>
                    </h3>
                  )}
                </div>
                <div>
                  {window.innerWidth >= 720 && (
                    <h3>
                      <a
                        href="https://www.linkedin.com/company/tradetoworld/"
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                      >
                        Follow us on Linkedin
                      </a>
                    </h3>
                  )}
                  <p>
                    You want to be ahead with our development? Stay updated with new
                    innovative marketplace features and read our news becoming a Follower
                    of our Linkedin page!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ProductsWrapper>
      <ProductsWrapper
        title="New Sellers"
        viewAllMobileText="View All Sellers"
        hideCarouselControl
        isGrayBg
      >
        <LatestSuppliers />
      </ProductsWrapper>
      <div className={styles.features}>
        <div className="homepage-container">
          <div className="row">
            <div className="col-6 col-md-3 col-lg-3 mb-4 mb-lg-0">
              <div className={styles.feature}>
                <div className="text-center">
                  <img src={hm1} alt="" />
                </div>
                <h3>Private Label</h3>
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg-3 mb-4 mb-lg-0">
              <div className={styles.feature}>
                <div className="text-center">
                  <img src={hm2} alt="" />
                </div>
                <h3>Special Deals</h3>
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg-3 mb-4 mb-lg-0">
              <div className={styles.feature}>
                <div className="text-center">
                  <img src={hm3} alt="" />
                </div>
                <h3>Green Products</h3>
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg-3">
              <div className={styles.feature}>
                <div className="text-center">
                  <img src={hm4} alt="" />
                </div>
                <h3>Ready to Ship</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BeAPartOfTTW />
    </>
  )
}

export default MarketplaceHome
