import axios from 'axios'


import convertObjToUrlParams from '../../../helpers/convert-obj-to-url-params'

var CANCEL_TOKEN

/**
 * @description Fetches the autocomplete options
 * @param {Object} params The query parameters object
 * @returns {Promise} A promise that resolves to the autocomplete options array
 * @async
 * @function fetchAutocomplete
 * @inner
 * @memberof fetch
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 */
export const fetchAutocomplete = async (params) => {
  let queryStr = convertObjToUrlParams(params)

  // TODO: Implement a debounce function to avoid making requests on every input change
  if (CANCEL_TOKEN) {
    CANCEL_TOKEN.cancel()
  }
  CANCEL_TOKEN = axios.CancelToken.source()

  try {
    let res = await axios.get(`${process.env.REACT_APP_MAIN_API_URL}/api/v2/search/autocomplete${queryStr}`, {
      cancelToken: CANCEL_TOKEN.token,
    })

    if (res.status === 200) {
      return res.data.data
    } else {
      throw new Error('Error fetching autocomplete')
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      console.error(error)
    }
  }
}
