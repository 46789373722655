import { useState, useEffect } from 'react'
import { Skeleton } from 'antd'

import axiosRequest from '../../../../hooks/useAxiosRequest'

import Card from '../Card'
import { ProductCard } from './ProductCard'
import { ProductDetailsModal } from '../../modals/ProductDetails/ProductDetailsModal'

/**
 * @description A card component for the Seller Showroom
 * @namespace Card - ProductCard - ProductCardSS
 * @param {Object} props
 * @param {Object} props.product - The product to display
 * @param {string} props.view - The view to display the product in
 * @param {boolean} props.defaultExpanded - Whether the product should be expanded by default
 *
 * @example
 * <ProductCardSS product={product} view="grid" defaultExpanded={false} />
 *
 * @method handleOpenModal - The function to handle the open modal
 * @method handleShowProductDetails - The function to handle the show product details
 * @method getProductDetails - The function to get the product details
 * @method useEffect - The hook to get the product details
 *
 * @version 1.0.0
 * @since 1.0.0
 * @author Rafael Rapizo Nery
 */
export const ProductCardSS = ({
  product,
  view,
  defaultExpanded = false,
  isShowAttributes = false,
  showDescription = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded)
  const [isShowDescription, setIsShowDescription] = useState(showDescription)
  const [detailedProduct, SetDetailedProduct] = useState(product)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)

  const handleChangeProductVariant = (slug) => {
    getProductDetails(slug)
  }

  const handleOpenModal = async () => {
    setIsShowModal(true)
  }

  const handleShowProductDetails = (isShow) => {
    if (isShowDescription && isShow) setIsShowDescription(false)
    if (showDescription && !isShow) setIsShowDescription(true)

    setIsExpanded(isShow)
  }

  const getProductDetails = (slug) => {
    setIsLoading(true)
    axiosRequest(`/api/v1/marketplace/product/${slug}`, null, 'get', true)
      .then((res) => {
        if (res && res.data.success) {
          SetDetailedProduct((prev) => ({
            ...prev,
            ...res.data.data.product,
            options: res.data.data.options,
          }))
          setIsLoading(false)
        }
      })
      .catch(() => {
        alert('Something went wrong, try again later...')
      })
  }

  useEffect(() => {
    getProductDetails(detailedProduct.slug)
  }, [])

  useEffect(() => {
    setIsExpanded(defaultExpanded)
  }, [defaultExpanded])

  useEffect(() => {
    setIsShowDescription(showDescription)
  }, [showDescription])

  return (
    <>
      <ProductDetailsModal
        isLoading={isLoading}
        isModalVisible={isShowModal}
        product={detailedProduct}
        onClose={() => setIsShowModal(false)}
        onVariantChange={(slug) => getProductDetails(slug)}
        onCardClick={() => null}
      />
      {isLoading ? (
        <Card className="w-100">
          <Skeleton active />
        </Card>
      ) : (
        <ProductCard
          isShowAttributes={isShowAttributes}
          isShowDescription={isShowDescription}
          disableProductLinks
          key={detailedProduct.id}
          className="product-card--ss"
          product={detailedProduct}
          view={view}
          isSellerShowroom
          expanded={isExpanded}
          setExpanded={() => handleShowProductDetails(!isExpanded)}
          handleChangeVariant={(slug) => handleChangeProductVariant(slug)}
          onClick={view === 'grid' ? handleOpenModal : () => null}
        />
      )}
    </>
  )
}
