import { uniqueId } from 'lodash'

export class Industry {
  id
  name
  icon
  subIndustries

  constructor(name, icon, subIndustries) {
    this.id = uniqueId()
    this.name = name
    this.icon = icon
    this.subIndustries = subIndustries
  }
}
