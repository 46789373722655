import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'

const ProductAttributes = ({
  className = '',
  product,
  handleChangeProductVariant,
  preventLinks = false,
  asBanner = false,
  options = false,
}) => {
  const [att, setAtt] = useState(null)

  /* Truncation logic     --------------------- */
  const attributeWrapperRef = useRef(null)
  const [isOverflown, setIsOverflown] = useState(false)

  const mountList = useCallback(
    (
      list,
      container,
      style = {
        fontSize: '14px',
        padding: '2px 12px',
      },
    ) => {
      if (container === null) return { newList: list, counter: 0 }

      let counter = 0
      let newList = list
      let isGreater = true
      while (isGreater) {
        const listWidth = newList.reduce((acc, item) => {
          const span = document.createElement('span')
          span.textContent = item.value ? item.value : item
          span.style.display = 'inline-block'
          span.style.whiteSpace = 'nowrap'
          span.style.visibility = 'hidden'
          span.style.fontSize = style.fontSize
          span.style.fontWeight = style.fontWeight
          span.style.padding = style.padding
          span.style.margin = style.margin
          document.body.appendChild(span)
          const width = span.offsetWidth
          span.remove()
          return acc + width
        }, 0)
        isGreater = listWidth > container.offsetWidth
        if (isGreater) {
          newList = newList.slice(0, -1)
          counter++
        }
      }
      return { newList, counter }
    },
    [],
  )

  const isOverflowing = useCallback(() => {
    let isOverflown = false

    if (attributeWrapperRef.current && window.innerWidth < 768) {
      let keys = Object.keys(att)
      for (let i = 0; i < keys.length; i++) {
        const { newList } = mountList(att[keys[i]], attributeWrapperRef.current)
        if (newList.length < att[keys[i]].length) {
          isOverflown = true
          break
        }
      }
    }
    return isOverflown
  }, [att, mountList, attributeWrapperRef])

  useEffect(() => {
    setIsOverflown(isOverflowing())
  }, [isOverflowing])

  /* Truncation Logic End --------------------- */

  const buildProductAttr = useCallback((product) => {
    const productAttr = {}
    if (product.get_product_attributes.length > 0) {
      for (const att of product.get_product_attributes) {
        if (!productAttr[att.get_attribute_details.name]) {
          productAttr[att.get_attribute_details.name] = []
        }
        productAttr[att.get_attribute_details.name].push(att.value)
      }
      return productAttr
    }
    return null
  }, [])

  useEffect(() => {
    if (!options) setAtt(buildProductAttr(product))
    else setAtt(product.options)
  }, [product, buildProductAttr, options])

  if (att)
    return (
      <div className={`product-attributes ${className}`} /* ref={attributeWrapperRef} */>
        {Object.keys(att).map((key) => (
          <div className="product-attribute" key={key}>
            <div className="attr_name">{key}</div>
            <div className="attr-options" ref={attributeWrapperRef}>
              {isOverflown
                ? (() => {
                    const { newList, counter } = mountList(
                      att[key],
                      attributeWrapperRef.current,
                    )

                    return (
                      <>
                        {newList.map((option) => (
                          <div
                            className={`option ${
                              option.isActive ? 'active' : 'not-active'
                            }`}
                            key={option.id}
                          >
                            {asBanner ? (
                              <p>{option}</p>
                            ) : (
                              <Link
                                to={`/product/${encodeURIComponent(option.slug)}`}
                                onClick={(e) => {
                                  if (preventLinks) {
                                    e.preventDefault()
                                    handleChangeProductVariant(option.slug)
                                  }
                                }}
                              >
                                {options ? option.value : option}
                              </Link>
                            )}
                          </div>
                        ))}
                        {counter > 0 ? <div className="option" style={{
                          border: '1px solid #ccc',
                          color: '#c7c7c7',
                          padding: '4px',
                          fontSize: '12px',
                          borderRadius: '4px',
                        }}>+{counter}</div> : null}
                      </>
                    )
                  })()
                : att[key].map((option) => (
                    <div
                      className={`option ${option.isActive ? 'active' : 'not-active'}`}
                      key={option.id}
                    >
                      {asBanner ? (
                        <p>{option}</p>
                      ) : (
                        <Link
                          to={`/product/${encodeURIComponent(option.slug)}`}
                          onClick={(e) => {
                            if (preventLinks) {
                              e.preventDefault()
                              handleChangeProductVariant(option.slug)
                            }
                          }}
                        >
                          {options ? option.value : option}
                        </Link>
                      )}
                    </div>
                  ))}
              {/* {isOverflown ? (
                <>
                  {(() => {
                    const { newList, counter } = mountList(
                      att[key],
                      attributeWrapperRef.current,
                    )

                    return (
                      <>
                        {newList.map((option) => (
                          <div
                            className={`option ${
                              option.isActive ? 'active' : 'not-active'
                            }`}
                            key={option.id}
                          >
                            {asBanner ? (
                              <p>{option}</p>
                            ) : (
                              <Link
                                to={`/product/${encodeURIComponent(option.slug)}`}
                                onClick={(e) => {
                                  if (preventLinks) {
                                    e.preventDefault()
                                    handleChangeProductVariant(option.slug)
                                  }
                                }}
                              >
                                {options ? option.value : option}
                              </Link>
                            )}
                          </div>
                        ))}
                        {counter > 0 ? <div className="option">+{counter}</div> : null}
                      </>
                    )
                  })()}
                </>
              ) : (
                att[key].map((option) => (
                  <div
                    className={`option ${option.isActive ? 'active' : 'not-active'}`}
                    key={option.id}
                  >
                    {asBanner ? (
                      <p>{option}</p>
                    ) : (
                      <Link
                        to={`/product/${encodeURIComponent(option.slug)}`}
                        onClick={(e) => {
                          if (preventLinks) {
                            e.preventDefault()
                            handleChangeProductVariant(option.slug)
                          }
                        }}
                      >
                        {options ? option.value : option}
                      </Link>
                    )}
                  </div>
                ))
              )} */}

              {/* {att[key].length > 0 &&
                att[key].map((option) => (
                  <div
                    className={`option ${option.isActive ? 'active' : 'not-active'}`}
                    key={option.id}
                  >
                    {asBanner ? (
                      <p>{option}</p>
                    ) : (
                      <Link
                        to={`/product/${encodeURIComponent(option.slug)}`}
                        onClick={(e) => {
                          if (preventLinks) {
                            e.preventDefault()
                            handleChangeProductVariant(option.slug)
                          }
                        }}
                      >
                        {options ? option.value : option}
                      </Link>
                    )}
                  </div>
                ))} */}
            </div>
          </div>
        ))}
      </div>
    )
  else return null
}

export default ProductAttributes
