import { useState } from 'react'
import { Input, Button, message } from 'antd'
import { FaChevronRight } from 'react-icons/fa'

import { ErrorMessage } from '../../../../models/Messages/ErrorMessage'
import { SubscribeToNewsletterMailer } from '../../../../models/Mailer/SubscribeToNewsletterMailer'

export const NewsletterForm = () => {
  const [email, setEmail] = useState()

  const handleSubmit = async (value) => {
    message.loading()
    try {
      let mailer = new SubscribeToNewsletterMailer().setData({ email: value })

      await mailer.sendMail()

      return message.success('Thank you for your subscription!')
    } catch (error) {
      return message.error(ErrorMessage.genericServerError)
    } finally {
      message.destroy()
    }
  }

  return (
    <>
      <h4>Subscribe to our newsletter</h4>
      <Input
        type="email"
        placeholder="Enter your email"
        className="my-2"
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        onClick={() => handleSubmit(email)}
        type="primary"
        className="ant-btn-primary__outlined ant-btn-primary-ttu"
      >
        Subscribe <FaChevronRight />
      </Button>
    </>
  )
}
