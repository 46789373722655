import { Button } from 'antd'

import { history } from '../../../../../index'

export const FooterNotification = () => {
  const redirectCart = () => history.push('/cart')

  return (
    <Button
      type="primary"
      className="ant-btn-primary__outlined ant-btn-ttu w-100 py-1 mt-2"
      onClick={redirectCart}
    >
      view my cart
    </Button>
  )
}
