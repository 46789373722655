import { useSelector } from 'react-redux'

import { Prices } from './Prices'
import { OptBtn } from './OptBtn'
import { PriceUnavailable } from './PriceUnavailable'
import { RateWrapper } from './RateWrapper'

export const PriceWrapper = ({
  isSellerShowroom,
  product,
  expanded,
  shopOptions,
  setExpanded,
  layout = 'list',
}) => {
  const { authorized } = useSelector((state) => state.user)

  const isOnDemand = (prices = []) => {
    for (let price of prices) {
      for (let item of price.prices) {
        if (item.type === 'on_demand') return true
        else return false
      }
    }
  }

  const isOutOfStock = (prices = []) => {
    for (let price of prices) {
      if (price.available_qty <= 0) return true
      else return false
    }
  }

  if (!authorized) return null
  return (
    <div className="product-card__prices">
      {!isSellerShowroom && (
        <div className="product-card__prices__price ">
          {isOnDemand(product.sales_terms) ? (
            <PriceUnavailable
              title="Price On Demand"
              className="product-card__prices__price--unavailable--on-demand"
            />
          ) : isOutOfStock(product.sales_terms) ? (
            <PriceUnavailable
              title="Product Out of Stock"
              className="product-card__prices__price--unavailable--out-of-stock"
            />
          ) : (
            <>
              <RateWrapper
                className="d-none"
                rating={product.rating}
                reviewCount={product.review_count}
              />
              <Prices product={product} />
            </>
          )}
        </div>
      )}
      {layout === 'list' && (
        <OptBtn
          expanded={expanded}
          isShopOption={shopOptions}
          product={product}
          setExpanded={setExpanded}
        />
      )}
    </div>
  )
}
