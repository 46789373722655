import { CloseCircleFilled } from '@ant-design/icons'
import { Tag } from '../Tag'
import './RemovableTag.scss'

const RemovableTag = ({ tag, className, onRemove = () => {} }) => {
  return (
    <Tag
      tag={tag}
      className={`removable-tag ${className}`}
      posfix={
        <span onClick={onRemove}>
          <CloseCircleFilled />
        </span>
      }
    />
  )
}

export default RemovableTag
