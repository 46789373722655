import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import ReactGA from 'react-ga'
import { createHashHistory } from 'history'
import createRootReducer from './redux/reducers'
// eslint-disable-next-line import/no-cycle
import sagas from './redux/sagas'
// eslint-disable-next-line import/no-cycle
import Router from './router'

import './axios'
import './scss/app.scss'

import reportWebVitals from './reportWebVitals'

// GoogleAnalytics
if (process.env.NODE_ENV === 'production') ReactGA.initialize('G-K6J3S6JTVD')
// Middleware
const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}
const store = createStore(
  createRootReducer(history),
  compose(applyMiddleware(...middlewares)),
)
sagaMiddleware.run(sagas)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history} />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
// Export state and history
export { store, history }
