import { Tags } from '../'

export const Description = ({ product }) => {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: product.body,
        }}
      />
      <Tags product={product} />
    </>
  )
}
