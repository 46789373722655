export const removeKey = (obj, key) => {
  let { [key]: deleted, ...rest } = obj
  return rest
}

export const removeKeys = (obj, keys) => {
  let newObj = { ...obj }
  keys.forEach((key) => {
    let { [key]: deleted, ...rest } = newObj
    newObj = rest
  })
  return newObj
}

export const removeMatchingKeys = (obj, regex) => {
  let newObj = { ...obj }
  Object.keys(obj).forEach((key) => {
    if (regex.test(key)) {
      let { [key]: deleted, ...rest } = newObj
      newObj = rest
    }
  })
  return newObj
}
