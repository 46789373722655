export const PolicyAndTerm = ({ policyURL, termURL }) => {
  return (
    <div className="col-12 col-md-3 order-1 my-5 my-md-0 text-uppercase marketplace-footer__policies">
      <a
        href={policyURL}
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>{' '}
      |{' '}
      <a href={termURL} target="_blank" rel="noreferrer">
        Terms
      </a>
    </div>
  )
}
