import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export const OptBtn = ({ product, expanded, setExpanded, isShopOption }) => {
  return (
    <div className="choose-options">
      {isShopOption && <Link to={`/product/${encodeURIComponent(product.slug)}`}>Go To Market</Link>}
      <Link
        to={`/product/${encodeURIComponent(product.slug)}`}
        className="mt-3"
        onClick={(e) => {
          e.preventDefault()
          if (product.id === expanded) {
            setExpanded(null)
          } else {
            setExpanded(product.id)
          }
        }}
      >
        {isShopOption ? 'SEE DETAILS' : 'CHOOSE OPTIONS'}
      </Link>
    </div>
  )
}
