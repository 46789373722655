import { useEffect, useRef, useState } from 'react'
import Truncate from 'react-truncate'

import './DocumentCard.scss'

/**
 * @description DocumentCard component is used to display a document card with a title and a link to the document itself.
 * @param {string} key - The key of the document card.
 * @param {string} href - The link to the document.
 * @param {string} title - The title of the document.
 * @param {string} wrapperClassName - The class name of the wrapper div.
 * @param {string} innerClassName - The class name of the inner div.
 * @returns {JSX.Element} The DocumentCard component.
 * @example
 * <DocumentCard
 *  key="document-card"
 * href="https://www.example.com"
 * title="Document Title"
 * wrapperClassName="document-card-wrapper"
 * innerClassName="document-card-inner"
 * />
 *
 * @since 0.1.2
 * @version 0.1.2
 * @component
 * @namespace Components - Marketplace - DocumentCard
 * @subcategory Marketplace
 * @see https://www.npmjs.com/package/react-truncate
 *
 * @requires react
 * @requires react-truncate
 * @requires ./DocumentCard.scss
 *
 * @author Rafael Rapizo Nery
 */
const DocumentCard = ({ key, href, title, wrapperClassName, innerClassName }) => {
  const [isTruncated, setIsTruncated] = useState(true)
  const wrapperRef = useRef(null)

  useEffect(() => {
    if (wrapperRef.current.scrollHeight > wrapperRef.current.clientHeight) {
      setIsTruncated(true)
    } else {
      setIsTruncated(false)
    }
    console.log({
      scrollHeight: wrapperRef.current.scrollHeight,
      clientHeight: wrapperRef.current.clientHeight,
    })
  }, [])

  return (
    <div key={key} className={wrapperClassName}>
      <div className={`${innerClassName} .certificate`} ref={wrapperRef}>
        <a href={href} target="_blank" rel="noreferrer noopener nofollow">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M46 24L41.12 18.42L41.8 11.04L34.58 9.4L30.8 3L24 5.92L17.2 3L13.42 9.38L6.19998 11L6.87998 18.4L1.99998 24L6.87998 29.58L6.19998 36.98L13.42 38.62L17.2 45L24 42.06L30.8 44.98L34.58 38.6L41.8 36.96L41.12 29.58L46 24ZM20.18 33.44L12.58 25.82L15.54 22.86L20.18 27.52L31.88 15.78L34.84 18.74L20.18 33.44Z"
              fill="#E2E2E2"
            />
          </svg>
        </a>
        <p>
          <a href={href} target="_blank" rel="noreferrer noopener nofollow">
            {isTruncated ? <Truncate lines={4}>{title}</Truncate> : title}
          </a>
        </p>
      </div>
    </div>
  )
}

export default DocumentCard
