import { Link } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * @description HeaderLogo component is used to display the logo of the marketplace header
 * @param {boolean} disableMenu - Disable the menu
 * @param {string} logo - Logo url
 * @returns {JSX.Element}
 * @author Rafael Rapizo Nery
 * @version 1.0.0
 * @since 1.0.0
 */
export const HeaderLogo = ({ disableMenu, logo }) => {
  return (
    <div className="header-logo">
      <Link
        to="/"
        onClick={(e) => {
          if (disableMenu) {
            e.preventDefault()
          }
        }}
      >
        <img src={logo} alt="TradeToWorld" />
      </Link>
    </div>
  )
}
