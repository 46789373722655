export const DiscountBadge = ({ product }) => {
  const getDiscount = () => {
    let lowestIndex = product.sales_terms[0]
    let lowestPrice = 999999999999999999
    // Find the lowest price
    product.sales_terms[0].prices &&
      product.sales_terms[0].prices.forEach((price, index) => {
        if (price.type === 'by_qty' && parseFloat(price.price_value) < lowestPrice) {
          lowestIndex = product.sales_terms[0].prices[index]
        }
      })

    return parseFloat(lowestIndex.discount)
  }

  if (product.sales_terms.length > 0 && getDiscount() > 0)
    return (
      <div className="product-card__image__badges__discount">
        <span>{getDiscount()}%</span>
      </div>
    )
  else return null
}
